import React, { useEffect, useState } from "react";
import TableCell from "../../../UI/Table/TableCell";
import { TrashIcon } from "@heroicons/react/20/solid";
import { updateDecisionRequest } from "../../../../requests/decisions";
import { ToastNotification } from "../../../UI/ToastNotification";
import SeminarDecisionsForm from "./SeminarDecisionsForm";
import { displayError } from "../../../../utils/displayError";
import { hoverLink } from "../../../../utils/hoverLink";

export default function SeminarDecisionsRow({
  decision,
  handleDeleteModal,
  formOpen,
  setFormOpen,
}) {
  const [editing, setEditing] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await updateDecisionRequest({
        ...data,
        decisionId: decision.id,
      });

      if (res.data) {
        ToastNotification("success", "Decision updated successfully.");
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleOpenForm = () => {
    setFormOpen({ open: true, action: "edit" });
    setEditing(true);
  };

  useEffect(() => {
    if (!formOpen.open && formOpen.action === null) {
      setEditing(false);
    }
  }, [formOpen]);

  return (
    <tr>
      {editing ? (
        <TableCell colSpan={2}>
          <SeminarDecisionsForm
            onSubmit={onSubmit}
            decision={decision}
            setOpen={setFormOpen}
          />
        </TableCell>
      ) : (
        <>
          <TableCell>
            <p onClick={() => handleOpenForm()} className={hoverLink}>
              {decision.name}
            </p>
          </TableCell>
          <TableCell>#</TableCell>
          <TableCell>
            <TrashIcon
              onClick={(e) => handleDeleteModal(e, decision.id)}
              className="w-5 cursor-pointer"
            />
          </TableCell>
        </>
      )}
    </tr>
  );
}
