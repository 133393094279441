import React, { useEffect, useState } from "react";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import { useOutletContext, useRouteLoaderData } from "react-router-dom";
import AddButton from "../../../../../UI/AddButton";
import EmptyTable from "../../../../../UI/EmptyTable";
import VisitRow from "./VisitRow";
import VisitNew from "./VisitNew";
import { getAllCourseDecisionsRequest } from "../../../../../../requests/courses";

export default function Visits() {
  const { seriesEnrollment } = useOutletContext();
  const enrollment = useRouteLoaderData(seriesEnrollment).data;
  const [addingVisit, setAddingVisit] = useState(false);
  const [decisionOptions, setDecisionOptions] = useState([]);

  const visits = enrollment?.visits;

  const getAllCourseDecisions = async () => {
    const res = await getAllCourseDecisionsRequest({
      courseId: enrollment.series_id,
    });

    if (res.data) {
      setDecisionOptions(res.data);
    }
  };

  useEffect(() => {
    getAllCourseDecisions();
  }, []);

  return (
    <div>
      {visits?.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>REMINDER DATE</TableHeader>
              <TableHeader>NOTES & DECISIONS</TableHeader>
            </tr>
          </thead>
          <tbody>
            {visits.map((visit) => (
              <VisitRow
                key={visit.id}
                enrollment={enrollment}
                visit={visit}
                handleCancelAdd={() => setAddingVisit(false)}
                addingVisit={addingVisit}
                decisionOptions={decisionOptions}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No visits recorded." />
      )}

      {addingVisit ? (
        <div className="mt-4">
          <VisitNew
            enrollment={enrollment}
            handleCancelAdd={() => setAddingVisit(false)}
          />
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <AddButton
            onClick={() => setAddingVisit(true)}
            label="Add Visit"
            variant="primary-outline"
          />
        </div>
      )}
    </div>
  );
}
