import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import Card from "../UI/Card";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { hoverLink } from "../../utils/hoverLink";
import { Link } from "react-router-dom";
import EmptyTable from "../UI/EmptyTable";

export default function CoursesTable({ courses, isAdmin }) {
  return (
    <Card classNames="overflow-y-scroll no-scrollbar h-full">
      <div className="flex items-center justify-between mb-6">
        <h2 className="font-bold text-[#0694C0]">COURSES</h2>
        {isAdmin && (
          <Link to="/courses">
            <ArrowTopRightOnSquareIcon className="h-[32px] w-[32px] text-[#C4C4C4]" />
          </Link>
        )}
      </div>
      {courses.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>COURSE</TableHeader>
              <TableHeader>STUDENTS</TableHeader>
              <TableHeader>NEEDING-FOLLOW-UP</TableHeader>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr key={course.id}>
                <TableCell>
                  {isAdmin ? (
                    <Link
                      className={hoverLink}
                      to={`/courses/${course?.id}/overview`}
                    >
                      {course.name}
                    </Link>
                  ) : (
                    course.name
                  )}
                </TableCell>
                <TableCell>{course.students_count}</TableCell>
                <TableCell>-</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No courses available." />
      )}
    </Card>
  );
}
