import React from "react";
import BackLink from "../../../../Mobile/BackLink";
import { Link, useRouteLoaderData } from "react-router-dom";
import Card from "../../../../UI/Card";

export default function StudentMobileDecisions({ decisions }) {
  const student = useRouteLoaderData("student").data;

  return (
    <div>
      <BackLink path={`/students/${student?.id}/overview`} label="Decisions" />

      <div className="pt-4">
        {decisions.map((decision) => (
          <Link
            key={decision.id}
            to={`/students/${decision.id}/overview`}
            className="mb-4 last:mb-0 block"
          >
            <Card classNames="flex justify-between items-center">
              <p className="text-[17px]">{decision.name}</p>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
