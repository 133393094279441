import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import Card from "../UI/Card";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import EmptyTable from "../UI/EmptyTable";
import { hoverLink } from "../../utils/hoverLink";
import { fullName } from "../../utils/fullName";
import { Link } from "react-router-dom";

export default function InstructorsTable({ instructors }) {
  return (
    <Card classNames="overflow-y-scroll h-full no-scrollbar">
      <div className="flex items-center justify-between mb-6">
        <h2 className="font-bold text-[#0694C0]">INSTRUCTORS</h2>
        <Link to="/instructors">
          <ArrowTopRightOnSquareIcon className="h-[32px] w-[32px] text-[#C4C4C4]" />
        </Link>
      </div>
      {instructors.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>INSTRUCTOR</TableHeader>
              <TableHeader>STUDENTS</TableHeader>
              <TableHeader>SCHOOL</TableHeader>
            </tr>
          </thead>
          <tbody>
            {instructors.map((instructor) => (
              <tr key={instructor.id}>
                <TableCell>
                  <Link
                    className={hoverLink}
                    to={`/instructors/${instructor.id}/overview`}
                  >
                    {fullName(instructor.first_name, " ", instructor.last_name)}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    className={hoverLink}
                    to={`/instructors/${instructor.id}/students`}
                  >
                    {instructor.students_count}
                  </Link>
                </TableCell>
                <TableCell>
                  {instructor.roles.map((role) => (
                    <div key={role.id}>{role.organization_name}</div>
                  ))}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No instructors available." />
      )}
    </Card>
  );
}
