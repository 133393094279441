import React from "react";
import Note from "./Note";
import NoteNew from "./NoteNew";

export default function Notes({
  visit,
  enrollment,
  setAddingNotes,
  addingNotes,
}) {
  return (
    <>
      {visit?.notes?.length > 0 &&
        visit?.notes?.map((note) => (
          <Note
            key={note.id}
            enrollment={enrollment}
            note={note}
            visit={visit}
          />
        ))}

      {addingNotes && (
        <NoteNew
          setAddingNotes={setAddingNotes}
          enrollment={enrollment}
          visit={visit}
        />
      )}
    </>
  );
}
