import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import { Cog8ToothIcon } from "@heroicons/react/20/solid";
import QuickFind from "../../../UI/QuickFind";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import AddButton from "../../../UI/AddButton";
import { getAllSeminarDecisionsRequest } from "../../../../requests/seminars";
import { useParams } from "react-router-dom";
import SeminarDecisionsNew from "./SeminarDesisonsNew";
import SeminarDecisionsRow from "./SeminarDecisionsRow";
import { deleteDecisionRequest } from "../../../../requests/decisions";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import Modal from "../../../UI/Modal";
import { ToastNotification } from "../../../UI/ToastNotification";
import { displayError } from "../../../../utils/displayError";
import { checkPermission } from "../../../../utils/checkPermission";

export default function SeminarDecisions() {
  const params = useParams();
  const seminarId = params.seminarId;
  const [decisions, setDecisions] = useState([]);
  const [formOpen, setFormOpen] = useState({ open: false, action: null });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeDecisionId, setActiveDecisionId] = useState(null);

  const getAllSeminarDecisions = async () => {
    const res = await getAllSeminarDecisionsRequest({ seminarId: seminarId });

    if (res.data) {
      setDecisions(res.data);
    }
  };

  const deleteDecision = async () => {
    try {
      const res = await deleteDecisionRequest({ decisionId: activeDecisionId });

      if (res) {
        ToastNotification("success", "Decision deleted successfully.");
        setDeleteModalOpen(false);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleDeleteModal = (e, decisionId) => {
    e.preventDefault();
    setDeleteModalOpen(true);
    setActiveDecisionId(decisionId);
  };

  useEffect(() => {
    getAllSeminarDecisions();
  }, [formOpen, deleteModalOpen]);

  return (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["global_admin", "school_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() => setFormOpen({ open: true, action: "new" })}
              />
            )}

            <h1 className="text-xl font-bold text-[#0694C0]">DECISIONS</h1>
          </div>
          <div className="flex">
            <QuickFind />
            <Cog8ToothIcon className="ml-7 w-5 cursor-pointer" />
          </div>
        </div>
        {decisions.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>DECISION</TableHeader>
                <TableHeader>DECISIONS RECORDED</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {decisions.map((decision) => (
                <SeminarDecisionsRow
                  key={decision.id}
                  setFormOpen={setFormOpen}
                  formOpen={formOpen}
                  decision={decision}
                  handleDeleteModal={handleDeleteModal}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <>
            {formOpen.action !== "new" && (
              <p className="text-center">No decisions found.</p>
            )}
          </>
        )}

        {formOpen.action === "new" && (
          <div>
            <SeminarDecisionsNew
              seminarId={seminarId}
              setFormOpen={setFormOpen}
            />
          </div>
        )}

        {formOpen.action === "new" ? null : (
          <div className="flex justify-center mt-4">
            <AddButton
              onClick={() => setFormOpen({ open: true, action: "new" })}
              label="Add Decision"
              variant="primary-outline"
            />
          </div>
        )}
      </Card>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deleteDecision()}
        />
      </Modal>
    </div>
  );
}
