import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/countries`;

const getAllCountries = async () => {
  const data = await axiosClient.get(endpoint);

  return data;
};

export { getAllCountries };
