import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ActionMenuContainer from "../../ActionMenuContainer";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { useRouteLoaderData } from "react-router-dom";
import DatePickerInput from "../../../UI/Inputs/DatePickerInput";
import { getAllCourseProductsRequest } from "../../../../requests/courses";
import { ToastNotification } from "../../../UI/ToastNotification";
import { displayError } from "../../../../utils/displayError";
import { updateEnrollmentRequest } from "../../../../requests/enrollments";

export default function NewStudentProduct({ setRender }) {
  const [products, setProducts] = useState([]);
  const student = useRouteLoaderData("student").data;

  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      const enrollmentId = student.course_enrollments.find(
        (enrollment) => enrollment.series_id === data.course_id
      ).id;
      const selectedProduct = products.find(
        (product) => product.id === data.product_id
      );

      const res = await updateEnrollmentRequest({
        product_deliveries_attributes: [
          {
            product_id: data.product_id,
            delivered_at: data.delivered_at,
            quantity: data.quantity,
            price: data.price || selectedProduct.default_price,
            visitor_name: data.visitor_name,
            delivery_type: data.delivery_type,
          },
        ],
        enrollmentId: enrollmentId,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        setRender(null);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const selectedCourse = methods.watch("course_id");

  const deliveryTypes = [
    {
      label: "PO",
      value: "Post Office",
    },
    {
      label: "PD",
      value: "Personal Delivery",
    },
    {
      label: "IH",
      value: "In-home",
    },
  ];

  const getAllCourseProducts = async () => {
    const res = await getAllCourseProductsRequest({
      courseId: selectedCourse,
    });

    if (res.data) {
      setProducts(res.data);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      getAllCourseProducts();
    }
  }, [selectedCourse]);

  return (
    <ActionMenuContainer
      label="Send a product"
      handleGoingBack={() => setRender(null)}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputGroup>
            <Select
              id="course_id"
              classNames="w-full bg-transparent"
              label="Course"
              isRequired
            >
              <SelectItem value="">Select</SelectItem>
              {student.course_enrollments?.map((enrollment) => (
                <SelectItem key={enrollment.id} value={enrollment.series_id}>
                  {enrollment.series_name}
                </SelectItem>
              ))}
            </Select>
          </InputGroup>
          <InputGroup>
            <Select id="product_id" label="Product Offer" isRequired dark>
              <SelectItem value="">
                {selectedCourse ? "Select" : "Select a course first"}
              </SelectItem>
              {products.map((product) => (
                <SelectItem key={product.id} value={product.id}>
                  {product.name}
                </SelectItem>
              ))}
            </Select>
          </InputGroup>
          <InputGroup>
            <DatePickerInput
              id="delivered_at"
              dark
              label="Sent"
              selected={new Date()}
            />
            <Input id="quantity" label="Quantity" type="number" dark />
            <Input
              id="price"
              label="Price"
              type="number"
              size="12"
              min="0.0"
              step="0.01"
              dark
            />
          </InputGroup>
          <InputGroup>
            <Select
              id="delivery_type"
              classNames="w-full bg-transparent"
              label="Delivery Type"
            >
              <SelectItem value="">Select</SelectItem>
              {deliveryTypes?.map((deliveryType) => (
                <SelectItem key={deliveryType.value} value={deliveryType.value}>
                  {deliveryType.value}
                </SelectItem>
              ))}
            </Select>
            <Input id="visitor_name" label="Visitor" dark />
          </InputGroup>
          <div className="text-center">
            <Button type="submit" variant="primary">
              Save Product
            </Button>
          </div>
        </form>
      </FormProvider>
    </ActionMenuContainer>
  );
}
