import React from "react";
import { twMerge } from "tailwind-merge";

export default function Card({ children, classNames }) {
  return (
    <div
      className={twMerge(
        "relative rounded bg-white/70 backdrop-blur-xl p-6",
        classNames
      )}
    >
      {children}
    </div>
  );
}
