import React from "react";

export const IconWrapper = ({ color = "currentColor", children }) => {
  const addColorToChildren = (child) => {
    if (React.isValidElement(child) && child.type === "path") {
      return React.cloneElement(child, { fill: color });
    }
    return child;
  };

  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {React.Children.map(children, addColorToChildren)}
    </svg>
  );
};
