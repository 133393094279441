import { removeToken, storedToken } from "../../utils/auth";

export default function PrivateRoute({ children }) {
  if (storedToken) {
    return children;
  }

  removeToken();

  const redirectPath = encodeURIComponent(
    `${location.pathname}${location.search}`
  );
  return window.location.replace(`/login?redirect=${redirectPath}`);
}
