import React from "react";
import Courses from "../Courses/Courses";
import Course from "../Courses/Course/Course";
import CourseOverview from "../Courses/Course/CourseOverview/CourseOverview";
import CourseLessons from "../Courses/Course/CourseLessons/CourseLessons";
import CourseDecisions from "../Courses/Course/CourseDecisions/CourseDecisions";
import CourseProducts from "../Courses/Course/CourseProducts/CourseProducts";
import CourseNew from "../Courses/Course/CourseNew";
import { getCourseRequest } from "../../requests/courses";
import CourseSchools from "../Courses/Course/CourseSchools/CourseSchools";

export default [
  {
    path: "/courses",
    element: <Courses />,
  },
  {
    path: "/courses/new",
    element: <CourseNew />,
  },
  {
    path: "/courses/:courseId",
    element: <Course />,
    id: "course",
    loader: ({ params }) => {
      return getCourseRequest({ courseId: params.courseId });
    },
    children: [
      {
        path: "/courses/:courseId/overview",
        element: <CourseOverview />,
      },
      {
        path: "/courses/:courseId/lessons",
        element: <CourseLessons />,
      },
      {
        path: "/courses/:courseId/decisions",
        element: <CourseDecisions />,
      },
      {
        path: "/courses/:courseId/products",
        element: <CourseProducts />,
      },
      {
        path: "/courses/:courseId/schools",
        element: <CourseSchools />,
      },
    ],
  },
];
