import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { hoverLink } from "../../../utils/hoverLink";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";

export default function TableHeader({
  children,
  classNames,
  sortBy,
  multiSortOptions,
  isMultiSort = false,
  isSortable = false,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState(searchParams.get("orderBy"));

  const handleClick = (value) => {
    if (order === "asc") {
      setOrder("desc");
      setSearchParams((searchParams) => {
        searchParams.set("orderBy", "desc");
        searchParams.set("sortBy", value);
        return searchParams;
      });
    } else {
      setOrder("asc");
      setSearchParams((searchParams) => {
        searchParams.set("orderBy", "asc");
        searchParams.set("sortBy", value);
        return searchParams;
      });
    }
  };

  return (
    <th className={twMerge("text-left text-xs", classNames)}>
      {isSortable ? (
        <>
          {isMultiSort ? (
            <>
              {children}
              <div className={"flex items-center"}>
                (
                {multiSortOptions.map((option, index) => (
                  <React.Fragment key={option.value}>
                    <div
                      onClick={() => handleClick(option.value)}
                      className={twMerge("flex items-center", hoverLink)}
                    >
                      {option.name}
                      {option.value === searchParams.get("sortBy") &&
                        order === "asc" && <ChevronUpIcon className="w-4" />}
                      {option.value === searchParams.get("sortBy") &&
                        order === "desc" && <ChevronDownIcon className="w-4" />}
                    </div>
                    {index < multiSortOptions.length - 1 && (
                      <span className="mx-1"> / </span>
                    )}
                  </React.Fragment>
                ))}
                )
              </div>
            </>
          ) : (
            <div
              onClick={() => handleClick(sortBy)}
              className={twMerge("flex items-center", hoverLink)}
            >
              {children}
              {sortBy === searchParams.get("sortBy") && order === "asc" && (
                <ChevronUpIcon className="w-4 ml-1" />
              )}
              {sortBy === searchParams.get("sortBy") && order === "desc" && (
                <ChevronDownIcon className="w-4 ml-1" />
              )}
            </div>
          )}
        </>
      ) : (
        children
      )}
    </th>
  );
}
