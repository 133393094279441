import { parseJwt, removeToken, storedToken } from "./auth";

const user = storedToken && parseJwt(storedToken)?.user;

export const checkPermission = (permissions) => {
  if (user === undefined) removeToken();

  if (!storedToken) return false;

  if (user?.admin) return true;

  const roles = user?.roles;

  return roles?.some((role) => permissions.includes(role.role_type));
};
