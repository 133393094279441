import React from "react";
import ReminderForm from "./ReminderForm";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { updateEnrollmentRequest } from "../../../../../../requests/enrollments";
import { useNavigate } from "react-router-dom";

export default function ReminderNew({ enrollment, handleCancelAdd }) {
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        reminders_attributes: [
          {
            date: data.date,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Reminder created successfully.");
        navigate(".", { replace: true });
        handleCancelAdd();
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return <ReminderForm onSubmit={onSubmit} handleCancelAdd={handleCancelAdd} />;
}
