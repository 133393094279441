import React from "react";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import { FormProvider, useForm } from "react-hook-form";

export default function SeminarDecisionsForm({ setOpen, decision, onSubmit }) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex items-end">
          <Input
            id="name"
            classNames="w-3/12 mr-4"
            placeholder="Decision Name"
            defaultValue={decision?.name}
            isRequired
          />
          <Button
            onClick={() => setOpen({ open: false, action: null })}
            classNames="mr-4"
            variant="primary-outline"
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary-outline">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
