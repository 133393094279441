import React from "react";
import InputGroup from "../../../../UI/Inputs/InputGroup";
import Input from "../../../../UI/Inputs/Input";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../../UI/Button";

export default function QuestionForm({
  setOpen,
  onSubmit,
  question,
  handleDeleteQuestionModal,
}) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex items-center my-2">
          <InputGroup classNames="w-4/12 mr-4 mb-0">
            <Input
              id="text"
              placeholder="Question name"
              defaultValue={question?.text}
              isRequired
            />
          </InputGroup>
          <Button
            onClick={() => setOpen({ open: false, action: null })}
            variant="primary-outline"
            classNames="mr-4"
          >
            Cancel
          </Button>
          {question && (
            <Button
              onClick={(e) => handleDeleteQuestionModal(e)}
              variant="primary-outline"
              classNames="mr-4"
            >
              Delete
            </Button>
          )}
          <Button type="submit" variant="primary-outline">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
