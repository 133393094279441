import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { login } from "../../../requests/auth";
import { setToken, storedToken } from "../../../utils/auth";
import Input from "../../UI/Inputs/Input";
import Button from "../../UI/Button";

export default function Login() {
  const [apiErrors, setApiErrors] = useState(null);
  const methods = useForm();

  const onSubmit = async (data) => {
    setApiErrors(null);

    try {
      const payload = await login({
        user: {
          email: data.email,
          password: data.password,
        },
      });

      if (
        payload.data.status === 200 &&
        payload.data.message === "Logged in successfully."
      ) {
        setToken(payload.headers.authorization);
        window.location.replace("/");
      }
    } catch (e) {
      const errorMessage = e.response?.data?.error || "Something went wrong.";
      setApiErrors(errorMessage);
    }
  };

  const handleLoggedInUser = () => {
    if (storedToken) {
      window.location.replace("/");
    }
  };

  useEffect(() => {
    handleLoggedInUser();
  });

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-10 px-4 text-white sm:px-6 lg:px-8">
      <div className="flex w-full max-w-sm flex-grow flex-col justify-center space-y-10">
        <h2 className="text-center text-5xl font-bold leading-10">
          SRS System
        </h2>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col space-y-10"
          >
            <div className="space-y-4">
              <div className="sm:col-span-4">
                <div>
                  <div className="flex rounded-md sm:max-w-md">
                    <Input
                      id="email"
                      type="email"
                      placeholder="Enter Email Address"
                      classNames="text-black"
                      isRequired
                    />
                  </div>
                </div>
                <div className="mt-7">
                  <div className="flex rounded-md sm:max-w-md">
                    <Input
                      id="password"
                      type="password"
                      placeholder="Enter Password"
                      classNames="text-black"
                      isRequired
                    />
                  </div>
                </div>
              </div>
            </div>
            {apiErrors && (
              <div className="text-center text-red-400">
                <p>{apiErrors}</p>
              </div>
            )}
            <div className="mt-7 flex items-center justify-center gap-x-6">
              <Button type="submit" variant="primary" classNames="w-40">
                Sign In
              </Button>
            </div>
          </form>
        </FormProvider>
        <p className="text-center text-[15px]">Forget your password?</p>
      </div>
      <div className="mx-auto flex flex-col items-center justify-center gap-2 px-2 md:flex-row md:gap-10">
        <p className="min-w-fit text-[17px] leading-9 -tracking-[0.425px]">
          Need an account?
        </p>

        <p className="text-[15px] text-center sm:text-left">
          If you don&apos;t have an SRS account, please contact your DBS
          Coordinator. If you are the DBS Coordinator, please contact the Voice
          of Prophecy at discoverschool@vop.com.
        </p>
      </div>
    </div>
  );
}
