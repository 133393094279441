import React, { useEffect, useState } from "react";
import Button from "../../../UI/Button";
import { suggestedSchoolsRequest } from "../../../../requests/students";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import Table from "../../../UI/Table/Table";
import { renderAddress } from "../../../../utils/fullAddress";
import { ToastNotification } from "../../../UI/ToastNotification";

export default function SuggestedSchools({
  setSuggestedSchools,
  setSuccess,
  selectedCourseId,
  zipCode,
  setSchool,
}) {
  const [schools, setSchools] = useState([]);

  const handleClick = async (school) => {
    if (!school.head_school_admin_id) {
      ToastNotification(
        "error",
        "No head school admin is assigned to this school."
      );
      return;
    }
    setSchool(school);
    setSuggestedSchools(false);
    setSuccess(true);
  };

  const fetchSchools = async () => {
    const res = await suggestedSchoolsRequest({
      courseId: selectedCourseId,
      zipCode: zipCode,
    });

    if (res.data) {
      setSchools(res.data);
    }
  };

  useEffect(() => {
    if (schools.length === 0) {
      fetchSchools();
    }
  }, []);

  return (
    <>
      <p className="text-[15px] text-center pb-10">
        The following schools have been found that match the students criteria.
      </p>
      <Table>
        <thead>
          <tr>
            <TableHeader classNames="text-base font-normal">School</TableHeader>
            <TableHeader classNames="text-base font-normal">Status</TableHeader>
            <TableHeader classNames="text-base font-normal">
              Church Address
            </TableHeader>
            <TableHeader classNames="text-base font-normal">
              School Address
            </TableHeader>
            <TableHeader classNames="text-base font-normal">
              Courses
            </TableHeader>
            <TableHeader />
          </tr>
        </thead>
        <tbody>
          {schools.length > 0 &&
            schools.map((school) => (
              <tr key={school.id}>
                <TableCell classNames="text-white pr-4">
                  {school.school_name}
                </TableCell>
                <TableCell classNames="text-white pr-4">
                  {school.active ? "Active" : "Inactive"}
                </TableCell>
                <TableCell classNames="text-white pr-4">
                  {renderAddress([
                    school.school_address,
                    school.school_address_2,
                    school.school_city,
                    school.school_state,
                    school.school_zip_code,
                  ])}
                </TableCell>
                <TableCell classNames="text-white pr-4">
                  {renderAddress([
                    school.church_address,
                    school.church_address_2,
                    school.church_city,
                    school.church_state,
                    school.church_zip_code,
                  ])}
                </TableCell>
                <TableCell classNames="text-white pr-4">
                  {school.courses &&
                    school.courses.map((c) => c.name).join(", ")}
                </TableCell>
                <TableCell classNames="text-white">
                  <div className="text-center">
                    <Button
                      variant="primary"
                      onClick={() => handleClick(school)}
                    >
                      Add Student
                    </Button>
                  </div>
                </TableCell>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}
