import React from "react";
import { StopIcon } from "@heroicons/react/20/solid";
import { StopIcon as StopIconOutline } from "@heroicons/react/24/outline";
import TableCell from "../../components/UI/Table/TableCell";

const findEpisodeDelivery = (enrollment, presentation) => {
  return enrollment.episode_deliveries.find(
    (episode_delivery) => episode_delivery.episode_id === presentation.id
  );
};

export const renderPresentations = (reports, enrollment) =>
  reports?.presentations?.map((presentation, index) => (
    <TableCell key={index}>
      <div className="flex mr-2">
        {findEpisodeDelivery(enrollment, presentation)?.date_completed ? (
          <StopIcon className="w-5" />
        ) : (
          <StopIconOutline className="w-5" />
        )}

        <span className="ml-1">{index + 1}</span>
      </div>
    </TableCell>
  ));
