import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import ListButtonGroup from "../../../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../../../UI/ListButtonGroup/ListButtonGroupItem";
import { Cog8ToothIcon } from "@heroicons/react/20/solid";
import QuickFind from "../../../UI/QuickFind";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import Button from "../../../UI/Button";
import { Link, useOutletContext, useRouteLoaderData } from "react-router-dom";
import { getAllUserStudentsRequest } from "../../../../requests/users";
import { fullName } from "../../../../utils/fullName";
import Modal from "../../../UI/Modal";
import PrintTicket from "../../../Students/Student/PrintTicket";
import EmptyTable from "../../../UI/EmptyTable";
import { hoverLink } from "../../../../utils/hoverLink";
import dayjs from "dayjs";
import { returnEnrollmentStatus } from "../../../../utils/enrollmentStatus";
import { checkPermission } from "../../../../utils/checkPermission";

export default function InstructorStudents() {
  const { setActionMenuModal } = useOutletContext();
  const instructor = useRouteLoaderData("user").data;
  const [printTicketModal, setPrintTicketModal] = useState({
    open: false,
    student: null,
  });

  const tableNavigation = [
    {
      label: "Active (#)",
    },
    {
      label: "Inactive (#)",
    },
  ];

  const [students, setStudents] = useState([]);

  const getStudents = async () => {
    const res = await getAllUserStudentsRequest({
      userId: instructor.id,
    });

    setStudents(res.data);
  };

  useEffect(() => {
    getStudents();
  }, []);

  return (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["school_admin", "conference_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() =>
                  setActionMenuModal({
                    open: true,
                    defaultRender: "NewStudent",
                  })
                }
              />
            )}

            <h1 className="text-xl font-bold text-[#0694C0]">STUDENTS</h1>
          </div>
          <ListButtonGroup>
            {tableNavigation.map((item, index) => (
              <ListButtonGroupItem
                key={item.id}
                label={item.label}
                isFirst={index === 0}
                isLast={index === tableNavigation.length - 1}
              />
            ))}
          </ListButtonGroup>
          <div className="flex">
            <QuickFind />
            <Cog8ToothIcon className="ml-7 w-5 cursor-pointer" />
          </div>
        </div>
        {students.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>STUDENT</TableHeader>
                <TableHeader>ENROLLMENTS</TableHeader>
                <TableHeader>CREATED</TableHeader>
                <TableHeader>SCHOOL</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/students/${student.id}/overview`}
                    >
                      {fullName(student.first_name, " ", student.last_name)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {student.enrollments.map((enrollment) => (
                      <div key={enrollment.id}>
                        <Link
                          to={`/students/${student.id}/enrollments/${enrollment.id}/management`}
                          className={hoverLink}
                        >
                          {enrollment.series_name}
                        </Link>{" "}
                        -{" "}
                        {returnEnrollmentStatus(
                          enrollment.series_id,
                          enrollment
                        )}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {dayjs(student.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/schools/${student.school.id}/overview`}
                    >
                      {student.school.school_name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        setPrintTicketModal({ open: true, student: student })
                      }
                      variant="primary-outline"
                    >
                      Print Ticket
                    </Button>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No students found for this instructor." />
        )}
      </Card>

      <Modal
        open={printTicketModal.open}
        setOpen={() => setPrintTicketModal({ open: false, student: null })}
      >
        <PrintTicket
          student={printTicketModal.student}
          closeModal={() => setPrintTicketModal({ open: false, student: null })}
        />
      </Modal>
    </div>
  );
}
