import React, { useEffect, useState } from "react";
import TableCell from "../../../UI/Table/TableCell";
import { TrashIcon } from "@heroicons/react/20/solid";
import { ToastNotification } from "../../../UI/ToastNotification";
import { updateProductRequest } from "../../../../requests/products";
import { hoverLink } from "../../../../utils/hoverLink";
import CourseProductsForm from "./CourseProductsForm";

export default function CourseProductsRow({
  product,
  handleDeleteModal,
  formOpen,
  setFormOpen,
}) {
  const [editing, setEditing] = useState({
    open: false,
    column: null,
  });

  const onSubmit = async (data) => {
    try {
      const res = await updateProductRequest({
        ...data,
        productId: product.id,
      });

      if (res.data) {
        ToastNotification("success", "Product updated successfully.");
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  useEffect(() => {
    if (!formOpen.open && formOpen.action === null) {
      setEditing({ open: false, column: null });
    }
  }, [formOpen]);

  const renderForm = () => {
    return (
      <CourseProductsForm
        onSubmit={onSubmit}
        product={product}
        setOpen={setFormOpen}
        column={editing.column}
      />
    );
  };

  return (
    <tr>
      <TableCell>
        {editing.open && editing.column === "sku" ? (
          renderForm()
        ) : (
          <span
            className={hoverLink}
            onClick={() => setEditing({ open: true, column: "sku" })}
          >
            {product.sku}
          </span>
        )}
      </TableCell>
      <TableCell>
        {editing.open && editing.column === "name" ? (
          renderForm()
        ) : (
          <span
            className={hoverLink}
            onClick={() => setEditing({ open: true, column: "name" })}
          >
            {product.name}
          </span>
        )}
      </TableCell>
      <TableCell>
        {editing.open && editing.column === "price" ? (
          renderForm()
        ) : (
          <span
            className={hoverLink}
            onClick={() => setEditing({ open: true, column: "price" })}
          >
            ${product.default_price}
          </span>
        )}
      </TableCell>
      <TableCell>{product.products_given}</TableCell>
      <TableCell>
        <TrashIcon
          onClick={(e) => handleDeleteModal(e, product.id)}
          className="w-5 cursor-pointer"
        />
      </TableCell>
    </tr>
  );
}
