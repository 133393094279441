import React from "react";
import NavGroup from "../../UI/NavGroup/NavGroup";
import NavGroupItem from "../../UI/NavGroup/NavGroupItem";

export default function InstructorNav({ instructor }) {
  const instructorNavigation = [
    {
      label: "Overview",
      url: "overview",
    },
    {
      label: "Profile",
      url: "profile",
    },
    {
      label: "Students",
      url: "students",
    },
    // {
    //   label: "Courses",
    //   url: "courses",
    // },
  ];

  return (
    <NavGroup>
      {instructorNavigation.map((item, index) => (
        <NavGroupItem
          key={item.label}
          label={item.label}
          url={`/instructors/${instructor?.id}/${item.url}`}
          isLast={index === instructorNavigation.length - 1}
          isFirst={index === 0}
        />
      ))}
    </NavGroup>
  );
}
