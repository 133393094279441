import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Card from "../../../UI/Card";
import ContactInformationForm from "./ContactInformation";
import SideSection from "./SideSection";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import {
  deleteSchoolRequest,
  updateSchoolRequest,
} from "../../../../requests/schools";
import { ToastNotification } from "../../../UI/ToastNotification";
import Button from "../../../UI/Button";
import Modal from "../../../UI/Modal";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import { displayError } from "../../../../utils/displayError";

export default function SchoolOverview() {
  const school = useRouteLoaderData("school").data;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      const selectedCourses = data.courses.map((course) => ({
        id: course.series_offered_id,
        school_id: school?.id,
        series_id: course.id,
        _destroy: false,
      }));

      const coursesToDestroy = school.courses_offered
        .filter((course) => !data.courses.some((c) => c.id === course.id))
        .map((course) => ({
          id: course.series_offered_id,
          school_id: school?.id,
          series_id: course.id,
          _destroy: true,
        }));

      const finalCourses = [...selectedCourses, ...coursesToDestroy];

      const res = await updateSchoolRequest({
        ...data,
        schoolId: school?.id,
        series_offered_attributes: finalCourses,
      });

      if (res.data) {
        ToastNotification("success", "School updated successfully.");
        navigate(".", { replace: true });
      }
    } catch (e) {
      displayError(e);
    }
  };

  const deleteSchool = async () => {
    try {
      const res = await deleteSchoolRequest({ schoolId: school?.id });

      if (res) {
        ToastNotification("success", "School deleted successfully.");
        navigate(`/schools`);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const handleDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <div className="flex">
              <div className="w-2/3 border-r border-black pr-6">
                <ContactInformationForm school={school} />
              </div>
              <div className="w-1/3 pl-6">
                <SideSection school={school} />
              </div>
            </div>
          </Card>

          <div className="flex items-center p-6">
            <Button
              classNames="md:w-auto w-full mr-4"
              variant="primary"
              type="submit"
            >
              Update School
            </Button>
            <Button
              classNames="md:w-auto w-full mr-4"
              variant="secondary"
              onClick={(e) => handleDeleteModal(e)}
              disabled={school.users_count > 0}
            >
              Delete School
            </Button>
            <div>
              {school.users_count > 0 && (
                <p>
                  This school has {school.users_count} instructors. All
                  instructors must be removed before deleting a school.
                </p>
              )}
              {school.courses_count > 0 && <p>This school has course data</p>}
              {school.seminars_hosted_count > 0 && (
                <p>
                  This school has {school.seminars_hosted_count} seminars
                  hosted.
                </p>
              )}
            </div>
          </div>
        </form>
      </FormProvider>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deleteSchool()}
        />
      </Modal>
    </div>
  );
}
