import React from "react";
import CoursesTable from "./CoursesTable";
import SeminarsTable from "./SeminarsTable";
import InstructorsTable from "./InstructorsTable";
import NeedingFollowupTable from "./NeedingFollowupTable";

export default function DesktopDashboard({
  needingFollowup,
  courses,
  seminars,
  instructors,
  isAdmin,
}) {
  return (
    <>
      <h1 className="text-[40px]">My Dashboard</h1>
      <div className="flex">
        <div className="mr-4 max-h-screen overflow-hidden w-1/2">
          <NeedingFollowupTable needingFollowup={needingFollowup} />
        </div>
        <div className="w-1/2">
          <span className="mb-4 block h-[32.5vh] max-h-screen overflow-hidden">
            <CoursesTable courses={courses} isAdmin={isAdmin} />
          </span>
          <span className="mb-4 block h-[32.5vh] max-h-screen overflow-hidden">
            <SeminarsTable seminars={seminars} isAdmin={isAdmin} />
          </span>
          <span className="block h-[32.5vh] max-h-screen overflow-hidden">
            {isAdmin && <InstructorsTable instructors={instructors} />}
          </span>
        </div>
      </div>
    </>
  );
}
