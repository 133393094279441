import React, { useState } from "react";
import Card from "../../../UI/Card";
import QuickFind from "../../../UI/QuickFind";
import { Cog8ToothIcon } from "@heroicons/react/20/solid";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import Button from "../../../UI/Button";
import ListButtonGroup from "../../../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../../../UI/ListButtonGroup/ListButtonGroupItem";
import EmptyTable from "../../../UI/EmptyTable";

export default function SeminarProducts() {
  const tableNavigation = [
    {
      label: "Enabled",
    },
    {
      label: "Correspondence",
    },
    {
      label: "Disabled",
    },
  ];

  const [products] = useState([]);

  return (
    <Card>
      <div className="flex items-center justify-between mb-6">
        <div className="relative">
          <h1 className="text-xl font-bold text-[#0694C0]">PRODUCTS</h1>
        </div>
        <ListButtonGroup>
          {tableNavigation.map((item, index) => (
            <ListButtonGroupItem
              key={item.id}
              label={item.label}
              isFirst={index === 0}
              isLast={index === tableNavigation.length - 1}
            />
          ))}
        </ListButtonGroup>
        <div className="flex">
          <QuickFind />
          <Cog8ToothIcon className="ml-7 w-5 cursor-pointer" />
        </div>
      </div>
      {products.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>SKU</TableHeader>
              <TableHeader>SCHOOLS</TableHeader>
              <TableHeader>DEFAULT PRICE</TableHeader>
              <TableHeader>GIVE/SOLD</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <TableCell>000000</TableCell>
                <TableCell>{product.school}</TableCell>
                <TableCell>{product.defaultPrice}</TableCell>
                <TableCell>000</TableCell>
                <TableCell>
                  <Button variant="primary-outline">Disable</Button>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="Work in progress." />
      )}
    </Card>
  );
}
