import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import StudentMobileNotes from "./Mobile/StudentMobileNotes";
import { getAllStudentNotesRequest } from "../../../../requests/students";
import { useRouteLoaderData } from "react-router-dom";
import dayjs from "dayjs";
import { humanize } from "../../../../utils/humanizeString";
import EmptyTable from "../../../UI/EmptyTable";

export default function StudentNotes() {
  const student = useRouteLoaderData("student").data;
  const [notes, setNotes] = useState([]);

  const getAllStudentNotes = async () => {
    const res = await getAllStudentNotesRequest({
      studentId: student.id,
    });

    if (res.data) {
      setNotes([
        ...res.data.episode_delivery_notes,
        ...res.data.reminder_notes,
      ]);
    }
  };

  useEffect(() => {
    getAllStudentNotes();
  }, []);

  return (
    <>
      <div className="md:hidden">
        <StudentMobileNotes notes={notes} />
      </div>
      <Card classNames="md:block hidden">
        {notes.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>DATE</TableHeader>
                <TableHeader>NOTE</TableHeader>
                <TableHeader>CONTEXT</TableHeader>
                <TableHeader>TYPE</TableHeader>
              </tr>
            </thead>
            <tbody>
              {notes.map((note) => (
                <tr key={note.id}>
                  <TableCell>
                    {dayjs(note.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>{note.text}</TableCell>
                  <TableCell>
                    {note.notable_type === "Reminder" ? "Reminder" : "Lessons"}
                  </TableCell>
                  <TableCell>{humanize(note.note_type)}</TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No notes found for this student." />
        )}
      </Card>
    </>
  );
}
