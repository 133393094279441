import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/enrollments`;

const createEnrollmentRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const updateEnrollmentRequest = async (params) => {
  const data = await axiosClient.put(
    `${endpoint}/${params.enrollmentId}`,
    params
  );

  return data;
};

const getEnrollmentRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.enrollmentId}`);

  return data;
};

const getDecisionSummaryRequest = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.enrollmentId}/decisions_summary`
  );

  return data;
};

const takeAttendanceRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/take_attendance`, params);

  return data;
};

const getNeedingFollowupRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/needing_followup`, params);

  return data;
};

const deleteEnrollmentRequest = async (params) => {
  return await axiosClient.delete(`${endpoint}/${params.enrollmentId}`);
};

export {
  createEnrollmentRequest,
  getEnrollmentRequest,
  updateEnrollmentRequest,
  getDecisionSummaryRequest,
  takeAttendanceRequest,
  getNeedingFollowupRequest,
  deleteEnrollmentRequest,
};
