import React, { useEffect, useState } from "react";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Checkbox from "../../../UI/Inputs/Checkbox";
import DatePickerInput from "../../../UI/Inputs/DatePickerInput";
import { ToastNotification } from "../../../UI/ToastNotification";
import { getLookupsByType } from "../../../../requests/lookups";
import InputGroup from "../../../UI/Inputs/InputGroup";

export default function DemographicsForm({ student, isAdmin }) {
  const [genders, setGenders] = useState([]);
  const maritalStatus = ["Married", "Single"];
  const [interests, setInsterests] = useState([]);
  const [sources, setSources] = useState([]);

  const getGenders = async () => {
    try {
      const res = await getLookupsByType({ lookupType: 1 });

      if (res.data) {
        setGenders(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching genders.");
    }
  };

  useEffect(() => {
    setInsterests(["Interest 1", "Interest 2"]);
    setSources(["Source 1", "Source 2"]);
    getGenders();
  }, []);

  return (
    <div>
      <h2 className="mb-6 text-2xl">Demographics</h2>

      <InputGroup>
        {genders.length > 0 && (
          <Select
            disabled={!isAdmin}
            id="gender"
            label="Gender"
            defaultValue={student?.gender}
          >
            <SelectItem value="" disabled selected>
              Select a gender
            </SelectItem>
            {genders.map((gender) => (
              <SelectItem
                key={gender.id}
                value={gender.name}
                selected={student?.gender === gender.name}
              >
                {gender.name}
              </SelectItem>
            ))}
          </Select>
        )}
      </InputGroup>

      <InputGroup>
        <Select
          disabled={!isAdmin}
          id="married"
          label="Marital Status"
          defaultValue={student?.married}
        >
          <SelectItem value="" disabled selected>
            Select marital status
          </SelectItem>
          {maritalStatus.map((option) => (
            <SelectItem
              key={option}
              value={option}
              selected={student?.married === "Married"}
            >
              {option}
            </SelectItem>
          ))}
        </Select>
      </InputGroup>

      <InputGroup>
        <DatePickerInput
          disabled={!isAdmin}
          id="birthdate"
          label="Birth Date"
          selected={student ? student.birthdate : new Date()}
        />
      </InputGroup>

      <InputGroup col>
        <Input
          disabled={!isAdmin}
          id="denomination"
          defaultValue={student?.denomination}
          placeholder="Denomination"
        />
        <Checkbox
          disabled={!isAdmin}
          id="baptized"
          defaultChecked={student?.baptized}
          label="Is baptized"
        />
      </InputGroup>

      <InputGroup>
        <Select disabled={!isAdmin} id="interests">
          <SelectItem value="" disabled selected>
            Select an interest
          </SelectItem>
          {interests.map((option) => (
            <SelectItem key={option} value={`${option}`}>
              {option}
            </SelectItem>
          ))}
        </Select>
      </InputGroup>

      <InputGroup>
        <Input
          disabled={!isAdmin}
          id="advertisingMethod"
          placeholder="Advertising Method"
        />
      </InputGroup>

      <InputGroup>
        <Select disabled={!isAdmin} id="sourceOfContact" classNames="w-full">
          <SelectItem value="" disabled selected>
            Select source of contact
          </SelectItem>
          {sources.map((option) => (
            <SelectItem key={option} value={`${option}`}>
              {option}
            </SelectItem>
          ))}
        </Select>
      </InputGroup>
    </div>
  );
}
