import React from "react";
import Table from "../../../UI/Table/Table";
import TableCell from "../../../UI/Table/TableCell";
import { fullName } from "../../../../utils/fullName";
import { useNavigate } from "react-router-dom";
import EmptyTable from "../../../UI/EmptyTable";

export default function ListView({ recentlyAssigned }) {
  const navigate = useNavigate();

  return (
    <div className="min-h-[60vh]">
      {recentlyAssigned.length > 0 ? (
        <div>
          <h2 className="text-center mb-10">
            The last 10 students assigned to teachers are listed below. Click a
            row to view student details and reassign.
          </h2>
          <div className="w-3/4 m-auto [&_td]:px-2">
            <Table>
              <thead className="text-[11px]">
                <tr className="uppercase font-bold">
                  <TableCell classNames="px-3">Student</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Zip/Postal</TableCell>
                  <TableCell>Last presentation</TableCell>
                  <TableCell>Instructor</TableCell>
                </tr>
              </thead>
              <tbody className="text-[15px]">
                {recentlyAssigned.map((student) => (
                  <tr
                    key={student.id}
                    className="cursor-pointer hover:bg-gray-200"
                    onClick={() => navigate(`/students/${student.id}/overview`)}
                  >
                    <TableCell classNames="px-3">
                      {fullName(student.first_name, " ", student.last_name)}
                    </TableCell>
                    <TableCell>
                      {[student.address, student.address_2]
                        .filter((s) => s && s !== "")
                        .join(", ")}
                    </TableCell>
                    <TableCell>{student.city}</TableCell>
                    <TableCell>{student.state}</TableCell>
                    <TableCell>{student.zip_code}</TableCell>
                    <TableCell>{student.latest_presentation?.name}</TableCell>
                    <TableCell>
                      {fullName(
                        student.instructor.first_name,
                        " ",
                        student.instructor.last_name
                      )}
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <EmptyTable message="No students assigned." />
      )}
    </div>
  );
}
