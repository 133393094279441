import React from "react";

export default function ListButtonGroup({ children }) {
  return (
    <div
      className="lg:block absolute left-1/2 inline-flex -translate-x-1/2 rounded-full shadow-sm hidden"
      role="group"
    >
      {children}
    </div>
  );
}
