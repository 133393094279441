import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/conferences`;

const getAllConferences = async (params) => {
  const data = await axiosClient.get(`${endpoint}`, params);

  return data;
};

export { getAllConferences };
