import React from "react";
import Schools from "../Schools/Schools";
import School from "../Schools/School/School";
import SchoolOverview from "../Schools/School/SchoolOverview/SchoolOverview";
import SchoolInstructors from "../Schools/School/SchoolInstructors/SchoolInstructors";
import SchoolCourses from "../Schools/School/SchoolCourses/SchoolCourses";
import SchoolSeminars from "../Schools/School/SchoolSeminars/SchoolSeminars";
import SchoolNew from "../Schools/School/SchoolNew";
import { getSchoolRequest } from "../../requests/schools";
import SchoolStudents from "../Schools/School/SchoolStudents/SchoolStudents";

export default [
  {
    path: "/schools",
    element: <Schools />,
  },
  {
    path: "/schools/new",
    element: <SchoolNew />,
  },
  {
    path: "/schools/:schoolId",
    element: <School />,
    id: "school",
    loader: ({ params }) => {
      return getSchoolRequest({ schoolId: params.schoolId });
    },
    children: [
      {
        path: "/schools/:schoolId/overview",
        element: <SchoolOverview />,
      },
      {
        path: "/schools/:schoolId/instructors",
        element: <SchoolInstructors />,
      },
      {
        path: "/schools/:schoolId/courses",
        element: <SchoolCourses />,
      },
      {
        path: "/schools/:schoolId/seminars",
        element: <SchoolSeminars />,
      },
      {
        path: "/schools/:schoolId/students",
        element: <SchoolStudents />,
      },
    ],
  },
];
