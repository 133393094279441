import React, { useState } from "react";
import TableCell from "../../../../../../UI/Table/TableCell";
import DateSent from "./DateSent";
import DateReceived from "./DateReceived";
import DeliveryType from "./DeliveryType";
import DecisionsAndNotes from "./DecisionsAndNotes";

export default function LessonsRow({
  lesson,
  decisionOptions,
  enrollment,
  user,
}) {
  const [addingDecision, setAddingDecision] = useState(false);
  const [addingNotes, setAddingNotes] = useState(false);
  const episodeDelivery = enrollment.episode_deliveries.find(
    (item) => item.episode_id === lesson.id
  );

  return (
    <tr key={lesson.id}>
      <TableCell>{lesson.name}</TableCell>
      <TableCell>
        <DateSent
          enrollment={enrollment}
          lesson={lesson}
          episodeDelivery={episodeDelivery}
          user={user}
        />
      </TableCell>
      <TableCell>
        <DateReceived
          enrollment={enrollment}
          lesson={lesson}
          episodeDelivery={episodeDelivery}
          user={user}
        />
      </TableCell>
      <TableCell>
        <DeliveryType
          enrollment={enrollment}
          lesson={lesson}
          episodeDelivery={episodeDelivery}
          user={user}
        />
      </TableCell>
      <TableCell classNames="w-[426px]">
        <DecisionsAndNotes
          addingDecision={addingDecision}
          setAddingDecision={setAddingDecision}
          decisionOptions={decisionOptions}
          addingNotes={addingNotes}
          setAddingNotes={setAddingNotes}
          lesson={lesson}
          enrollment={enrollment}
          episodeDelivery={episodeDelivery}
          user={user}
        />
      </TableCell>
    </tr>
  );
}
