import React, { useEffect, useState } from "react";
import { getSchoolContact } from "../../requests/reports";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { usePagination } from "../UI/Pagination";
import { useSearchParams } from "react-router-dom";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { Pagination } from "../UI/Pagination";
import Pill from "../UI/Pill";
import { twMerge } from "tailwind-merge";

export default function SchoolContactReport() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pagination = usePagination();

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    setLoading(true);

    try {
      const res = await getSchoolContact({
        params: {
          active: searchParams.get("status"),
          conference_code: searchParams.get("conference_code"),
          discovery_center: searchParams.get("discovery_center"),
          page: page,
          per_page: pagination.recordsPerPage,
        },
      });

      if (res.data) {
        setReports(res.data);
        pagination.setData(res.data.pagination);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReport();
  }, [
    page,
    pagination.recordsPerPage,
    searchParams.get("status"),
    searchParams.get("conference_code"),
    searchParams.get("discovery_center"),
  ]);

  return (
    <div>
      <div className="flex justify-between my-10">
        <p>Schools with City, State, Contact Info</p>
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && reports.length < 1}
        empty={reports.length < 1}
      >
        {reports.schools?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>NAME</TableHeader>
                <TableHeader>ADDRESS</TableHeader>
                <TableHeader>ADDRES 2</TableHeader>
                <TableHeader>CITY</TableHeader>
                <TableHeader>STATE</TableHeader>
                <TableHeader>ZIP/POSTAL CODE</TableHeader>
                <TableHeader>COUNTRY</TableHeader>
                <TableHeader>PHONE</TableHeader>
                <TableHeader>E-MAIL ADDRESS</TableHeader>
                <TableHeader>HEAD SCHOOL ADMIN</TableHeader>
                <TableHeader>ACTIVE</TableHeader>
                <TableHeader>SCHOOL NUMBER</TableHeader>
              </tr>
            </thead>
            <tbody>
              {reports.schools.map((school) => {
                const isActive = school.active;
                return (
                  <tr
                    className={twMerge(!isActive && "bg-[#FDD]")}
                    key={school.id}
                  >
                    <TableCell>
                      <p>{school.school_name}</p>
                      <p>{school.church_name}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_address}</p>
                      <p>{school.church_address}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_address_2}</p>
                      <p>{school.church_address_2}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_city}</p>
                      <p>{school.church_city}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_state}</p>
                      <p>{school.church_state}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_zip_code}</p>
                      <p>{school.church_zip_code}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_country}</p>
                      <p>{school.church_country}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_phone}</p>
                      <p>{school.church_phone}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_email}</p>
                      <p>{school.church_email}</p>
                    </TableCell>
                    <TableCell>
                      {school.head_school_admin?.first_name}
                    </TableCell>
                    <TableCell>
                      {isActive ? (
                        <Pill color="success">Active</Pill>
                      ) : (
                        <Pill>Inactive</Pill>
                      )}
                    </TableCell>
                    <TableCell>{school.school_code}</TableCell>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No schools recorded." />
        )}
      </Pagination>
    </div>
  );
}
