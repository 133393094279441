import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/episodes`;

const createLessonRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const bulkCreateLessonRequest = async (params) => {
  const data = await axiosClient.post(`${endpoint}/bulk_create`, params);

  return data;
};

const updateLessonRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/${params.lessonId}`, params);

  return data;
};

const getQuestionsByLessonRequest = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.lessonId}/questions`
  );

  return data;
};

const deleteLessonRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.lessonId}`);

  return data;
};

export {
  createLessonRequest,
  bulkCreateLessonRequest,
  deleteLessonRequest,
  getQuestionsByLessonRequest,
  updateLessonRequest,
};
