import React from "react";
import {
  CalendarDaysIcon,
  PencilSquareIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { ClockIcon } from "@heroicons/react/24/outline";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { checkPermission } from "../checkPermission";
import { storedToken } from "../auth";

export const dashboardLinks = [
  {
    id: 1,
    name: "Student",
    icon: <UserPlusIcon />,
    child: "NewStudent",
    show: storedToken
      ? checkPermission(["school_admin", "conference_admin"])
      : false,
  },
  {
    id: 2,
    name: "Instructor",
    icon: <UserPlusIcon />,
    path: "/instructors/new",
    show: storedToken
      ? checkPermission(["school_admin", "conference_admin"])
      : false,
  },
  {
    id: 3,
    name: "Course",
    icon: <CheckCircleIcon />,
    path: "/courses/new",
    show: storedToken ? checkPermission(["global_admin"]) : false,
  },
  {
    id: 4,
    name: "Seminar",
    icon: <ClockIcon />,
    child: "SchoolSelect",
    show: storedToken
      ? checkPermission(["school_admin", "global_admin"])
      : false,
  },
  {
    id: 5,
    name: "School",
    icon: <LockClosedIcon />,
    path: "/schools/new",
    show: storedToken ? checkPermission(["global_admin"]) : false,
  },
];

export const studentLinks = [
  {
    id: 1,
    name: "Visit",
    icon: <CalendarDaysIcon />,
    child: "NewStudentVisit",
  },
  {
    id: 2,
    name: "Lesson",
    icon: <LockClosedIcon />,
    child: "NewStudentLesson",
  },
  {
    id: 3,
    name: "Product",
    icon: <LockClosedIcon />,
    child: "NewStudentProduct",
  },
  {
    id: 4,
    name: "Reminder",
    icon: <ClockIcon />,
    child: "NewStudentReminder",
  },
  {
    id: 5,
    name: "Decision",
    icon: <CheckCircleIcon />,
    child: "NewStudentDecision",
  },
  {
    id: 6,
    name: "Note",
    icon: <PencilSquareIcon />,
    child: "NewStudentNote",
  },
];
