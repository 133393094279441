import React, { useEffect, useState } from "react";
import InputGroup from "../../../UI/Inputs/InputGroup";
import Select from "../../../UI/Inputs/Select/Select";
import {
  getAllUsersBySchoolRequest,
  getAllUsersForShareRequest,
} from "../../../../requests/users";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import { fullName } from "../../../../utils/fullName";
import { displayError } from "../../../../utils/displayError";
import MultiSelect from "../../../UI/Inputs/MultiSelect";
import { checkAllowSharing } from "../../../../utils/checkAllowSharing";

export default function Instructors({
  student,
  isAdmin,
  schoolId,
  defaultStudentShares,
}) {
  const [instructorsBySchool, setInstructorsBySchool] = useState([]);
  const [instructorsToShare, setInstructorsToShare] = useState([]);

  const getInstructors = async () => {
    try {
      const res = await getAllUsersBySchoolRequest({
        student_id: student?.id,
      });
      if (res.data) {
        if (schoolId) {
          setInstructorsBySchool(
            res.data.filter((school) => school.id === schoolId)
          );
        } else {
          setInstructorsBySchool(res.data);
        }
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getInstructorsForShare = async () => {
    try {
      const res = await getAllUsersForShareRequest();
      if (res.data) {
        const instructors = res.data.map((user) => ({
          id: user.id,
          name: fullName(user.first_name, "", user.last_name),
        }));
        setInstructorsToShare(instructors);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const renderInstructorsOptions = () => {
    if (schoolId) {
      const school = instructorsBySchool.find(
        (school) => school.id === schoolId
      );
      return (
        <>
          <SelectItem value="" disabled selected>
            Select an instructor
          </SelectItem>
          {school.users.map((user) => (
            <SelectItem key={user.id} value={`${user.id}#${school.id}`}>
              {fullName(user.first_name, "", user.last_name)}
            </SelectItem>
          ))}
        </>
      );
    } else {
      return instructorsBySchool.map((school) => (
        <optgroup label={school.school_name} key={school.id}>
          {school.users.map((user) => (
            <SelectItem key={user.id} value={`${user.id}#${school.id}`}>
              {fullName(user.first_name, "", user.last_name)}
            </SelectItem>
          ))}
        </optgroup>
      ));
    }
  };

  useEffect(() => {
    if (isAdmin) {
      getInstructors();
    }

    if (isAdmin || checkAllowSharing(student?.school_id)) {
      getInstructorsForShare();
    }
  }, []);

  return (
    <>
      {isAdmin && (
        <>
          <h2 className="mb-6 text-2xl">Instructor</h2>
          <InputGroup>
            {instructorsBySchool.length > 0 && (
              <Select
                disabled={!isAdmin}
                id="instructor_id"
                isRequired
                defaultValue={
                  schoolId
                    ? student?.instructor_id
                    : `${student?.instructor_id}#${student?.school_id}`
                }
              >
                {renderInstructorsOptions()}
              </Select>
            )}
          </InputGroup>
        </>
      )}

      {student &&
        instructorsToShare.length > 0 &&
        checkAllowSharing(student?.school_id) && (
          <>
            <h2 className="mb-6 text-2xl">Shared With</h2>
            <InputGroup>
              <MultiSelect
                id="shared_with"
                placeholder="Add Instructors"
                options={instructorsToShare}
                defaultSelected={student ? defaultStudentShares : []}
              />
            </InputGroup>
          </>
        )}
    </>
  );
}
