import React from "react";
import { Link } from "react-router-dom";
import Card from "../../UI/Card";
import BackLink from "../../Mobile/BackLink";

export default function SeminarsMobileList({ seminars }) {
  return (
    <div>
      <BackLink path="/" label="Seminars" />

      <div className="pt-4">
        {seminars.map((seminar) => (
          <Link
            key={seminar.id}
            to={`/seminars/${seminar.id}/details`}
            className="mb-4 last:mb-0 block"
          >
            <Card>
              <p>{seminar.name}</p>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
