import { parseJwt, removeToken, storedToken } from "./auth";

const user = storedToken && parseJwt(storedToken)?.user;

export const checkAllowSharing = (studentSchool) => {
  if (user === undefined) removeToken();

  if (!storedToken) return false;

  if (user?.admin) return true;

  const roles = user?.roles;

  const instructorCanShare = roles.find(
    (role) => role.role_type === "instructor" && role.allow_sharing
  );

  const isSchoolAdmin = roles.find(
    (role) =>
      role.role_type === "school_admin" &&
      role.organization_id === studentSchool
  );

  return instructorCanShare || isSchoolAdmin ? true : false;
};
