import React, { useEffect, useState } from "react";
import { Pagination, usePagination } from "../UI/Pagination";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import Card from "../UI/Card";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import Button from "../UI/Button";
import QuickFind from "../UI/QuickFind";
import ListButtonGroup from "../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../UI/ListButtonGroup/ListButtonGroupItem";
import SeminarsMobileList from "./Mobile/SeminarsMobileList";
import { getAllSeminarsPaginated } from "../../requests/seminars";
import { checkPermission } from "../../utils/checkPermission";
import { displayError } from "../../utils/displayError";
import { hoverLink } from "../../utils/hoverLink";
import EmptyTable from "../UI/EmptyTable";

export default function Seminars() {
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const { setActionMenuModal } = useOutletContext();
  const tableNavigation = [
    {
      label: "Active (#)",
    },
    {
      label: "Inactive (#)",
    },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const page = searchParams.get("page") || "1";
  const [loading, setLoading] = useState(false);
  const pagination = usePagination();
  const [seminars, setSeminars] = useState([]);

  const getSeminars = async () => {
    setLoading(true);

    try {
      const queryParams = {
        page: page,
        per_page: pagination.recordsPerPage,
        q: {},
      };

      // Only add the search parameter if query is not empty.
      // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
      // Splitting the query terms into an array for the `cont` matcher.
      if (query.trim()) {
        queryParams.q.name_i_cont_all = query.trim().split(" ");
      }

      const res = await getAllSeminarsPaginated({ params: queryParams });

      if (res.data) {
        setSeminars(res.data.seminars);
        pagination.setData(res.data.pagination);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  // Fetch seminars when the component mounts
  // and when pagination or section changes.
  useEffect(() => {
    getSeminars();
  }, [searchParams, pagination.recordsPerPage]);

  if (!isAdmin) {
    return (
      <EmptyTable message="You do not have permission to view this page" />
    );
  }

  return (
    <div>
      <div className="md:hidden">
        <SeminarsMobileList seminars={seminars} />
      </div>
      <div className="hidden md:block">
        <Card>
          <div className="flex items-center justify-between mb-6">
            <div className="relative">
              {checkPermission(["global_admin", "school_admin"]) && (
                <img
                  src="/add-new.svg"
                  className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                  width={30}
                  height={30}
                  alt="Add New"
                  onClick={() =>
                    setActionMenuModal({
                      open: true,
                      defaultRender: "SchoolSelect",
                    })
                  }
                />
              )}

              <h1 className="text-xl font-bold text-[#0694C0]">SEMINARS</h1>
            </div>
            <ListButtonGroup>
              {seminars.length > 0 &&
                tableNavigation.map((item, index) => (
                  <ListButtonGroupItem
                    key={index}
                    label={item.label}
                    isFirst={index === 0}
                    isLast={index === tableNavigation.length - 1}
                  />
                ))}
            </ListButtonGroup>
            <div className="flex">
              <QuickFind onSubmit={onSubmit} value={query} />
            </div>
          </div>
          <Pagination
            pagination={pagination}
            loading={loading && seminars.length < 1}
            empty={seminars.length < 1}
          >
            <Table>
              <thead>
                <tr>
                  <TableHeader>SEMINAR</TableHeader>
                  <TableHeader>SCHOOLS</TableHeader>
                  <TableHeader>STUDENTS</TableHeader>
                  <TableHeader>PRESENTATIONS</TableHeader>
                  <TableHeader>PRODUCTS</TableHeader>
                </tr>
              </thead>
              <tbody>
                {seminars.map((seminar) => (
                  <tr
                    key={seminar.id}
                    className={loading ? "opacity-25" : undefined}
                  >
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/seminars/${seminar.id}/details`}
                      >
                        {seminar.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/schools/${seminar.host_school?.id}/overview`}
                      >
                        {seminar.host_school?.school_name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/seminars/${seminar.id}/students`}
                      >
                        {seminar.students_count}
                      </Link>
                    </TableCell>
                    <TableCell>{seminar.presentations_count}</TableCell>
                    <TableCell>#</TableCell>
                    <TableCell>
                      <Button variant="primary-outline">Archive</Button>
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Pagination>
        </Card>
      </div>
    </div>
  );
}
