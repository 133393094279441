import React from "react";
import Print from "../UI/Print";

const SchoolsPrint = ({ schools, handleAfterPrint }) => {
  return (
    <Print
      header="SCHOOLS"
      empty={schools.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>NAME</th>
            <th>ADDRESS</th>
            <th>COURSES</th>
            <th>CONFERENCE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {schools.length > 0 &&
            schools.map((school) => (
              <tr key={school.id}>
                <td>
                  (#{school.school_code}) {school.school_name}
                </td>
                <td className="whitespace-nowrap">
                  {school.school_address && (
                    <>
                      {school.school_address}
                      {school.school_address_2 && (
                        <>
                          <br />
                          {school.school_address_2}
                        </>
                      )}
                      <br />
                      {school.school_city}
                      <br />
                      {school.school_state} {school.school_zip_code}
                      <br />
                      {school.school_country}
                    </>
                  )}
                </td>
                <td>
                  {school.courses.map((course) => course.name).join(", ")}
                </td>
                <td>{school.conference?.name}</td>
                <td>{school.active ? "Active" : "Inactive"}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Print>
  );
};

export default SchoolsPrint;
