import React from "react";
import QuestionForm from "./QuestionForm";
import { ToastNotification } from "../../../../UI/ToastNotification";
import { createQuestionRequest } from "../../../../../requests/questions";

export default function QuestionNew({ setQuestionFormOpen, lessonId }) {
  const onSubmit = async (data) => {
    try {
      const res = await createQuestionRequest({
        ...data,
        episode_id: lessonId,
      });

      if (res.data) {
        ToastNotification("success", "Question created successfully.");
        setQuestionFormOpen({ open: false, action: null });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return (
    <QuestionForm
      onSubmit={onSubmit}
      setOpen={setQuestionFormOpen}
      lessonId={lessonId}
    />
  );
}
