import React, { useState, useEffect, useRef } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";

const AutocompleteInput = ({
  options,
  value,
  onChange,
  onClear,
  placeholder,
  disabled,
  isLoading,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === value);
    setInputValue(selectedOption ? selectedOption.label : "");
  }, [value, options]);

  useEffect(() => {
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [inputValue, options]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsOpen(true);
  };

  const handleOptionSelect = (option) => {
    setInputValue(option.label);
    onChange(option.value);
    setIsOpen(false);
  };

  const handleBlur = () => {
    setTimeout(() => setIsOpen(false), 200);
  };

  const handleClear = () => {
    setInputValue("");
    onClear();
  };

  return (
    <div className="relative">
      <input
        ref={inputRef}
        type="text"
        className="p-2 w-full pr-8"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)}
        onBlur={handleBlur}
        placeholder={placeholder}
        disabled={disabled || isLoading}
      />
      {isLoading ? (
        <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
          <span className="animate-spin">⌛</span>
        </div>
      ) : (
        inputValue && (
          <XCircleIcon
            className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer hover:text-gray-600"
            onClick={handleClear}
          />
        )
      )}
      {isOpen && filteredOptions.length > 0 && !isLoading && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 max-h-60 overflow-auto">
          {filteredOptions.map((option) => (
            <li
              key={option.value}
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteInput;
