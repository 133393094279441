import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function BackLink({ label, path }) {
  return (
    <Link to={path} className="flex text-[28px]">
      <ArrowLeftIcon className="mr-1 w-7" />
      <span>{label}</span>
    </Link>
  );
}
