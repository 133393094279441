import React from "react";
import BackLink from "../../../../Mobile/BackLink";
import { Link, useRouteLoaderData } from "react-router-dom";
import Card from "../../../../UI/Card";
import { twMerge } from "tailwind-merge";

export default function StudentMobileSeminars({ seminars }) {
  const student = useRouteLoaderData("student").data;

  return (
    <div>
      <BackLink path={`/students/${student?.id}/overview`} label="Seminars" />

      <div className="pt-4">
        {seminars.map((seminar) => (
          <Link
            key={seminar.id}
            to={`/students/${student.id}/overview`}
            className="mb-4 last:mb-0 block"
          >
            <Card
              classNames={twMerge(
                "flex justify-between items-center",
                seminar.status === "Complete" && "bg-black/70 text-white"
              )}
            >
              <p className="text-[17px]">{seminar.name}</p>
              {seminar.status === "Complete" && (
                <div className="text-center text-xs">
                  <p>Complete</p>
                  <p>10/12/2021</p>
                </div>
              )}
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
