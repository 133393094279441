import React from "react";
import { twMerge } from "tailwind-merge";

export default function Button({
  children,
  variant,
  onClick,
  classNames,
  disabled,
  type = "button",
  icon = false,
  style = {},
}) {
  let btnStyle;
  const defaultStyle = twMerge(
    "cursor-pointer rounded border p-2 hover:border-black h-[38px]",
    icon && "flex items-center",
    disabled && "opacity-50 cursor-not-allowed"
  );

  switch (variant) {
    case "primary":
      btnStyle = "border-none bg-[#0694C0] text-white hover:bg-[#0385ad]";
      break;
    case "primary-outline":
      btnStyle =
        "border-[#0694C0] text-[#0694C0] hover:text-white hover:border-[#0694C0] hover:bg-[#0694C0]";
      break;
    case "secondary":
      btnStyle =
        "rounded border-none bg-gray-600 p-2 text-white hover:bg-gray-700";
      break;
    default:
      btnStyle = defaultStyle;
      break;
  }

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={twMerge(defaultStyle, btnStyle, classNames)}
      style={style}
    >
      {children}
    </button>
  );
}
