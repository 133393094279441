import React from "react";
import {
  Outlet,
  useLoaderData,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Card from "../../../../UI/Card";
import StudentCourseNav from "./StudentCourseNav";

export default function StudentCourse() {
  const enrollment = useLoaderData().data;
  const seriesEnrollment = "courseEnrollment";
  const { user } = useOutletContext();

  const params = useParams();
  const studentId = params.studentId;

  return (
    <Card>
      <StudentCourseNav enrollmentId={enrollment?.id} studentId={studentId} />

      <h1 className="text-2xl">{enrollment?.series.name}</h1>

      <div className="mt-6">
        <Outlet context={{ user, seriesEnrollment }} />
      </div>
    </Card>
  );
}
