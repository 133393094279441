import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import MultiSelect from "../../../../../../../UI/Inputs/MultiSelect";
import Button from "../../../../../../../UI/Button";

export default function DecisionsForm({
  onSubmit,
  setAddingDecision,
  decisionOptions,
  selectedDecisionOptions,
}) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <MultiSelect
          id="decisions"
          placeholder="Add Decision"
          options={decisionOptions}
          defaultSelected={selectedDecisionOptions}
        />
        <Button
          onClick={() => setAddingDecision(false)}
          classNames="mr-2"
          variant="primary-outline"
        >
          Cancel
        </Button>
        <Button type="submit" variant="primary-outline">
          Save
        </Button>
      </form>
    </FormProvider>
  );
}
