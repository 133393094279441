import React from "react";
import Note from "./Note";
import NoteNew from "./NoteNew";

export default function Notes({
  presentation,
  enrollment,
  setAddingNotes,
  addingNotes,
  episodeDelivery,
  user,
}) {
  return (
    <>
      {episodeDelivery?.notes?.length > 0 &&
        episodeDelivery?.notes?.map((note) => (
          <Note
            key={note.id}
            enrollment={enrollment}
            note={note}
            presentation={presentation}
            episodeDelivery={episodeDelivery}
          />
        ))}

      {addingNotes && (
        <NoteNew
          setAddingNotes={setAddingNotes}
          enrollment={enrollment}
          presentation={presentation}
          episodeDelivery={episodeDelivery}
          user={user}
        />
      )}
    </>
  );
}
