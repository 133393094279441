import React from "react";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import { returnEnrollmentStatus } from "../../../../utils/enrollmentStatus";
import { hoverLink } from "../../../../utils/hoverLink";
import { Link } from "react-router-dom";

export default function Seminars({ student }) {
  return (
    <>
      <h2 className="mb-2 text-xl font-bold text-[#0694C0]">SEMINARS</h2>
      {student.seminar_enrollments.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>SEMINAR</TableHeader>
              <TableHeader>PROGRESS</TableHeader>
              <TableHeader>DECISION</TableHeader>
              <TableHeader>STATUS</TableHeader>
            </tr>
          </thead>
          <tbody>
            {student.seminar_enrollments.map((enrollment) => (
              <tr key={enrollment.id}>
                <TableCell>
                  <Link
                    to={`/students/${student.id}/seminars/enrollments/${enrollment.id}/presentations`}
                    className={hoverLink}
                  >
                    {enrollment.series_name}
                  </Link>
                </TableCell>
                <TableCell>{enrollment.progress}</TableCell>
                <TableCell>{enrollment.decisions_count}</TableCell>
                <TableCell>
                  {returnEnrollmentStatus(enrollment.series_id, enrollment)}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No seminars for this student." />
      )}
    </>
  );
}
