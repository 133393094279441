import React, { useEffect, useState } from "react";
import QuestionForm from "./QuestionForm";
import ConfirmationDialog from "../../../../UI/ConfirmationDialog";
import Modal from "../../../../UI/Modal";
import { ToastNotification } from "../../../../UI/ToastNotification";
import {
  deleteQuestionRequest,
  updateQuestionRequest,
} from "../../../../../requests/questions";

export default function Question({
  question,
  deleteQuestionModalOpen,
  setDeleteQuestionModalOpen,
  questionFormOpen,
  setQuestionFormOpen,
}) {
  const [editing, setEditing] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await updateQuestionRequest({
        ...data,
        questionId: question.id,
      });

      if (res.data) {
        ToastNotification("success", "Question updated successfully.");
        setQuestionFormOpen({ open: false, action: null });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const deleteQuestion = async () => {
    try {
      const res = await deleteQuestionRequest({ questionId: question.id });

      if (res) {
        ToastNotification("success", "Question deleted successfully.");
        setDeleteQuestionModalOpen(false);
        setEditing(false);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const handleDeleteQuestionModal = (e) => {
    e.preventDefault();
    setDeleteQuestionModalOpen(true);
  };

  const handleOpenForm = () => {
    setQuestionFormOpen({ open: true, action: "edit" });
    setEditing(true);
  };

  useEffect(() => {
    if (!questionFormOpen.open && questionFormOpen.action === null) {
      setEditing(false);
    }
  }, [questionFormOpen]);

  if (editing) {
    return (
      <div>
        <QuestionForm
          setOpen={setQuestionFormOpen}
          onSubmit={onSubmit}
          question={question}
          handleDeleteQuestionModal={handleDeleteQuestionModal}
        />
        <Modal
          dark
          open={deleteQuestionModalOpen}
          setOpen={setDeleteQuestionModalOpen}
        >
          <ConfirmationDialog
            closeModal={() => setDeleteQuestionModalOpen(false)}
            confirmAction={() => deleteQuestion()}
          />
        </Modal>
      </div>
    );
  }

  return (
    <p
      onClick={() => handleOpenForm()}
      className="hover:text-[#0694C0]  cursor-pointer"
    >
      {question.text}
    </p>
  );
}
