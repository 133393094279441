import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

export default function AuthLayout() {
  return (
    <div
      className="font-halcom flex h-full min-h-screen flex-col bg-cover bg-center justify-center sm:justify-initial"
      style={{
        background: `linear-gradient(0deg, rgba(72, 81, 84, 0.80) 0%, rgba(72, 81, 84, 0.80) 100%), url(/sign_in_bg.jpg), lightgray 50%`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <main className="max-w-login mx-auto flex py-12 pb-4 sm:px-6 md:h-4/5 md:flex-grow lg:px-8">
        <Outlet />
      </main>

      <Footer />
    </div>
  );
}
