import React, { useEffect, useState } from "react";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import EmptyTable from "../../../../../UI/EmptyTable";
import AddButton from "../../../../../UI/AddButton";
import ProductsNew from "./ProductsNew";
import { useOutletContext, useRouteLoaderData } from "react-router-dom";
import { getAllCourseProductsRequest } from "../../../../../../requests/courses";
import ProductRow from "./ProductRow";

export default function Products() {
  const { seriesEnrollment } = useOutletContext();
  const enrollment = useRouteLoaderData(seriesEnrollment).data;
  const [products, setProducts] = useState([]);
  const [addingProduct, setAddingProduct] = useState(false);

  const productDeliveries = enrollment?.product_deliveries;

  const getAllCourseProducts = async () => {
    const res = await getAllCourseProductsRequest({
      courseId: enrollment?.series_id,
    });

    if (res.data) {
      setProducts(res.data);
    }
  };

  useEffect(() => {
    getAllCourseProducts();
  }, []);

  return (
    <div>
      {productDeliveries?.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>PRODUCT OFFER</TableHeader>
              <TableHeader>DELIVERED AT</TableHeader>
              <TableHeader>QUANTITY</TableHeader>
              <TableHeader>PRICE</TableHeader>
              <TableHeader>DELIVERY TYPE</TableHeader>
              <TableHeader>VISITOR</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {productDeliveries.map((delivery) => (
              <ProductRow
                key={delivery.id}
                delivery={delivery}
                enrollment={enrollment}
                products={products}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No products found." />
      )}

      {addingProduct ? (
        <div className="mt-4">
          <ProductsNew
            enrollment={enrollment}
            handleCancelAdd={() => setAddingProduct(false)}
            products={products}
          />
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <AddButton
            onClick={() => setAddingProduct(true)}
            label="Add Product"
            variant="primary-outline"
          />
        </div>
      )}
    </div>
  );
}
