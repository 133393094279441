import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const login = async (params) => {
  const data = await axiosClient.post(`${baseEndpoint}/login`, params);

  return data;
};

const logout = async () => {
  const data = await axiosClient.delete(`${baseEndpoint}/logout`);

  return data;
};

const signUp = async (params) => {
  const data = await axiosClient.post(`${baseEndpoint}/signup`, params);

  return data;
};

export { login, logout, signUp };
