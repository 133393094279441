import React from "react";
import MyAccount from "../Account/MyAccount/MyAccount";
import Settings from "../Account/Settings/Settings";

export default [
  {
    path: "/account/my-account",
    element: <MyAccount />,
  },
  {
    path: "/account/settings",
    element: <Settings />,
  },
];
