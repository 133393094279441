import React, { useEffect, useState } from "react";
import InputGroup from "../../../../UI/Inputs/InputGroup";
import {
  ClipboardIcon,
  HandRaisedIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../../UI/Inputs/Input";
import Button from "../../../../UI/Button";

export default function NoteForm({
  setAddingNotes,
  onSubmit,
  note,
  setDeleteModalOpen,
}) {
  const methods = useForm();
  const [isEditing, setIsEditing] = useState(false);

  const handleNoteTypeChange = (type) => {
    if (note) {
      setIsEditing(true);
    }

    methods.setValue("type", type);
  };

  const handleClose = () => {
    if (!note) {
      setAddingNotes(false);
    }
    setIsEditing(false);
  };

  const onSubmitAndClose = async (data) => {
    await onSubmit(data);
    handleClose();
  };

  const handleDelete = () => {
    if (note) {
      setDeleteModalOpen(true);
    } else {
      setAddingNotes(false);
    }
  };

  useEffect(() => {
    if (!note) {
      methods.setValue("type", "Note");
    } else {
      methods.setValue("type", note.note_type);
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitAndClose)}>
        <InputGroup classNames="items-center inline-grid">
          <div className="flex h-full items-center justify-start">
            <ClipboardIcon
              onClick={() => handleNoteTypeChange("Note")}
              className={twMerge(
                methods.watch("type") === "Note"
                  ? "bg-[#0694C0] text-white"
                  : "text-[#0694C0]",
                "cursor-pointer rounded-l-lg border border-r-0 border-[#0694C0] p-1 h-[30px] w-[30px]"
              )}
            />
            <HandRaisedIcon
              onClick={() => handleNoteTypeChange("PrayerRequest")}
              className={twMerge(
                methods.watch("type") === "PrayerRequest"
                  ? "bg-[#0694C0] text-white"
                  : "text-[#0694C0]",
                "cursor-pointer rounded-r-lg border border-[#0694C0] p-1 h-[30px] w-[30px]"
              )}
            />
          </div>
          <div
            onClick={() => {
              note && setIsEditing(true);
            }}
          >
            <Input
              id="text"
              defaultValue={note?.text}
              placeholder="Add Note"
              isRequired
            />
          </div>
          <TrashIcon
            className="h-5 w-5 cursor-pointer"
            onClick={() => handleDelete()}
          />
        </InputGroup>

        {(note && isEditing) || (!note && !isEditing) ? (
          <div className={twMerge(note && "mb-10")}>
            <Button
              onClick={() => handleClose()}
              classNames="mr-2"
              variant="primary-outline"
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary-outline">
              Save
            </Button>
          </div>
        ) : null}
      </form>
    </FormProvider>
  );
}
