import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/reports`;

const getAttendanceReport = async (params) => {
  const data = await axiosClient.get(`${endpoint}/attendance`, params);

  return data;
};

const getStudentFollowUpReport = async (params) => {
  const data = await axiosClient.get(`${endpoint}/student-follow-up`, params);

  return data;
};

const getStudentContactReport = async (params) => {
  const data = await axiosClient.get(`${endpoint}/student-contact`, params);

  return data;
};

const getMasterTeamReport = async (params) => {
  const data = await axiosClient.get(`${endpoint}/master-team`, params);

  return data;
};

const getClaimedZips = async (params) => {
  const data = await axiosClient.get(`${endpoint}/claimed-zips`, params);

  return data;
};

const getSchoolContact = async (params) => {
  const data = await axiosClient.get(`${endpoint}/school-contact`, params);

  return data;
};

const getSchoolInformationForms = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/school-information-forms`,
    params
  );

  return data;
};

const getSchoolAveryLabels = async (params) => {
  const data = await axiosClient.get(`${endpoint}/school-avery-labels`, params);

  return data;
};

const getNadBibleSchools = async (params) => {
  const data = await axiosClient.get(`${endpoint}/nad-bible-schools`, params);

  return data;
};

export {
  getAttendanceReport,
  getStudentFollowUpReport,
  getStudentContactReport,
  getMasterTeamReport,
  getClaimedZips,
  getSchoolContact,
  getSchoolInformationForms,
  getSchoolAveryLabels,
  getNadBibleSchools,
};
