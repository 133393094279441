import React, { useState, useEffect } from "react";

export const Tooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    return () => {
      setIsVisible(false);
    };
  }, []);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 w-max max-w-xs bottom-full left-1/2 transform -translate-x-1/2 mb-2">
          <div className="px-3 py-2 text-sm text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 whitespace-nowrap text-center">
            {content}
          </div>
        </div>
      )}
    </div>
  );
};
