import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import Card from "../../UI/Card";
import Button from "../../UI/Button";
import Checkbox from "../../UI/Inputs/Checkbox";
import { ToastNotification } from "../../UI/ToastNotification";
import PageLoader from "../../UI/PageLoader";
import { displayError } from "../../../utils/displayError";
import { updateCurrentUserRequest } from "../../../requests/users";
import ContactInformationForm from "../../Instructors/Instructor/InstructorProfile/ContactInformationForm";

export default function MyAccount() {
  const { userData, reloadUserData } = useOutletContext();
  const methods = useForm();

  const onSubmit = async (data) => {
    // Create an object to store only the changed values
    const changedData = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== userData[key]) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    try {
      // Only make the API call if there are changes
      if (Object.keys(changedData).length > 0) {
        const res = await updateCurrentUserRequest(changedData);

        if (res) {
          ToastNotification("success", "Account updated successfully.");
        }
      } else {
        ToastNotification("info", "No changes were made.");
      }
    } catch (e) {
      displayError(e);
    } finally {
      reloadUserData();
    }
  };

  if (!userData)
    return (
      <Card classNames="p-6">
        <PageLoader />
      </Card>
    );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card classNames="p-6">
          <div className="flex">
            <div className="w-3/5 border-r border-black pr-6">
              <ContactInformationForm instructor={userData} />
            </div>
            <div className="w-2/5 pl-6">
              <h2 className="mb-6 text-2xl">Options</h2>

              <div className="mb-6">
                <div className="mb-6">
                  <Checkbox
                    id="followup_notification"
                    label="Receive follow-up information"
                    defaultChecked={userData?.followup_notification}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div className="flex items-center p-6">
          <Button
            classNames="md:w-auto w-full mr-4"
            variant="primary"
            type="submit"
          >
            Update My Account
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
