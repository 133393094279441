import React, { useEffect } from "react";

const Print = ({
  header,
  empty = false,
  children,
  handleAfterPrint = () => {},
}) => {
  useEffect(() => {
    document.body.classList.add("has-printable-component");
    return () => document.body.classList.remove("has-printable-component");
  }, []);

  useEffect(() => {
    window.addEventListener("afterprint", handleAfterPrint);
    return () => window.removeEventListener("afterprint", handleAfterPrint);
  }, [handleAfterPrint]);

  if (empty) {
    return (
      <div className="print-only">
        <p>
          No printable records available.
          <br />
          Please use the "Print Records" button on page actions to render an
          updated list.
        </p>
      </div>
    );
  }

  return (
    <table className="print-only">
      <thead>
        <tr>
          <th>{header}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>{children}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Print;
