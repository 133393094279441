import React, { useEffect, useState } from "react";
import { displayError } from "../../../../utils/displayError";
import { getAllSchoolStudents } from "../../../../requests/schools";
import { Link, useOutletContext, useRouteLoaderData } from "react-router-dom";
import Card from "../../../UI/Card";
import { checkPermission } from "../../../../utils/checkPermission";
import QuickFind from "../../../UI/QuickFind";
import { Cog8ToothIcon } from "@heroicons/react/20/solid";
import TableHeader from "../../../UI/Table/TableHeader";
import Table from "../../../UI/Table/Table";
import EmptyTable from "../../../UI/EmptyTable";
import { fullName } from "../../../../utils/fullName";
import { hoverLink } from "../../../../utils/hoverLink";
import TableCell from "../../../UI/Table/TableCell";
import dayjs from "dayjs";
import { returnEnrollmentStatus } from "../../../../utils/enrollmentStatus";

export default function SchoolStudents() {
  const { setActionMenuModal } = useOutletContext();
  const school = useRouteLoaderData("school").data;
  const [students, setStudents] = useState([]);

  const getSchoolInstructors = async () => {
    try {
      const res = await getAllSchoolStudents({ school_id: school.id });

      if (res.data) {
        setStudents(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    getSchoolInstructors();
  }, []);

  return (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["school_admin", "conference_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() =>
                  setActionMenuModal({
                    open: true,
                    defaultRender: "NewStudent",
                  })
                }
              />
            )}

            <h1 className="text-xl font-bold text-[#0694C0]">STUDENTS</h1>
          </div>
          <div className="flex">
            <QuickFind />
            <Cog8ToothIcon className="ml-7 w-5 cursor-pointer" />
          </div>
        </div>
        {students.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>STUDENT</TableHeader>
                <TableHeader>ENROLLMENTS</TableHeader>
                <TableHeader>CREATED</TableHeader>
                <TableHeader>INSTRUCTOR</TableHeader>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/students/${student.id}/overview`}
                    >
                      {fullName(student.first_name, " ", student.last_name)}
                    </Link>
                  </TableCell>
                  <TableCell classNames="text-sm min-w-[300px]">
                    {student.enrollments.map((enrollment) => (
                      <div
                        key={enrollment.id}
                        className="flex flex-row space-x-2"
                      >
                        {enrollment.series_type === "Seminar" ? (
                          <>
                            <div className="w-5/12">
                              <Link
                                to={`/students/${student.id}/seminars/enrollments/${enrollment.id}/presentations`}
                                className={hoverLink}
                              >
                                {enrollment.series_name}
                              </Link>
                            </div>
                            <div className="w-3/12 whitespace-nowrap text-xs">
                              {enrollment.registration_number || "-"}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="w-5/12">
                              <Link
                                to={`/students/${student.id}/courses/enrollments/${enrollment.id}/lessons`}
                                className={hoverLink}
                              >
                                {enrollment.series_name}
                              </Link>
                            </div>
                            <div className="w-3/12 whitespace-nowrap text-xs">
                              {enrollment.last_completed_delivery
                                ? dayjs(
                                    enrollment.last_completed_delivery
                                      ?.date_completed
                                  ).format("MM/DD/YYYY")
                                : "No lessons"}
                            </div>
                          </>
                        )}
                        <div className="w-4/12 text-xs">
                          {returnEnrollmentStatus(
                            enrollment.series_id,
                            enrollment
                          )}
                        </div>
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {dayjs(student.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/instructors/${student.instructor_id}/overview`}
                    >
                      {student.instructor_name}
                    </Link>
                    {student.student_shares.length > 0 && (
                      <ul className="ml-2 text-xs text-yellow-500">
                        {student.student_shares.map((share) => (
                          <li key={share.id}>{share.user_name}</li>
                        ))}
                      </ul>
                    )}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No students found." />
        )}
      </Card>
    </div>
  );
}
