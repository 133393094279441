import React from "react";
import { twMerge } from "tailwind-merge";

export default function Pill({ children, classNames, color = "default" }) {
  let badgeColor;

  switch (color) {
    case "default":
      badgeColor = "bg-[#0694C0]";
      break;
    case "success":
      badgeColor = "bg-green-500";
      break;
    default:
      badgeColor = "bg-[#0694C0]";
  }

  return (
    <div
      className={twMerge(
        "inline-flex items-center border rounded-full pl-2 pr-1 py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white",
        badgeColor,
        classNames
      )}
    >
      {children}
    </div>
  );
}
