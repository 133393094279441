import React from "react";
import { useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import PageLoader from "./PageLoader";

const RECORDS_PER_PAGE_KEY = "recordsPerPage";

export const usePagination = (initialRecordsPerPage = 20) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    currentPage: parseInt(searchParams.get("page") || "1", 10),
    totalPages: 1,
    totalCount: 0,
    recordsPerPage: parseInt(
      localStorage.getItem(RECORDS_PER_PAGE_KEY) ||
        initialRecordsPerPage.toString(),
      10
    ),
    prevPage: null,
    nextPage: null,
    outOfRange: false,
  });

  const setData = useCallback((apiPagination) => {
    setPagination({
      currentPage: apiPagination.current_page,
      totalPages: apiPagination.total_pages,
      totalCount: apiPagination.total_count,
      recordsPerPage: apiPagination.records_per_page,
      prevPage: apiPagination.prev_page,
      nextPage: apiPagination.next_page,
      outOfRange: apiPagination.out_of_range,
    });
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      setSearchParams((prev) => {
        prev.set("page", page.toString());
        return prev;
      });
    },
    [setSearchParams]
  );

  const handleRecordsPerPageChange = useCallback(
    (newRecordsPerPage) => {
      localStorage.setItem(RECORDS_PER_PAGE_KEY, newRecordsPerPage.toString());
      setPagination((prev) => ({
        ...prev,
        recordsPerPage: newRecordsPerPage,
      }));
      setSearchParams((prev) => {
        prev.set("page", "1");
        return prev;
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    const savedRecordsPerPage = localStorage.getItem(RECORDS_PER_PAGE_KEY);
    if (savedRecordsPerPage) {
      setPagination((prev) => ({
        ...prev,
        recordsPerPage: parseInt(savedRecordsPerPage, 10),
      }));
    }
  }, []);

  return {
    ...pagination,
    setData,
    handlePageChange,
    handleRecordsPerPageChange,
  };
};

const CustomPagination = ({
  currentPage,
  totalPages,
  handlePageChange,
  handleRecordsPerPageChange,
  totalCount,
  recordsPerPage,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex items-center justify-between pt-3">
      <div className="flex items-center xl:w-[300px]">
        <p className="text-sm text-gray-700">
          Displaying {(currentPage - 1) * recordsPerPage + 1}-
          {Math.min(currentPage * recordsPerPage, totalCount)} of {totalCount}
        </p>

        <div className="ml-6 select-wrapper">
          <select
            value={recordsPerPage}
            onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
            className="w-20 px-2 py-1 text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            {[20, 50, 100, 1000, 10000].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <div>
          <nav className="isolate inline-flex" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center rounded-full px-2 py-2 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 transition-colors duration-200 ${
                currentPage === 1 && "text-[#999] hover:bg-transparent"
              }`}
            >
              <span className="sr-only">First page</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center rounded-full px-2 py-2 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 transition-colors duration-200 ${
                currentPage === 1 && "text-[#999] hover:bg-transparent"
              }`}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handlePageChange(number)}
                className={`relative inline-flex items-center mx-1 px-4 py-2 text-sm rounded-full ${
                  number === currentPage
                    ? "z-10 bg-gray-50 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    : "hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                } transition-colors duration-200`}
              >
                {number}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center rounded-full px-2 py-2 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 transition-colors duration-200 ${
                currentPage === totalPages && "text-[#999] hover:bg-transparent"
              }`}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center rounded-full px-2 py-2 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 transition-colors duration-200 ${
                currentPage === totalPages && "text-[#999] hover:bg-transparent"
              }`}
            >
              <span className="sr-only">Last page</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>

      <div className="xl:w-[300px]" />
    </div>
  );
};

export const Pagination = ({ pagination, loading, empty, children }) =>
  loading ? (
    <PageLoader />
  ) : (
    <>
      {empty ? (
        <div className="flex items-center justify-center h-[400px]">
          <div className="text-center">
            <h1 className="mb-4 font-extrabold leading-none tracking-tight text-gray-900">
              No records found
            </h1>
          </div>
        </div>
      ) : (
        <>
          {children}
          <CustomPagination {...pagination} />
        </>
      )}
    </>
  );
