import React from "react";
import { useMatches } from "react-router-dom";

export default function Breadcrumb() {
  let matches = useMatches();
  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  return (
    <ol className="md:hidden justify-center mb-8 flex">
      {crumbs.map((crumb, index) => (
        <li key={index}>{crumb}</li>
      ))}
    </ol>
  );
}
