import React, { useState, Fragment } from "react";
import { Outlet, useLoaderData, useOutletContext } from "react-router-dom";
import { fullName } from "../../../utils/fullName";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import StudentNav from "./StudentNav";

export default function Student() {
  const { user } = useOutletContext();
  const student = useLoaderData().data;
  const [navOpen, setNavOpen] = useState(true);

  const renderAddress = () => {
    const hasFullAddress = student.city && student.state;
    const partialAddress = student.city || student.state;

    if (hasFullAddress) {
      return `| ${student.city}, ${student.state}`;
    }

    if (partialAddress) {
      return `| ${partialAddress}`;
    }

    return "";
  };

  return (
    <div>
      <div
        className={twMerge(
          !navOpen && "mb-7",
          "md:mb-0 mb-7 flex items-center justify-center text-center"
        )}
      >
        <h1 className="md:block hidden text-2xl">
          {fullName(student?.first_name, " ", student?.last_name)}{" "}
          {renderAddress()}
        </h1>

        <div className="md:hidden block">
          <h1 className="text-2xl text-[40px] mb-4">
            {fullName(student?.first_name, " ", student?.last_name)}
          </h1>
          <p>
            {student?.city}, {student?.state}
          </p>
        </div>

        <EllipsisVerticalIcon
          className="md:block hidden ml-5 w-6 cursor-pointer"
          onClick={() => setNavOpen(!navOpen)}
        />
      </div>

      <Transition.Root show={navOpen} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="md:block hidden">
            <StudentNav student={student} />
          </div>
        </Transition.Child>
      </Transition.Root>

      <Outlet context={{ user }} />
    </div>
  );
}
