import React from "react";
import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";

export default function SearchSchool({
  options,
  id,
  label,
  placeholder,
  borderless,
  isRequired,
  index,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: twMerge(
        errors[id] && "#EF4444",
        borderless && "transparent"
      ),
      boxShadow: state.isFocused ? "0 0 0 1px gray" : "none",
      "&:hover": {
        borderColor: "none",
      },
      minHeight: "38px",
      height: "38px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "38px",
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ffffff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#444" : "transparent",
      color: state.isFocused ? "white" : "#d1d5db",
      "&:hover": {
        backgroundColor: "#444",
        color: "white",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
  };

  return (
    <div>
      {label && <p>{label}</p>}
      <Controller
        name={id}
        control={control}
        defaultValue=""
        rules={{
          required: isRequired ? "This field is required" : false,
        }}
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            styles={customStyles}
            placeholder={placeholder || "Select..."}
          />
        )}
      />

      {index >= 0 ? (
        <>
          {errors?.[id.split(".")[0]]?.[id.split(".")[1]]?.[
            id.split(".")[2]
          ] && (
            <p className="absolute text-red-500 text-sm">
              {
                errors?.[id.split(".")[0]]?.[id.split(".")[1]]?.[
                  id.split(".")[2]
                ]?.message
              }
            </p>
          )}
        </>
      ) : (
        <>
          {errors[id] && (
            <p className="absolute text-red-500">{errors[id]?.message}</p>
          )}
        </>
      )}
    </div>
  );
}
