import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/question_responses`;

const getAllQuestionResponseRequest = async (params) => {
  const data = await axiosClient.get(endpoint, params);

  return data;
};

const createQuestionResponseRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const updateQuestionResponseRequest = async (params) => {
  const data = await axiosClient.put(
    `${endpoint}/${params.responseId}`,
    params
  );

  return data;
};

export {
  createQuestionResponseRequest,
  getAllQuestionResponseRequest,
  updateQuestionResponseRequest,
};
