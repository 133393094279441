import React, { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { Outlet, useLoaderData, useOutletContext } from "react-router-dom";
import SeminarNav from "./SeminarNav";
import PageActions from "../../UI/PageActions";

export default function Seminar() {
  const { user } = useOutletContext();
  const seminar = useLoaderData("seminar").data;
  const [navOpen, setNavOpen] = useState(true);

  return (
    <div>
      <div
        className={twMerge(
          !navOpen && "mb-7",
          "flex items-center justify-center"
        )}
      >
        <h1 className="text-2xl">{seminar.name}</h1>
        <EllipsisVerticalIcon
          className="ml-5 w-6 cursor-pointer"
          onClick={() => setNavOpen(!navOpen)}
        />
      </div>

      <div className="relative">
        <Transition.Root show={navOpen} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div>
              <SeminarNav seminar={seminar} />
              <div className="absolute top-2 right-0">
                <PageActions />
              </div>
            </div>
          </Transition.Child>
        </Transition.Root>
      </div>

      <Outlet context={{ user, seminar }} />
    </div>
  );
}
