import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../../UI/Inputs/Input";
import Button from "../../../../UI/Button";
import InputGroup from "../../../../UI/Inputs/InputGroup";

export default function TakeAttendanceForm({ onSubmit }) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="text-center">
        <p className="mb-2">
          Scan a ticket or enter the student's registration number:
        </p>

        <div className="flex justify-center">
          <InputGroup classNames="mb-2 w-1/3 pr-4">
            <Input id="registration_number" dark isRequired autoFocus />
          </InputGroup>
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
