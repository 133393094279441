import React, { useEffect, useState } from "react";
import { getAllStudents } from "../../../requests/students";
import { displayError } from "../../../utils/displayError";
import { useSearchParams } from "react-router-dom";
import { Pagination, usePagination } from "../../UI/Pagination";
import Table from "../../UI/Table/Table";
import TableHeader from "../../UI/Table/TableHeader";
import Button from "../../UI/Button";
import IncludeInMailingListRow from "./IncludeInMailingListRow";
import DownloadAveryLabels from "./DownloadAveryLabels";
import QuickFind from "../../UI/QuickFind";
import StudentsFilter from "../../Students/StudentsFilter";
import { useFilterValues } from "../../UI/Filter/Filter";

export default function StudentAveryLabelsReports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const query = searchParams.get("query") || "";
  const [loading, setLoading] = useState(false);
  const pagination = usePagination();
  const [students, setStudents] = useState([]);
  const [pdfPreview, setPdfPreview] = useState(false);
  const filterValues = useFilterValues();

  const getStudents = async () => {
    setLoading(true);

    try {
      const res = await getAllStudents({
        params: {
          page: page,
          per_page: pagination.recordsPerPage,
          q: {
            full_name_i_cont_all: query.split(" "),
            ...filterValues,
          },
        },
      });

      if (res.data) {
        setStudents(res.data.students);
        pagination.setData(res.data.pagination);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStudents();
  }, [page, pagination.recordsPerPage, pdfPreview, searchParams]);

  return (
    <div className="my-10">
      {pdfPreview ? (
        <DownloadAveryLabels setPdfPreview={setPdfPreview} />
      ) : (
        <>
          <div className="flex justify-between mb-10 items-center">
            <div className="flex">
              <b className="mr-2">Step One:</b>
              <p>
                Please review the address and include or exclude names as
                needed.
              </p>
            </div>
            <div className="flex">
              <QuickFind
                onSubmit={(data) =>
                  setSearchParams((searchParams) => {
                    searchParams.set("query", data.search);
                    return searchParams;
                  })
                }
                value={query}
              />
              <div className="relative mx-7">
                <StudentsFilter />
              </div>
              <Button
                variant="primary"
                classNames="ml-auto"
                onClick={() => setPdfPreview(true)}
              >
                View and Download Avery 5160 Labels
              </Button>
            </div>
          </div>
          <Pagination
            pagination={pagination}
            loading={loading && students.length < 1}
            empty={students.length < 1}
          >
            <Table>
              <thead>
                <tr className="border-b">
                  <TableHeader classNames="pb-2 uppercase w-[190px]">
                    Include?
                  </TableHeader>
                  <TableHeader classNames="pb-2 uppercase">
                    First Name
                  </TableHeader>
                  <TableHeader classNames="pb-2 uppercase">
                    Last Name
                  </TableHeader>
                  <TableHeader classNames="pb-2 uppercase">
                    Address 1
                  </TableHeader>
                  <TableHeader classNames="pb-2 uppercase">
                    Address 2
                  </TableHeader>
                  <TableHeader classNames="pb-2 uppercase">City</TableHeader>
                  <TableHeader classNames="pb-2 uppercase">State</TableHeader>
                  <TableHeader classNames="pb-2 uppercase">
                    Postal Code
                  </TableHeader>
                  <TableHeader classNames="pb-2 uppercase">Country</TableHeader>
                  <TableHeader classNames="pb-2 uppercase w-[180px]">
                    Last Updated
                  </TableHeader>
                </tr>
              </thead>
              <tbody>
                {students.length > 0 &&
                  students.map((student) => (
                    <IncludeInMailingListRow
                      student={student}
                      loading={loading}
                      key={student.id}
                    />
                  ))}
              </tbody>
            </Table>
          </Pagination>
        </>
      )}
    </div>
  );
}
