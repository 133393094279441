import React from "react";
import Button from "./Button";

export default function ConfirmationDialog({
  closeModal,
  confirmAction,
  label = "This action cannot be undone",
  title = "Are you sure?",
}) {
  return (
    <div className="p-6 text-white">
      <p className="text-2xl mb-3">{title}</p>
      <p>{label}</p>
      <div className="flex mt-6">
        <Button
          variant="primary-outline"
          classNames="mr-4"
          onClick={closeModal}
          className="mr-6"
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={confirmAction}>
          Confirm
        </Button>
      </div>
    </div>
  );
}
