import React, { useEffect, useState } from "react";
import { getMasterTeamReport } from "../../requests/reports";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { usePagination } from "../UI/Pagination";
import { useSearchParams } from "react-router-dom";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { fullName } from "../../utils/fullName";
import { Pagination } from "../UI/Pagination";
import { renderAddress } from "../../utils/fullAddress";
import QuickFind from "../UI/QuickFind";
import StudentsFilter from "../Students/StudentsFilter";
import { useFilterValues } from "../UI/Filter/Filter";

export default function MasterTeamReport({ seminar }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const query = searchParams.get("query") || "";
  const pagination = usePagination();
  const filterValues = useFilterValues();

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    setLoading(true);

    try {
      const res = await getMasterTeamReport({
        params: {
          seminar_id: searchParams.get("seminar_id"),
          page: page,
          per_page: pagination.recordsPerPage,
          q: {
            full_name_i_cont_all: query.split(" "),
            ...filterValues,
          },
        },
      });

      if (res.data) {
        setReports(res.data);
        pagination.setData(res.data.pagination);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReport();
  }, [page, seminar, pagination.recordsPerPage, searchParams]);

  return (
    <div>
      <div className="flex justify-between my-10">
        <p>Master Team Report for {seminar?.name}</p>
        <div className="flex">
          <QuickFind
            onSubmit={(data) =>
              setSearchParams((searchParams) => {
                searchParams.set("query", data.search);
                return searchParams;
              })
            }
            value={query}
          />
          <div className="relative ml-7">
            <StudentsFilter />
          </div>
        </div>
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && reports?.length < 1}
        empty={reports?.length < 1}
      >
        {reports.enrollments?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>
                  STUDENT
                  <br /> PHONE
                </TableHeader>
                <TableHeader>ADDRESS</TableHeader>
                <TableHeader>LATEST FOLLOW-UP DATE</TableHeader>
                <TableHeader>LATEST FOLLOW-UP ACTIVITY</TableHeader>
              </tr>
            </thead>
            <tbody>
              {reports?.enrollments.map((enrollment) => (
                <tr key={enrollment.id}>
                  <TableCell>
                    {fullName(
                      enrollment.student.first_name,
                      "",
                      enrollment.student.last_name
                    )}
                  </TableCell>
                  <TableCell classNames="max-w-[100px] p-2">
                    {renderAddress([
                      enrollment.student.address,
                      "",
                      enrollment.student.city,
                      enrollment.student.state,
                      enrollment.student.zip_code,
                      "",
                    ])}
                  </TableCell>
                  <TableCell>
                    {enrollment?.latest_followup_activity?.created_at || "-"}
                  </TableCell>
                  <TableCell>
                    {enrollment?.latest_followup_activity?.activity || "-"}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No students for this seminar." />
        )}
      </Pagination>
    </div>
  );
}
