import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/states`;

const getAllStates = async () => {
  const data = await axiosClient.get(endpoint);

  return data;
};

const getStatesByCountry = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}?country_id=${params.countryId}`
  );

  return data;
};

const getStatesByCountryName = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}?country_name=${params.countryName}`
  );

  return data;
};

export { getAllStates, getStatesByCountry, getStatesByCountryName };
