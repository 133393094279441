import React from "react";
import { ToastNotification } from "../../../UI/ToastNotification";
import { createPresentationRequest } from "../../../../requests/presentations";
import SeminarPresentationForm from "./SeminarPresentationForm";
import { displayError } from "../../../../utils/displayError";

export default function SeminarPresentationRowNew({ setFormOpen, seminarId }) {
  const onSubmit = async (data) => {
    try {
      const res = await createPresentationRequest({
        ...data,
        type: "Presentation",
        series_id: seminarId,
      });

      if (res.data) {
        ToastNotification("success", "Presentation created successfully.");
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <div className="mt-4">
      <SeminarPresentationForm onSubmit={onSubmit} setOpen={setFormOpen} />
    </div>
  );
}
