import React from "react";
import { ToastNotification } from "../../../UI/ToastNotification";
import { createDecisionRequest } from "../../../../requests/decisions";
import SeminarDecisionsForm from "./SeminarDecisionsForm";
import { displayError } from "../../../../utils/displayError";

export default function SeminarDecisionsNew({ setFormOpen, seminarId }) {
  const onSubmit = async (data) => {
    try {
      const res = await createDecisionRequest({
        ...data,
        series_id: seminarId,
      });

      if (res.data) {
        ToastNotification("success", "Decision created successfully.");
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <div className="mt-4">
      <SeminarDecisionsForm setOpen={setFormOpen} onSubmit={onSubmit} />
    </div>
  );
}
