import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import StudentMobileDecisions from "./Mobile/StudentMobileDecisions";
import EmptyTable from "../../../UI/EmptyTable";
import { useRouteLoaderData } from "react-router-dom";
import { getAllStudentDecisionsRequest } from "../../../../requests/students";
import dayjs from "dayjs";

export default function StudentDecisions() {
  const student = useRouteLoaderData("student").data;
  const [decisions, setDecisions] = useState([]);

  const getAllStudentDecisions = async () => {
    const res = await getAllStudentDecisionsRequest({
      studentId: student.id,
    });

    if (res.data) {
      setDecisions(res.data);
    }
  };

  useEffect(() => {
    getAllStudentDecisions();
  }, []);

  return (
    <>
      <div className="md:hidden">
        <StudentMobileDecisions decisions={decisions} />
      </div>
      <Card classNames="md:block hidden">
        {decisions.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>DECISION</TableHeader>
                <TableHeader>CONTEXT</TableHeader>
                <TableHeader>DATE</TableHeader>
              </tr>
            </thead>
            <tbody>
              {decisions.map((decision) => (
                <tr key={decision.id}>
                  <TableCell>{decision.decision_option.name}</TableCell>
                  <TableCell>{decision.context}</TableCell>
                  <TableCell>
                    {dayjs(decision.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No decisions found." />
        )}
      </Card>
    </>
  );
}
