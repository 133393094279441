import React from "react";
import NoteForm from "../../NoteForm";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import { useNavigate } from "react-router-dom";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";

export default function NoteNew({ enrollment, visit, setAddingNotes }) {
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        visits_attributes: [
          {
            id: visit.id,
            notes_attributes: [
              {
                text: data.text,
                type: data.type,
              },
            ],
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Note created successfully.");
        navigate(".", { replace: true });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return <NoteForm setAddingNotes={setAddingNotes} onSubmit={onSubmit} />;
}
