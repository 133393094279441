import React from "react";
import Card from "../UI/Card";
import { Link } from "react-router-dom";

export default function MobileDashboard({
  students,
  courses,
  seminars,
  instructors,
  isAdmin,
}) {
  return (
    <div>
      <div className="mb-4 text-center">
        <img src="/logo.png" width={260} className="m-auto" />
        <p className="text-lg font-bold">Student Relationship System</p>
      </div>
      <div className="flex mb-4">
        <Card classNames="mr-4 w-1/2 text-center">
          <Link to="/courses">
            <p className="text-[40px]">{courses.length}</p>
            <p className="text-[15px]">Courses</p>
          </Link>
        </Card>
        <Card classNames="w-1/2 text-center">
          <Link to="/seminars">
            <p className="text-[40px]">{seminars.length}</p>
            <p className="text-[15px]">Seminars</p>
          </Link>
        </Card>
      </div>
      <div className="flex">
        <Card classNames="mr-4 w-1/2 text-center">
          <Link to="/students">
            <p className="text-[40px]">{students.length}</p>
            <p className="text-[15px]">Students</p>
          </Link>
        </Card>
        {isAdmin && (
          <Card classNames="w-1/2 text-center">
            <Link to="/instructors">
              <p className="text-[40px]">{instructors.length}</p>
              <p className="text-[15px]">Instructors</p>
            </Link>
          </Card>
        )}
      </div>
    </div>
  );
}
