import React from "react";
import ActionMenuHeader from "./ActionMenuHeader";
import { twMerge } from "tailwind-merge";

export default function ActionMenuContainer({
  children,
  label,
  handleGoingBack,
  clickToClose,
  light,
}) {
  return (
    <div
      className={twMerge("lg:min-w-[870px]", !light && "text-white font-thin")}
    >
      <ActionMenuHeader
        label={label}
        goBack={handleGoingBack}
        clickToClose={clickToClose}
        light={light}
      />
      <div className="p-6">{children}</div>
    </div>
  );
}
