import React from "react";
import AddButton from "../../../../../../UI/AddButton";
import Notes from "./Notes/Notes";
import Decisions from "./Decisions/Decisions";

export default function DecisionsAndNotes({
  addingDecision,
  setAddingDecision,
  decisionOptions,
  addingNotes,
  setAddingNotes,
  lesson,
  enrollment,
  episodeDelivery,
  user,
}) {
  const handleAddNote = () => {
    setAddingNotes(true);
  };

  return (
    <>
      <Notes
        lesson={lesson}
        enrollment={enrollment}
        episodeDelivery={episodeDelivery}
        setAddingNotes={setAddingNotes}
        addingNotes={addingNotes}
        user={user}
      />

      <Decisions
        addingDecision={addingDecision}
        decisionOptions={decisionOptions}
        setAddingDecision={setAddingDecision}
        lesson={lesson}
        enrollment={enrollment}
        episodeDelivery={episodeDelivery}
        addingNotes={addingNotes}
        user={user}
      />
      <div className="flex">
        {!addingDecision && !addingNotes && (
          <>
            <AddButton
              onClick={() => handleAddNote()}
              label="Note"
              variant="primary-outline"
            />
            <AddButton
              onClick={() => setAddingDecision(!addingDecision)}
              label="Decision"
              variant="primary-outline"
              classNames="mx-2"
            />
          </>
        )}
      </div>
    </>
  );
}
