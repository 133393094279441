import React from "react";
import AddButton from "../../../../../UI/AddButton";
import Notes from "./Notes/Notes";
import Decisions from "./Decisions/Decisions";

export default function DecisionsAndNotes({
  addingDecision,
  setAddingDecision,
  decisionOptions,
  addingNotes,
  setAddingNotes,
  visit,
  enrollment,
}) {
  const handleAddNote = () => {
    setAddingNotes(true);
  };

  return (
    <>
      <Notes
        visit={visit}
        enrollment={enrollment}
        addingNotes={addingNotes}
        setAddingNotes={setAddingNotes}
      />

      <Decisions
        addingDecision={addingDecision}
        decisionOptions={decisionOptions}
        setAddingDecision={setAddingDecision}
        visit={visit}
        enrollment={enrollment}
        addingNotes={addingNotes}
      />
      <div className="flex">
        {!addingDecision && !addingNotes && (
          <>
            <AddButton
              onClick={() => handleAddNote()}
              label="Note"
              variant="primary-outline"
            />
            <AddButton
              onClick={() => setAddingDecision(!addingDecision)}
              label="Decision"
              variant="primary-outline"
              classNames="mx-2"
            />
          </>
        )}
      </div>
    </>
  );
}
