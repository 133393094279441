import React from "react";
import NewStudent from "../../SeminarStudents/NewStudent";

export default function TakeAttendanceCreateNew({
  seminar,
  handleReset,
  registrationNumber,
  presentation,
  user,
}) {
  return (
    <NewStudent
      seminar={seminar}
      closeModal={() => handleReset()}
      reloadStudents={null}
      registrationNumber={registrationNumber}
      presentation={presentation}
      user={user}
      takingAttendance
    />
  );
}
