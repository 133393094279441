import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import Card from "../../UI/Card";
import ContactInformationForm from "./InstructorProfile/ContactInformationForm";
import AccountForm from "./InstructorProfile/AccountForm";
import Button from "../../UI/Button";
import { signUp } from "../../../requests/auth";
import { displayError } from "../../../utils/displayError";
import { ToastNotification } from "../../UI/ToastNotification";
import { useNavigate } from "react-router-dom";

export default function InstructorNew() {
  const navigate = useNavigate();
  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      const payload = await signUp({
        user: {
          ...data,
          roles_attributes: data.roles_attributes.map((role) => ({
            id: role.role_id,
            organization_id: role.organization_id,
            organization_type:
              role.role_type === "conference_admin" ? "Conference" : "School",
            role_type: role.role_type,
            allow_sharing: role.allow_sharing,
            _destroy: role.delete,
          })),
        },
      });

      if (payload.status === 201) {
        ToastNotification("success", "Instructor created successfully.");
        navigate(`/instructors/${payload.data.id}/overview`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center mb-7">
        <h1 className="text-2xl">New Instructor | Location</h1>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card classNames="p-6">
            <div className="flex">
              <div className="w-3/5 border-r border-black pr-6">
                <ContactInformationForm />
              </div>
              <div className="w-2/5 pl-6">
                <AccountForm />
              </div>
            </div>
          </Card>

          <div className="flex items-center p-6">
            <Button variant="primary" type="submit">
              Create Instructor
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
