import React, { useEffect, useState } from "react";
import { getAllCourses } from "../../../../requests/courses";
import InputGroup from "../../../UI/Inputs/InputGroup";
import MultiSelect from "../../../UI/Inputs/MultiSelect";

export default function CoursesOffered({ coursesOffered, newSchool }) {
  const [courses, setCourses] = useState([]);

  const getCourses = async () => {
    const res = await getAllCourses({ pagination: false });

    if (res.data) {
      setCourses(res.data);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <div>
      <h2 className="mb-6 text-2xl">Courses Offered</h2>
      {!newSchool && (
        <>
          {courses.length > 0 ? (
            <InputGroup>
              <MultiSelect
                id="courses"
                placeholder="Add Courses"
                options={courses}
                defaultSelected={coursesOffered}
              />
            </InputGroup>
          ) : (
            <p>No courses available.</p>
          )}
        </>
      )}
    </div>
  );
}
