import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function NavGroupItem({ label, url }) {
  const activeLink =
    "bg-gradient-to-r from-cyan-200 to-[#0694C0] text-white rounded-full";

  return (
    <NavLink
      to={url}
      className={({ isActive }) =>
        twMerge(
          isActive && activeLink,
          "min-w-[100px] px-4 py-3 text-sm font-medium"
        )
      }
    >
      {label}
    </NavLink>
  );
}
