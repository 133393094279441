import React from "react";
import { Link } from "react-router-dom";
import Card from "../../UI/Card";
import BackLink from "../../Mobile/BackLink";
import { fullName } from "../../../utils/fullName";

export default function InstructorsMobileList({ instructors }) {
  return (
    <div>
      <BackLink path="/" label="Instructors" />

      <div className="pt-4">
        {instructors.map((instructor) => (
          <Link
            key={instructor.id}
            to={`/instructors/${instructor.id}/overview`}
            className="mb-4 last:mb-0 block"
          >
            <Card>
              <p>
                {fullName(instructor.first_name, " ", instructor.last_name)}
              </p>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
