import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/episodes`;

const createPresentationRequest = async (params) => {
  return await axiosClient.post(endpoint, params);
};

const updatePresentationRequest = async (params) => {
  return await axiosClient.put(`${endpoint}/${params.presentationId}`, params);
};

const deletePresentationRequest = async (params) => {
  return await axiosClient.delete(`${endpoint}/${params.presentationId}`);
};

export {
  createPresentationRequest,
  deletePresentationRequest,
  updatePresentationRequest,
};
