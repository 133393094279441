import axios from "axios";
import qs from "qs";
import { storedToken } from "./auth";

const axiosClient = axios.create({
  // eslint-disable-next-line no-undef
  baseUrl: process.env.REACT_APP_API_URL,
  // eslint-enable-next-line no-undef
  headers: {
    Authorization: storedToken,
    "Content-type": "application/json",
  },
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "brackets" }),
});

export default axiosClient;
