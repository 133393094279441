import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Card from "../../UI/Card";
import ContactInformationForm from "./StudentProfile/ContactInformationForm";
import DemographicsForm from "./StudentProfile/DemographicsForm";
import { createStudentRequest } from "../../../requests/students";
import { ToastNotification } from "../../UI/ToastNotification";
import Button from "../../UI/Button";
import { checkPermission } from "../../../utils/checkPermission";
import Instructors from "./StudentProfile/Instructors";
import { displayError } from "../../../utils/displayError";

export default function StudentNew() {
  const [searchParams] = useSearchParams();
  const schoolId = searchParams.get("school_id");
  const methods = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    data.married = data.married === "Married";

    try {
      const res = await createStudentRequest({
        ...data,
        instructor_id: data.instructor_id.split("#")[0],
        school_id: data.instructor_id.split("#")[1],
      });

      if (res.data) {
        ToastNotification("success", "Student created successfully.");
        navigate(`/students/${res.data.id}/overview`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const isAdmin = checkPermission(["school_admin", "conference_admin"]);

  return (
    <div>
      <div className="flex items-center justify-center mb-7">
        <h1 className="text-2xl">New Student | Location</h1>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card classNames="p-6">
            <div className="flex md:flex-row flex-col">
              <div className="md:w-3/5 md:border-r md:border-black md:pr-6">
                <ContactInformationForm isAdmin={isAdmin} />
              </div>
              <div className="md:w-2/5 md:pl-6">
                <Instructors isAdmin={isAdmin} schoolId={schoolId} />
                <DemographicsForm isAdmin={isAdmin} />
              </div>
            </div>
          </Card>
          <div className="flex items-center p-6">
            <Button
              classNames="md:w-auto w-full mb-4 mr-4"
              variant="primary"
              type="submit"
            >
              Create Student
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
