import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import Card from "../../UI/Card";
import Button from "../../UI/Button";
import { ToastNotification } from "../../UI/ToastNotification";
import PageLoader from "../../UI/PageLoader";
import { displayError } from "../../../utils/displayError";
import { updateCurrentUserImagesRequest } from "../../../requests/users";

export default function Settings() {
  const { userData, reloadUserData } = useOutletContext();
  const methods = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();

    if (data.profile_picture && data.profile_picture[0]) {
      formData.append("user[profile_picture]", data.profile_picture[0]);
    }
    if (data.background_image && data.background_image[0]) {
      formData.append("user[background_image]", data.background_image[0]);
    }
    if (data.remove_profile_picture) {
      formData.append("user[remove_profile_picture]", "1");
    }
    if (data.remove_background_image) {
      formData.append("user[remove_background_image]", "1");
    }

    try {
      const res = await updateCurrentUserImagesRequest(formData);

      if (res) {
        ToastNotification("success", "Images updated successfully.");
      }
    } catch (e) {
      displayError(e);
    } finally {
      reloadUserData();
      methods.setValue("remove_profile_picture", false);
      methods.setValue("remove_background_image", false);
    }
  };

  if (!userData)
    return (
      <Card classNames="p-6">
        <PageLoader />
      </Card>
    );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <Card classNames="p-6">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="w-full md:w-1/3 border-r border-black pr-6">
              <h2 className="mb-6 text-2xl">Profile Picture</h2>
              <input
                type="file"
                {...methods.register("profile_picture")}
                accept="image/*"
                className="w-full"
              />

              <div className="my-4 text-sm text-gray-500">
                Must be a PNG or JPEG and less than 2MB.
              </div>

              {userData.profile_picture_url && (
                <>
                  <img
                    className="max-w-[200px] mt-2"
                    src={userData.profile_picture_url}
                    alt="Profile Picture"
                  />

                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      {...methods.register("remove_profile_picture")}
                      className="mr-2"
                      defaultChecked={false}
                    />
                    Remove profile picture
                  </label>
                </>
              )}
            </div>
            <div className="w-full md:w-2/3">
              <h2 className="mb-6 text-2xl">Background Image</h2>
              <input
                type="file"
                {...methods.register("background_image")}
                accept="image/*"
                className="w-full"
              />

              <div className="my-4 text-sm text-gray-500">
                Must be a PNG or JPEG and less than 5MB.
              </div>

              {userData.background_image_url && (
                <>
                  <img
                    className="max-w-full mt-2"
                    src={userData.background_image_url}
                    alt="Background Image"
                  />

                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      {...methods.register("remove_background_image")}
                      className="mr-2"
                      defaultChecked={false}
                    />
                    Remove background image
                  </label>
                </>
              )}
            </div>
          </div>
        </Card>
        <div className="flex items-center p-6">
          <Button
            classNames="md:w-auto w-full mr-4"
            variant="primary"
            type="submit"
          >
            Update Settings
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
