export const studentReportOptions = [
  {
    label: "Attendance Report",
    value: "attendance_report",
    component: "AttendanceReport",
    requireSeminar: true,
  },
  {
    label: "Attendance Decision Report",
    value: "attendance_decision_report",
    component: "AttendanceDecisionReport",
    requireSeminar: true,
  },
  {
    label: "Student Follow-up Report",
    value: "student_followup_report",
    component: "StudentFollowupReport",
    requireSeminar: true,
  },
  {
    label: "Student Contact Report",
    value: "student_contact_report",
    component: "StudentContactReport",
    requireSeminar: false,
  },
  {
    label: "Master Team Report",
    value: "master_team_report",
    component: "MasterTeamReport",
    requireSeminar: true,
  },
  {
    label: "Student Address Labels (5160 Avery)",
    value: "student_avery_labels_report",
    component: "StudentAveryLabelsReports",
    requireSeminar: false,
  },
];

export const schoolReportOptions = [
  {
    label: "Schools with Claimed Zips",
    value: "claimed_zips_report",
    component: "ClaimedZipsReport",
    school: true,
  },
  {
    label: "Schools with City, State, Contact Info",
    value: "school_contact_report",
    component: "SchoolContactReport",
    school: true,
  },
  {
    label: "School Information Forms",
    value: "school_information_forms_report",
    component: "SchoolInformationFormsReport",
    school: true,
  },
  {
    label: "School Avery 5160 Labels",
    value: "school_avery_labels_report",
    component: "SchoolAveryLabelsReport",
    school: true,
  },
  {
    label: "NAD Bible School List",
    value: "nad_bible_school_report",
    component: "NadBibleSchoolsReport",
    school: true,
  },
];
