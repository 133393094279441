import React, { createContext, useContext, useState } from "react";

const PageActionsContext = createContext();

export const PageActionsProvider = ({ children }) => {
  const [pageActions, setPageActions] = useState([]);

  const value = {
    pageActions,
    setPageActions,
  };

  return (
    <PageActionsContext.Provider value={value}>
      {children}
    </PageActionsContext.Provider>
  );
};

export const usePageActions = () => {
  const context = useContext(PageActionsContext);
  if (!context) {
    throw new Error("usePageActions must be used within a PageActionsProvider");
  }
  return context;
};
