import NewStudent from "../../components/ActionMenu/Dashboard/NewStudent/NewStudent";
import NewStudentVisit from "../../components/ActionMenu/Student/Visit/NewStudentVisit";
import NewStudentLesson from "../../components/ActionMenu/Student/Lesson/NewStudentLesson";
import NewStudentProduct from "../../components/ActionMenu/Student/Product/NewStudentProduct";
import NewStudentReminder from "../../components/ActionMenu/Student/Reminder/NewStudentReminder";
import NewStudentDecision from "../../components/ActionMenu/Student/Decision/NewStudentDecision";
import NewStudentNote from "../../components/ActionMenu/Student/Note/NewStudentNote";
import SchoolSelect from "../../components/ActionMenu/Dashboard/Seminar/SchoolSelect";

export const actionMenuChild = {
  NewStudent: NewStudent,
  NewStudentVisit: NewStudentVisit,
  NewStudentLesson: NewStudentLesson,
  NewStudentProduct: NewStudentProduct,
  NewStudentReminder: NewStudentReminder,
  NewStudentDecision: NewStudentDecision,
  NewStudentNote: NewStudentNote,
  SchoolSelect: SchoolSelect,
};
