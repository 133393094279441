export default function localeFormat(date) {
  if (!date) {
    return date;
  }

  date = new Date(date)
    .toLocaleString()
    .replaceAll("/", "-")
    .replaceAll(",", "");
  return date;
}
