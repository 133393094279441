import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/decision_options`;

const createDecisionRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const updateDecisionRequest = async (params) => {
  const data = await axiosClient.put(
    `${endpoint}/${params.decisionId}`,
    params
  );

  return data;
};

const deleteDecisionRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.decisionId}`);

  return data;
};

export { createDecisionRequest, deleteDecisionRequest, updateDecisionRequest };
