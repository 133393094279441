import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Login from "./Auth/Login/Login";
import AuthLayout from "./Layouts/Auth/AuthLayout";
import PrivateRoute from "./Routes/PrivateRoute";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import MainLayout from "./Layouts/Main/MainLayout";
import Dashboard from "./Dashboard/Dashboard";
import studentRoutes from "./Routes/studentRoutes";
import instructorRoutes from "./Routes/instructorRoutes";
import coursesRoutes from "./Routes/coursesRoutes";
import seminarsRoutes from "./Routes/seminarsRoutes";
import schoolsRoutes from "./Routes/schoolsRoutes";
import accountRoutes from "./Routes/accountRoutes";
import axiosClient from "../utils/axios";
import { removeToken } from "../utils/auth";
import { ToastNotification } from "./UI/ToastNotification";
import reportRoutes from "./Routes/reportRoutes";

export default function App() {
  useEffect(() => {
    axiosClient.interceptors.response.use(
      (res) => res,
      (error) => {
        const isTokenExpired = error.response.data === "Signature has expired";
        const unprocessableEntity = 422;
        const unauthorized = 403;

        if (error.response.status === unauthorized) {
          ToastNotification(
            "error",
            "You are not authorized to access this page"
          );
        }

        if (
          window.location.href !== `${window.origin}/login` &&
          error.response.status !== unprocessableEntity
        ) {
          if (isTokenExpired || error.response.data === "nil user") {
            removeToken();
            window.location.href = `${window.origin}/login`;
          }
        }

        return Promise.reject(error);
      }
    );
  }, []);

  const router = createBrowserRouter([
    {
      path: "*",
      element: <Navigate to="/login" replace />,
    },
    {
      element: <AuthLayout />,
      children: [
        {
          path: "/login",
          element: <Login />,
        },
      ],
    },
    {
      element: (
        <PrivateRoute>
          <MainLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
      ].concat(
        studentRoutes,
        instructorRoutes,
        coursesRoutes,
        seminarsRoutes,
        schoolsRoutes,
        reportRoutes,
        accountRoutes
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}
