import React, { useState } from "react";
import TableCell from "../../../../../UI/Table/TableCell";
import { TrashIcon } from "@heroicons/react/20/solid";
import Modal from "../../../../../UI/Modal";
import ConfirmationDialog from "../../../../../UI/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { updateEnrollmentRequest } from "../../../../../../requests/enrollments";
import { hoverLink } from "../../../../../../utils/hoverLink";
import dayjs from "dayjs";
import ProductsForm from "./ProductsForm";

export default function ProductRow({ delivery, enrollment, products }) {
  const [editing, setEditing] = useState({
    open: false,
    column: null,
  });
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        product_deliveries_attributes: [
          {
            id: delivery.id,
            product_id: data.product_id,
            delivered_at: data.delivered_at,
            quantity: data.quantity,
            price: data.price || delivery.product.default_price,
            visitor_name: data.visitor_name,
            delivery_type: data.delivery_type,
            _destroy: data === "destroy" ? true : false,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        const toastMessage =
          data === "destroy"
            ? "Product offer deleted successfully."
            : "Product offer updated successfully.";
        ToastNotification("success", toastMessage);
        setEditing({ open: false, column: null });
        navigate(".", { replace: true });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const renderForm = () => {
    return (
      <ProductsForm
        products={products}
        enrollment={enrollment}
        delivery={delivery}
        handleCancelAdd={() => setEditing({ open: false, column: null })}
        onSubmit={onSubmit}
        column={editing.column}
      />
    );
  };

  return (
    <tr key={delivery.id}>
      <TableCell>
        {editing.open && editing.column === "product_id" ? (
          renderForm()
        ) : (
          <span
            className={hoverLink}
            onClick={() => setEditing({ open: true, column: "product_id" })}
          >
            {delivery.product.name}
          </span>
        )}
      </TableCell>
      <TableCell>
        {editing.open && editing.column === "delivered_at" ? (
          renderForm()
        ) : (
          <span
            className={hoverLink}
            onClick={() => setEditing({ open: true, column: "delivered_at" })}
          >
            {dayjs(delivery.delivered_at).format("MM/DD/YYYY")}
          </span>
        )}
      </TableCell>
      <TableCell>
        {editing.open && editing.column === "quantity" ? (
          renderForm()
        ) : (
          <span
            className={hoverLink}
            onClick={() => setEditing({ open: true, column: "quantity" })}
          >
            {delivery.quantity}
          </span>
        )}
      </TableCell>
      <TableCell>
        {editing.open && editing.column === "price" ? (
          renderForm()
        ) : (
          <span
            className={hoverLink}
            onClick={() => setEditing({ open: true, column: "price" })}
          >
            ${delivery.price}
          </span>
        )}
      </TableCell>
      <TableCell>
        {editing.open && editing.column === "delivery_type" ? (
          renderForm()
        ) : (
          <span
            className={hoverLink}
            onClick={() => setEditing({ open: true, column: "delivery_type" })}
          >
            {delivery.delivery_type}
          </span>
        )}
      </TableCell>
      <TableCell>
        {editing.open && editing.column === "visitor_name" ? (
          renderForm()
        ) : (
          <span
            className={hoverLink}
            onClick={() => setEditing({ open: true, column: "visitor_name" })}
          >
            {delivery.visitor_name}
          </span>
        )}
      </TableCell>
      <TableCell>
        <TrashIcon
          onClick={() => setDeleteModalOpen(true)}
          className="cursor-pointer w-5"
        />
      </TableCell>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => onSubmit("destroy")}
        />
      </Modal>
    </tr>
  );
}
