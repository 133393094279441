import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import InputGroup from "../../../../../UI/Inputs/InputGroup";
import DatePickerInput from "../../../../../UI/Inputs//DatePickerInput";
import Button from "../../../../../UI/Button";

export default function VisitForm({ onSubmit, visit, handleCancelAdd }) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form className="max-w-[400px]" onSubmit={methods.handleSubmit(onSubmit)}>
        <InputGroup classNames="mb-0">
          <DatePickerInput
            id="date"
            selected={visit?.date || new Date()}
            autoFocus
          />
          <Button onClick={() => handleCancelAdd()} variant="primary-outline">
            Cancel
          </Button>
          <Button type="submit" variant="primary-outline">
            Save
          </Button>
        </InputGroup>
      </form>
    </FormProvider>
  );
}
