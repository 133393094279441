import React from "react";
import ActionMenuContainer from "../../ActionMenu/ActionMenuContainer";
import { useForm, FormProvider } from "react-hook-form";
import Select from "../../UI/Inputs/Select/Select";
import SelectItem from "../../UI/Inputs/Select/SelectItem";
import Input from "../../UI/Inputs/Input";
import Button from "../../UI/Button";

export default function PrintTicket({ closeModal }) {
  const methods = useForm();

  const onSubmit = (data) => data;

  return (
    <div className="bg-neutral-600 relative">
      <ActionMenuContainer label="Print Ticket" clickToClose={closeModal}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex">
              <div className="w-2/5 mr-4">
                <p>School</p>
                <Select id="targeted_school" dark>
                  <SelectItem value="" disabled selected>
                    Select targeted school
                  </SelectItem>
                </Select>
              </div>

              <div className="w-2/5">
                <p>Seminar</p>
                <Select id="targeted_school" dark>
                  <SelectItem value="" disabled selected>
                    Select targeted seminar
                  </SelectItem>
                </Select>
              </div>
            </div>
            <div className="mt-6">
              <p>Generate tickets for registration numbers in the range:</p>
              <div className="flex mt-4">
                <div className="mr-4 w-2/5">
                  <Input id="range_start" type="number" dark />
                </div>
                <div className="mr-4 w-2/5">
                  <Input id="range_end" type="number" dark />
                </div>
                <div>
                  <Button type="submit" variant="primary" classNames="h-[38px]">
                    SUBMIT
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <p>Registration number assigned to ### students</p>
            </div>
          </form>
        </FormProvider>
      </ActionMenuContainer>
    </div>
  );
}
