import React from "react";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { updateEnrollmentRequest } from "../../../../../../requests/enrollments";
import { useNavigate } from "react-router-dom";
import VisitForm from "./VisitForm";
import { displayError } from "../../../../../../utils/displayError";

export default function VisitNew({ enrollment, handleCancelAdd }) {
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        visits_attributes: [
          {
            date: data.date,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Visit created successfully.");
        navigate(".", { replace: true });
        handleCancelAdd();
      }
    } catch (e) {
      displayError(e);
    }
  };

  return <VisitForm onSubmit={onSubmit} handleCancelAdd={handleCancelAdd} />;
}
