import React from "react";

export default function SelectItem({
  value,
  disabled,
  children,
  selected,
  classNames,
}) {
  return (
    <option
      value={value}
      disabled={disabled}
      selected={selected}
      className={classNames}
    >
      {children}
    </option>
  );
}
