import React, { useState } from "react";
import { TrashIcon } from "@heroicons/react/20/solid";
import TableCell from "../../../../../UI/Table/TableCell";
import ReminderForm from "./ReminderForm";
import Notes from "./Notes/Notes";
import AddButton from "../../../../../UI/AddButton";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Modal from "../../../../../UI/Modal";
import ConfirmationDialog from "../../../../../UI/ConfirmationDialog";
import { updateEnrollmentRequest } from "../../../../../../requests/enrollments";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { hoverLink } from "../../../../../../utils/hoverLink";

export default function ReminderRow({ reminder, enrollment }) {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addingNotes, setAddingNotes] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleAddNote = () => {
    setAddingNotes(true);
  };

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        reminders_attributes: [
          {
            id: reminder.id,
            date: data.date,
            _destroy: data === "destroy" ? true : false,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        const toastMessage =
          data === "destroy"
            ? "Reminder deleted successfully."
            : "Reminder updated successfully.";
        ToastNotification("success", toastMessage);
        setEditing(false);
        navigate(".", { replace: true });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return (
    <tr>
      <TableCell>
        {editing ? (
          <ReminderForm
            reminder={reminder}
            enrollment={enrollment}
            handleCancelAdd={() => setEditing(false)}
            onSubmit={onSubmit}
          />
        ) : (
          <span onClick={() => setEditing(true)} className={hoverLink}>
            {dayjs(reminder.date).format("MM/DD/YYYY")}
          </span>
        )}
      </TableCell>
      <TableCell classNames="w-[560px]">
        <Notes
          reminder={reminder}
          enrollment={enrollment}
          addingNotes={addingNotes}
          setAddingNotes={setAddingNotes}
        />
        {!addingNotes && (
          <AddButton
            onClick={() => handleAddNote()}
            label="Note"
            variant="primary-outline"
          />
        )}
      </TableCell>
      <TableCell>
        <TrashIcon
          onClick={() => setDeleteModalOpen(true)}
          className="cursor-pointer w-5"
        />
      </TableCell>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => onSubmit("destroy")}
        />
      </Modal>
    </tr>
  );
}
