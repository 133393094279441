import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import Card from "../UI/Card";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import EmptyTable from "../UI/EmptyTable";
import { Link } from "react-router-dom";
import { hoverLink } from "../../utils/hoverLink";

export default function SeminarsTable({ seminars, isAdmin }) {
  return (
    <Card classNames="h-full overflow-y-scroll no-scrollbar">
      <div className="flex items-center justify-between mb-6">
        <h2 className="font-bold text-[#0694C0]">SEMINARS</h2>
        {isAdmin && (
          <Link to="/seminars">
            <ArrowTopRightOnSquareIcon className="h-[32px] w-[32px] text-[#C4C4C4]" />
          </Link>
        )}
      </div>
      {seminars.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>SEMINAR</TableHeader>
              <TableHeader>STUDENTS</TableHeader>
              <TableHeader>NEEDING-FOLLOW-UP</TableHeader>
            </tr>
          </thead>
          <tbody>
            {seminars.map((seminar) => (
              <tr key={seminar.id}>
                <TableCell>
                  <TableCell>
                    {isAdmin ? (
                      <Link
                        className={hoverLink}
                        to={`/seminars/${seminar?.id}/details`}
                      >
                        {seminar.name}
                      </Link>
                    ) : (
                      seminar.name
                    )}
                  </TableCell>
                </TableCell>
                <TableCell>
                  <Link
                    className={hoverLink}
                    to={`/seminars/${seminar?.id}/students`}
                  >
                    {seminar.students_count}
                  </Link>
                </TableCell>
                <TableCell>-</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No seminars available." />
      )}
    </Card>
  );
}
