import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/schools`;

const getAllSchools = async (params) => {
  const data = await axiosClient.get(`${endpoint}?page=${params.page}`, params);

  return data;
};

const getSchoolRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.schoolId}`);

  return data;
};

const getAllSchoolInstructors = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.school_id}/instructors`,
    {
      params: params,
    }
  );

  return data;
};

const getAllSchoolSeminars = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.school_id}/seminars_hosted`,
    {
      params: params,
    }
  );

  return data;
};

const getAllSchoolCourses = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.school_id}/courses`,
    {
      params: params,
    }
  );

  return data;
};

const getAllSchoolStudents = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.school_id}/students`,
    {
      params: params,
    }
  );

  return data;
};

const createSchoolRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const updateSchoolRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/${params.schoolId}`, params);

  return data;
};

const deleteSchoolRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.schoolId}`);

  return data;
};

const updateSchoolClaimedZipCodes = async (params) => {
  const data = await axiosClient.post(
    `${endpoint}/${params.schoolId}/claimed_zips`,
    params
  );

  return data;
};

const downloadCsvSchoolsRequest = async (params) => {
  try {
    const response = await axiosClient.get(`${endpoint}.csv`, {
      ...params,
      responseType: "blob",
    });

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a link element, set the download attribute with a filename
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `Schools-${new Date().toISOString().split("T")[0]}.csv`;

    // Append to the document body and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export {
  getAllSchools,
  createSchoolRequest,
  getAllSchoolInstructors,
  updateSchoolRequest,
  getSchoolRequest,
  deleteSchoolRequest,
  getAllSchoolCourses,
  getAllSchoolStudents,
  getAllSchoolSeminars,
  updateSchoolClaimedZipCodes,
  downloadCsvSchoolsRequest,
};
