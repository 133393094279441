import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/products`;

const createProductRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const updateProductRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/${params.productId}`, params);

  return data;
};

const deleteProductRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.productId}`);

  return data;
};

export { createProductRequest, deleteProductRequest, updateProductRequest };
