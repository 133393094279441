import { ToastNotification } from "../components/UI/ToastNotification";

export const displayError = (error) => {
  let errorMessage;

  if ([404, 500].includes(error.response?.status) || !error.response) {
    errorMessage = "Something went wrong.";
  } else if (error.response.status === 422) {
    if (Array.isArray(error.response.data)) {
      errorMessage = error.response.data.join(", ");
    } else {
      if (typeof error.response.data === "string") {
        errorMessage = error.response.data;
      } else {
        errorMessage = "";
        for (const key of Object.keys(error.response.data)) {
          error.response.data[key].map((value) => {
            let _key = key?.replaceAll("_", " "); // remove underscore
            _key = _key[0]?.toUpperCase() + _key?.slice(1); // to capitalize
            errorMessage += `${_key} ${value}, `;
          });
        }

        errorMessage = errorMessage?.slice(0, -2);
      }
    }
  } else {
    errorMessage = error.response.statusText;
  }

  if (!errorMessage) {
    errorMessage = "Something went wrong.";
  }

  ToastNotification("error", errorMessage);
};
