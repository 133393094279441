import React from "react";

const FooterInfo = ({ title, description }) => {
  return (
    <div className="self-center md:row-start-1">
      <p className="text-3xl leading-tight -tracking-[2px] text-[#35CDBB] md:leading-normal">
        {title}
      </p>
      <p className="text-login-white text-[15px] leading-7 -tracking-[0.6px] [text-wrap:balance]">
        {description}
      </p>
    </div>
  );
};

export default function Footer() {
  return (
    <footer className="hidden sm:block border-t border-white bg-[#485154] bg-opacity-95 p-4 py-20 text-white mix-blend-hard-light">
      <div className="max-w-login mx-auto grid grid-cols-2 grid-rows-3 gap-4 md:grid-cols-5 md:grid-rows-1">
        <img
          src="/logo-footer-login.png"
          style={{
            width: 180,
            height: 60,
          }}
          alt="VOP-srs"
          className="col-span-2 h-auto max-w-full self-center justify-self-center md:col-span-1 md:col-start-5"
        />

        <FooterInfo
          title="View Progress"
          description="View your students' progress through their Bible study guides."
        />

        <FooterInfo
          title="Detailed Reports"
          description="Run detailed reports and filter by course, lesson type, etc."
        />

        <FooterInfo
          title="Export Data"
          description="Export information to CSV or convert it to Excel format."
        />

        <p className="text-login-white self-center text-center text-[17px] leading-9 -tracking-[0.425px] md:col-start-1 md:row-start-1">
          Benefits
        </p>
      </div>
    </footer>
  );
}
