import React from "react";
import TableCell from "../../../../../UI/Table/TableCell";
import QuestionNew from "./QuestionNew";
import QuestionResponseForm from "./QuestionResponseForm";
import { updateQuestionResponseRequest } from "../../../../../../requests/questionResponses";
import { ToastNotification } from "../../../../../UI/ToastNotification";

export default function QuestionRow({ question, enrollment, response }) {
  const onSubmit = async (data) => {
    try {
      const res = await updateQuestionResponseRequest({
        ...data,
        responseId: response?.id,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return (
    <tr key={question.id}>
      <TableCell>{question.episode.name}</TableCell>
      <TableCell>{question.text}</TableCell>
      <TableCell>
        {response ? (
          <QuestionResponseForm onSubmit={onSubmit} response={response} />
        ) : (
          <QuestionNew question={question} enrollment={enrollment} />
        )}
      </TableCell>
    </tr>
  );
}
