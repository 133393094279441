import React, { useEffect, useState } from "react";
import { getClaimedZips } from "../../requests/reports";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { usePagination } from "../UI/Pagination";
import { useSearchParams } from "react-router-dom";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { Pagination } from "../UI/Pagination";
import { renderAddress } from "../../utils/fullAddress";
import Pill from "../UI/Pill";

export default function ClaimedZipsReport() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pagination = usePagination();

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    setLoading(true);

    try {
      const res = await getClaimedZips({
        params: {
          active: searchParams.get("status"),
          conference_code: searchParams.get("conference_code"),
          discovery_center: searchParams.get("discovery_center"),
          page: page,
          per_page: pagination.recordsPerPage,
        },
      });

      if (res.data) {
        setReports(res.data);
        pagination.setData(res.data.pagination);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReport();
  }, [
    page,
    pagination.recordsPerPage,
    searchParams.get("status"),
    searchParams.get("conference_code"),
    searchParams.get("discovery_center"),
  ]);

  return (
    <div>
      <div className="flex justify-between my-10">
        <p>Schools with Claimed Zips Report</p>
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && reports?.length < 1}
        empty={reports?.length < 1}
      >
        {reports.schools?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>NAME</TableHeader>
                <TableHeader>ADDRESS</TableHeader>
                <TableHeader>CONFERENCE</TableHeader>
                <TableHeader>CLAIMED ZIPS</TableHeader>
                <TableHeader>STATUS</TableHeader>
              </tr>
            </thead>
            <tbody>
              {reports.schools.map((school) => (
                <tr key={school.id}>
                  <TableCell>
                    {`(${school.school_code})
                ${school.church_name}`}
                  </TableCell>
                  <TableCell classNames="max-w-[100px] p-2">
                    {renderAddress([
                      school.church_address,
                      "",
                      school.church_city,
                      school.church_state,
                      school.church_zip_code,
                      "",
                    ])}
                  </TableCell>
                  <TableCell>{school.conference?.name}</TableCell>
                  <TableCell>
                    {school.claimed_zips.map((claimed_zip) => (
                      <p key={claimed_zip.id}>{claimed_zip.zip}</p>
                    ))}
                  </TableCell>
                  <TableCell>
                    {school.active ? (
                      <Pill color="success">Active</Pill>
                    ) : (
                      <Pill>Inactive</Pill>
                    )}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No schools with claimed zips." />
        )}
      </Pagination>
    </div>
  );
}
