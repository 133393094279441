import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import { Link } from "react-router-dom";
import { hoverLink } from "../../../../utils/hoverLink";
import { fullName } from "../../../../utils/fullName";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default function StudentsTable({ instructor, needingFollowup }) {
  dayjs.extend(relativeTime);

  return (
    <Card classNames="overflow-y-scroll no-scrollbar">
      <div className="flex items-center justify-between mb-6">
        <h2 className="font-bold text-[#0694C0]">STUDENTS NEEDING FOLLOW-UP</h2>
        <Link to={`/instructors/${instructor.id}/students`}>
          <ArrowTopRightOnSquareIcon className="h-[32px] w-[32px] text-[#C4C4C4]" />
        </Link>
      </div>
      {needingFollowup.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>STUDENT NAME</TableHeader>
              <TableHeader>COURSE</TableHeader>
              <TableHeader>LAST FOLLOW UP</TableHeader>
            </tr>
          </thead>
          <tbody>
            {needingFollowup.map((followup) => (
              <tr key={followup.id}>
                <TableCell>
                  <Link
                    className={hoverLink}
                    to={`/students/${followup.student.id}/overview`}
                  >
                    {fullName(
                      followup.student.first_name,
                      " ",
                      followup.student.last_name
                    )}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    className={hoverLink}
                    to={`/students/${followup.student.id}/enrollments/${followup.id}/management`}
                  >
                    {followup.series.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {dayjs().to(dayjs(followup.cached_latest_followup_date))}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No students needing followup." />
      )}
    </Card>
  );
}
