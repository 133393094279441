import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function MobileNavigation({
  baseNavigation,
  setMobileMenuOpen,
}) {
  return (
    <div className="fixed inset-0 z-[9990] lg:hidden">
      <div className="h-full w-full divide-y overflow-y-auto bg-[#2C2C2C] p-4 pb-12">
        <div className="pb-4">
          <div className="flex items-center justify-end">
            <div className="-mr-2 pt-1">
              <div
                onClick={() => setMobileMenuOpen(false)}
                className="relative inline-flex items-center justify-center rounded-md p-2 text-white focus:outline-none"
              >
                <span className="absolute -inset-0.5" />
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </div>
            </div>
          </div>
          <div className="mt-3 space-y-1">
            {baseNavigation.map((item) => (
              <Link
                onClick={() => setMobileMenuOpen(false)}
                key={item.name}
                to={item.href}
                className="text-white block rounded-md px-3 py-2 text-[32px]"
                aria-current={item.current ? "page" : undefined}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
