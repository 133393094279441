import { toast } from "react-toastify";

export const ToastNotification = (type, message) => {
  toast[type](message, {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
  });
};
