// The `Filter` component is designed to work seamlessly with backend systems that use the Ransack gem for querying,
// translating user-friendly filter options into Ransack-compatible query parameters.
// It's composed of several key parts:
//
// 1. State management for filters, errors, and UI state.
// 2. Functions for handling user interactions (adding/removing filters, applying filters).
// 3. Utility functions for working with dates, operators, and filter values.
// 4. Render functions for different parts of the filter interface.
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import {
  XMarkIcon,
  PlusIcon,
  TrashIcon,
  FunnelIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import AutocompleteInput from "./AutocompleteInput";
import { format, parse } from "date-fns";

// This custom hook is used to extract filter values from the URL search parameters.
// It returns an object with the filter values, where the keys are the filter names and the values are the filter values.
export const useFilterValues = () => {
  const [searchParams] = useSearchParams();

  const filterValues = {};
  for (let [key, value] of searchParams.entries()) {
    if (key.startsWith("q[")) {
      filterValues[key.slice(2, -1)] = value;
    }
  }

  return filterValues;
};

// This object defines the default operators for different data types.
// It's used to provide a fallback when an attribute doesn't have its own operators defined.
const defaultOperators = {
  id: [
    { label: "equal", value: "eq" },
    { label: "not equal", value: "not_eq" },
    { label: "is null", value: "null" },
    { label: "is not null", value: "not_null" },
  ],
  string: [
    { label: "equal", value: "eq" },
    { label: "not equal", value: "not_eq" },
    { label: "contains", value: "cont" },
    { label: "not contain", value: "not_cont" },
    { label: "starts with", value: "start" },
    { label: "ends with", value: "end" },
    { label: "is null", value: "null" },
    { label: "is not null", value: "not_null" },
    { label: "is present", value: "present" },
    { label: "is blank", value: "blank" },
    { label: "case insensitive contains", value: "i_cont" },
  ],
  number: [
    { label: "equal", value: "eq" },
    { label: "not equal", value: "not_eq" },
    { label: "greater than", value: "gt" },
    { label: "greater than or equal to", value: "gteq" },
    { label: "less than", value: "lt" },
    { label: "less than or equal to", value: "lteq" },
    { label: "is null", value: "null" },
    { label: "is not null", value: "not_null" },
  ],
  date: [
    { label: "between", value: "between" },
    { label: "equal", value: "eq" },
    { label: "not equal", value: "not_eq" },
    { label: "after", value: "gt" },
    { label: "after or on", value: "gteq" },
    { label: "before", value: "lt" },
    { label: "before or on", value: "lteq" },
    { label: "is null", value: "null" },
    { label: "is not null", value: "not_null" },
  ],
  boolean: [
    { label: "is true", value: "true" },
    { label: "is false", value: "false" },
    { label: "is null", value: "null" },
    { label: "is not null", value: "not_null" },
  ],
};

const allOperators = Object.values(defaultOperators).flatMap((ops) =>
  ops.map((op) => op.value)
);

// This array contains operators that can be used with boolean values.
// It's used to check if an operator is valid for a boolean attribute.
const operatorsWithBooleanValue = [
  "null",
  "not_null",
  "present",
  "blank",
  "true",
  "false",
];

const generateRandomId = () => Math.random().toString(36).slice(2, 11);

const Filter = ({ attributes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dropdownRef = useRef(null);
  const [errors, setErrors] = useState({});

  // This state is used to store the loading state for each column.
  // It's an object where the keys are the attribute names and the values are boolean values indicating if the attribute is loading.
  const [loadingColumns, setLoadingColumns] = useState({});

  // This state is used to store the filters that have dependents.
  // It's an object where the keys are the attribute names and the values are boolean values indicating if the attribute has dependents.
  const [filtersWithDependents, setFiltersWithDependents] = useState({});

  // This state is used to store the loaded options for each attribute.
  // It's an object where the keys are the attribute names and the values are the loaded options.
  // This is only used for attributes that have options defined as an array or a function
  // that returns a promise resolving to an array of options.
  const [loadedOptions, setLoadedOptions] = useState({});

  // The `getCurrentFilters` function parses the current URL search parameters to extract and format the active filters.
  // It's crucial for maintaining filter state across page reloads.
  const getCurrentFilters = () => {
    const filters = [];
    const filterEntries = Array.from(searchParams.entries());
    const filterOrderMap = new Map();

    // Map filter orders
    filterEntries.forEach(([key, value]) => {
      if (key.startsWith("o[") && key.endsWith("]")) {
        const index = parseInt(key.slice(2, -1));
        filterOrderMap.set(parseInt(value), index);
      }
    });

    // This loop processes each filter entry to extract and format the active filters.
    // It identifies the operator for each filter and extracts the column name and value.
    // It then validates the filter and adds it to the filters array.
    filterEntries.forEach(([key, value]) => {
      if (key.startsWith("q[") && key.endsWith("]")) {
        const innerPart = key.slice(2, -1);
        const sortedOperators = allOperators.sort(
          (a, b) => b.length - a.length
        );
        const matchedOperator = sortedOperators.find((op) =>
          innerPart.endsWith(`_${op}`)
        );

        if (matchedOperator) {
          const column = innerPart.slice(0, -(matchedOperator.length + 1));
          const attributeIndex = attributes[column]
            ? Object.keys(attributes).indexOf(column)
            : -1;
          if (attributeIndex !== -1) {
            filters.push({
              id: generateRandomId(), // Generate a 9-character id
              column,
              operator: matchedOperator,
              value: operatorsWithBooleanValue.includes(matchedOperator)
                ? ""
                : value,
              order: filterOrderMap.get(attributeIndex) ?? attributeIndex,
            });
          }
        }
      }
    });

    // This sorts the filters based on the order of the attributes.
    return filters.sort((a, b) => a.order - b.order);
  };

  // This initializes the filters state with the current filters from the URL.
  // If no current filters are found, it defaults to an empty array.
  const [filters, setFilters] = useState(() => {
    const currentFilters = getCurrentFilters();
    return currentFilters.length > 0
      ? currentFilters
      : [
          {
            id: generateRandomId(),
            column: "",
            operator: "",
            value: "",
          },
        ];
  });

  // This function finds duplicate filters based on the column and operator.
  // It returns a set of indexes that have duplicate filters.
  const findDuplicateFilters = (filters) => {
    const combinations = new Map();
    const duplicates = new Set();

    filters.forEach((filter, index) => {
      if (filter.column && filter.operator) {
        const combination = `${filter.column}-${filter.operator}`;
        if (combinations.has(combination)) {
          duplicates.add(index);
          duplicates.add(combinations.get(combination));
        } else {
          combinations.set(combination, index);
        }
      }
    });

    return duplicates;
  };

  // This function validates a single filter and updates the errors state accordingly.
  // It checks for required fields and ensures the operator and value are valid.
  const validateFilter = (filter) => {
    const newErrors = { ...errors };

    if (!filter.column) {
      newErrors[`${filter.id}-column`] = "Required";
    } else {
      delete newErrors[`${filter.id}-column`];
    }

    if (!filter.operator) {
      newErrors[`${filter.id}-operator`] = "Required";
    } else {
      delete newErrors[`${filter.id}-operator`];
    }

    const attribute = attributes[filter.column];
    if (
      !attribute?.hiddenOption &&
      !operatorsWithBooleanValue.includes(filter.operator) &&
      !filter.value
    ) {
      newErrors[`${filter.id}-value`] = "Required";
    } else {
      delete newErrors[`${filter.id}-value`];
    }

    // This checks if the operator is "between" and validates the date range.
    // It ensures both start and end dates are provided and that the start date is before the end date.
    if (filter.operator === "between" && filter.value) {
      const [startDate, endDate] = filter.value.split("+");
      if (!startDate || !endDate) {
        newErrors[`${filter.id}-value`] =
          "Both start and end dates are required";
      } else if (startDate > endDate) {
        newErrors[`${filter.id}-value`] =
          "Start date must be before or equal to end date";
      } else {
        delete newErrors[`${filter.id}-value`];
      }
    }

    setErrors(newErrors);
  };

  // This function validates all filters and updates the errors state accordingly.
  // It ensures all required fields are filled and that the operators and values are valid.
  const validateAllFilters = () => {
    const newErrors = {};
    const duplicateIndexes = findDuplicateFilters(filters);

    filters.forEach((filter) => {
      if (!filter.column) {
        newErrors[`${filter.id}-column`] = "Required";
      }
      if (!filter.operator) {
        newErrors[`${filter.id}-operator`] = "Required";
      }
      const attribute = attributes[filter.column];
      if (
        !attribute?.hiddenOption &&
        !operatorsWithBooleanValue.includes(filter.operator) &&
        !filter.value
      ) {
        newErrors[`${filter.id}-value`] = "Required";
      }

      // This checks if the operator is "between" and validates the date range.
      // It ensures both start and end dates are provided and that the start date is before the end date.
      if (filter.operator === "between" && filter.value) {
        const [startDate, endDate] = filter.value.split("+");
        if (!startDate || !endDate) {
          newErrors[`${filter.id}-value`] =
            "Both start and end dates are required";
        } else if (startDate > endDate) {
          newErrors[`${filter.id}-value`] =
            "Start date must be before or equal to end date";
        }
      }

      // Add duplicate error for the attribute column
      if (duplicateIndexes.has(filters.indexOf(filter))) {
        newErrors[`${filter.id}-column`] = "Duplicate rules";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // This function adds a new empty filter to the filters state.
  // It ensures that there will always be at least one filter in the UI to be set.
  const addFilter = () => {
    setFilters([
      ...filters,
      {
        id: generateRandomId(),
        column: "",
        operator: "",
        value: "",
      },
    ]);
  };

  const removeFilter = (id) => {
    const newFilters = filters.filter((filter) => filter.id !== id);
    setFilters(
      newFilters.length > 0
        ? newFilters
        : [
            {
              id: generateRandomId(),
              column: "",
              operator: "",
              value: "",
            },
          ]
    );
    updateFiltersWithDependents(newFilters);
  };

  const removeAllFilters = () => {
    const newFilters = [
      {
        id: generateRandomId(),
        column: "",
        operator: "",
        value: "",
      },
    ];
    setFilters(newFilters);
    setErrors({});
    updateFiltersWithDependents(newFilters);

    // Clear all filter parameters and apply changes
    setSearchParams((prevParams) => {
      for (let key of prevParams.keys()) {
        if (key.startsWith("q[") || key.startsWith("o[")) {
          prevParams.delete(key);
        }
      }
      // Reset to first page when removing all filters
      prevParams.set("page", "1");
      return prevParams;
    });

    // Close the dropdown after clearing filters
    setIsOpen(false);
  };

  // This function adds a dependent attribute to the filters state.
  // It ensures that the dependent attribute is added to the filters state if it doesn't already exist.
  // It returns the dependent attribute if it exists, otherwise it returns null.
  const addDependentAttribute = (columnName) => {
    const attribute = attributes[columnName];
    if (attribute && attribute.dependsOn) {
      const dependentAttribute = attributes[attribute.dependsOn];
      if (dependentAttribute) {
        const dependentFilter = filters.find(
          (f) =>
            f.column === attribute.dependsOn && f.operator === "eq" && f.value
        );
        if (!dependentFilter) {
          const newFilter = {
            id: generateRandomId(),
            column: attribute.dependsOn,
            operator: "eq",
            value: "",
          };
          return newFilter;
        }
      }
    }
    return null;
  };

  // This function checks if a column has dependents.
  const hasDependents = (columnName) => {
    return filtersWithDependents[columnName] || false;
  };

  // This function updates the filtersWithDependents state.
  // It checks for each filter if it has dependents and updates the state accordingly.
  const updateFiltersWithDependents = (filters) => {
    const newFiltersWithDependents = {};
    filters.forEach((filter) => {
      if (filter.column) {
        const dependents = filters.filter(
          (f) => attributes[f.column]?.dependsOn === filter.column
        );
        newFiltersWithDependents[filter.column] = dependents.length > 0;
      }
    });
    setFiltersWithDependents(newFiltersWithDependents);
  };

  // This function updates the dependent attributes.
  // It resets the value of the dependent attribute if the parent attribute has changed.
  // It also triggers option loading for the dependent attribute.
  const updateDependentAttributes = (changedFilter, allFilters) => {
    Object.entries(attributes).forEach(([key, attr]) => {
      if (attr.dependsOn === changedFilter.column) {
        const dependentFilter = allFilters.find((f) => f.column === key);
        if (dependentFilter) {
          // Reset the dependent filter's value
          dependentFilter.value = "";
          // Trigger option loading for the dependent attribute
          setLoadingColumns((prev) => ({ ...prev, [key]: true }));
          // Clear existing options for the dependent attribute
          setLoadedOptions((prev) => ({ ...prev, [key]: [] }));
          // Load options for the dependent attribute
          getOptions(key, changedFilter.value).then((options) => {
            setLoadedOptions((prev) => ({ ...prev, [key]: options }));
            setLoadingColumns((prev) => ({ ...prev, [key]: false }));
          });
        }
      }
    });
  };

  // This function updates a filter's value and triggers option loading if necessary.
  // It also validates the filter and updates the state accordingly.
  const handleFilterChange = (id, field, value) => {
    let newFilters = [...filters];
    const filterIndex = newFilters.findIndex((filter) => filter.id === id);

    if (filterIndex === -1) return;

    newFilters[filterIndex] = { ...newFilters[filterIndex], [field]: value };

    if (field === "column") {
      const attribute = attributes[value];
      newFilters[filterIndex].operator = attribute?.defaultOperator || "";
      newFilters[filterIndex].value = "";

      if (attribute?.hiddenOption) {
        newFilters[filterIndex].value = attribute.hiddenOption;
      }

      // Set loading state for this column
      setLoadingColumns((prev) => ({ ...prev, [value]: true }));

      // Add dependent attribute if necessary
      const dependentFilter = addDependentAttribute(value);
      if (dependentFilter) {
        // Check if the dependent attribute already exists
        const existingDependentFilter = newFilters.find(
          (f) => f.column === dependentFilter.column
        );
        if (!existingDependentFilter) {
          newFilters = [
            ...newFilters.slice(0, filterIndex),
            dependentFilter,
            ...newFilters.slice(filterIndex),
          ];
          // Set loading state for the dependent attribute
          setLoadingColumns((prev) => ({
            ...prev,
            [dependentFilter.column]: true,
          }));
        }
      }
    }

    setFilters(newFilters);
    validateFilter(newFilters[filterIndex]);
    updateFiltersWithDependents(newFilters);

    // Call the onChange callback if it exists for this attribute
    if (
      field === "value" &&
      attributes[newFilters[filterIndex].column]?.onChange
    ) {
      attributes[newFilters[filterIndex].column].onChange(value);
    }

    // Check if this attribute has dependents and update them
    updateDependentAttributes(newFilters[filterIndex], newFilters);
  };

  // This function applies the filters to the URL search parameters.
  // It ensures that all filters are valid and then updates the search parameters accordingly.
  // It also resets the page number to 1 after applying filters.
  const handleApplyFilters = () => {
    if (validateAllFilters()) {
      setSearchParams((prevParams) => {
        // Create a new URLSearchParams object
        const newParams = new URLSearchParams();

        // Copy non-filter related parameters (except 'page')
        for (let [key, value] of prevParams.entries()) {
          if (
            !key.startsWith("q[") &&
            !key.startsWith("o[") &&
            key !== "page"
          ) {
            newParams.append(key, value);
          }
        }

        // Reset to first page
        newParams.set("page", "1");

        // Add new filter parameters.
        // This loop iterates through each filter and appends the corresponding parameters to the newParams object.
        filters.forEach((filter) => {
          if (filter.column && filter.operator) {
            const attribute = attributes[filter.column];
            if (operatorsWithBooleanValue.includes(filter.operator)) {
              newParams.set(`q[${filter.column}_${filter.operator}]`, "true");
            } else if (
              attribute?.hiddenOption !== undefined ||
              filter.value !== ""
            ) {
              newParams.set(
                `q[${filter.column}_${filter.operator}]`,
                attribute?.hiddenOption || filter.value
              );
            }
            const attributeIndex = attributes[filter.column]
              ? Object.keys(attributes).indexOf(filter.column)
              : -1;
            if (attributeIndex !== -1) {
              newParams.set(
                `o[${filters.indexOf(filter)}]`,
                attributeIndex.toString()
              );
            }
          }
        });

        return newParams;
      });
      setIsOpen(false);
    }
  };

  // This function handles the Enter key press to apply filters.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleApplyFilters();
    }
  };

  // This function retrieves the type of the attribute based on the column name.
  // It returns the type of the attribute if defined, otherwise it defaults to "string".
  const getAttributeType = (columnName) => {
    const attribute = attributes[columnName];
    return attribute ? attribute.type : "string";
  };

  // This function retrieves the operators for a given attribute.
  // It first checks if the attribute has a custom operators array.
  // If not, it uses the default operators for the attribute type.
  // It also filters out excluded operators if the attribute has an excludeOperators array.
  const getOperators = (columnName) => {
    const attribute = attributes[columnName];
    if (attribute) {
      let operators = attribute.operators
        ? attribute.operators.map((op) => {
            if (typeof op === "object" && op.label && op.value) {
              return op;
            }
            const defaultOps = defaultOperators[attribute.type || "string"];
            return (
              defaultOps.find((defaultOp) => defaultOp.value === op) || {
                label: op,
                value: op,
              }
            );
          })
        : defaultOperators[getAttributeType(columnName)] ||
          defaultOperators.string;

      // Filter out excluded operators if excludeOperators is defined
      if (attribute.excludeOperators) {
        operators = operators.filter(
          (op) => !attribute.excludeOperators.includes(op.value)
        );
      }

      return operators;
    }
    return (
      defaultOperators[getAttributeType(columnName)] || defaultOperators.string
    );
  };

  // This function calculates the number of active filters.
  // It counts filters that have a column, operator, and a value that is not empty.
  const getActiveFilterCount = () => {
    return filters.filter(
      (filter) =>
        filter.column &&
        filter.operator &&
        (operatorsWithBooleanValue.includes(filter.operator) || filter.value)
    ).length;
  };

  // This function retrieves the options for a given attribute.
  // It first checks if the attribute has a custom options array.
  // If not, it uses the options from the loadedOptions state.
  // If the options are not yet loaded, it sets the loading state to true and attempts to load the options.
  // If the options are loaded successfully, it updates the loadedOptions state and returns the options.
  // If there's an error loading the options, it returns an empty array.
  const getOptions = useCallback(
    async (columnName, dependencyValue) => {
      const attribute = attributes[columnName];
      if (!attribute) return [];

      if (Array.isArray(attribute.options)) {
        return attribute.options;
      }

      if (typeof attribute.options === "function") {
        try {
          let options;
          if (attribute.dependsOn) {
            if (dependencyValue) {
              options = await attribute.options(dependencyValue);
            } else {
              return [];
            }
          } else {
            options = await attribute.options();
          }
          return options;
        } catch (error) {
          // console.error(`Error loading options for ${columnName}:`, error);
          return [];
        }
      }

      return [];
    },
    [attributes]
  );

  // This function checks if a filter is dependent and its parent is not set.
  const isDependentAndParentNotSet = (filter) => {
    const attribute = attributes[filter.column];
    if (attribute && attribute.dependsOn) {
      const parentFilter = filters.find(
        (f) => f.column === attribute.dependsOn
      );
      return !parentFilter || !parentFilter.value;
    }
    return false;
  };

  // This function renders the value input for a filter.
  // It first checks if the operator is valid and not a boolean operator.
  // If so, it returns null.
  // Otherwise, it retrieves the attribute and its type.
  // It also checks if the filter is dependent and its parent is not set.
  // If so, it sets the parent label.
  // It then checks if the attribute has options and renders the appropriate input based on the attribute type.
  // If the attribute type is "date", it renders a date range input.
  // If the attribute type is "number", it renders a number input.
  const renderValueInput = (filter) => {
    if (
      !filter.operator ||
      operatorsWithBooleanValue.includes(filter.operator)
    ) {
      return null;
    }

    const attribute = attributes[filter.column];

    if (attribute?.hiddenOption !== undefined) {
      return null;
    }

    const attributeType = attribute ? attribute.type : "string";
    const isDependent = isDependentAndParentNotSet(filter);
    const parentLabel = attribute?.dependsOn
      ? attributes[attribute.dependsOn]?.label
      : "";

    if (attribute && attribute.options) {
      const isLoading = loadingColumns[filter.column];
      const options = Array.isArray(attribute.options)
        ? attribute.options
        : loadedOptions[filter.column] || [];

      if (attribute.autocomplete) {
        return (
          <AutocompleteInput
            options={options}
            value={filter.value}
            onChange={(value) => handleFilterChange(filter.id, "value", value)}
            onClear={() => handleFilterChange(filter.id, "value", "")}
            placeholder={
              isDependent
                ? `Set ${parentLabel} first`
                : isLoading
                  ? "Loading options..."
                  : "Select or type..."
            }
            disabled={isLoading || isDependent}
            isLoading={isLoading}
          />
        );
      } else {
        return (
          <div className="relative">
            <div className={`${!isDependent ? "select-wrapper" : ""}`}>
              <select
                className="p-2 w-full border-none"
                value={filter.value}
                onChange={(e) =>
                  handleFilterChange(filter.id, "value", e.target.value)
                }
                disabled={isLoading || isDependent}
              >
                <option value="">
                  {isDependent
                    ? `Set ${parentLabel} first`
                    : isLoading
                      ? "Loading options..."
                      : "Select..."}
                </option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {isLoading && (
              <div className="absolute right-8 top-1/2 transform -translate-y-1/2">
                <span className="animate-spin">⌛</span>
              </div>
            )}
          </div>
        );
      }
    }

    switch (attributeType) {
      case "date":
        if (filter.operator === "between") {
          const [startDate, endDate] = filter.value.split("+");
          return (
            <div className="relative flex space-x-2">
              <div className="w-5/12">
                <input
                  type="date"
                  className="p-2 w-full"
                  value={startDate || ""}
                  onChange={(e) =>
                    handleDateBetweenChange(filter.id, "start", e.target.value)
                  }
                  disabled={isDependent}
                />
              </div>
              <div className="w-5/12">
                <input
                  type="date"
                  className="p-2 w-full"
                  value={endDate || ""}
                  onChange={(e) =>
                    handleDateBetweenChange(filter.id, "end", e.target.value)
                  }
                  disabled={isDependent}
                />
              </div>
              <div className="w-2/12">
                {filter.value && !isDependent && (
                  <XCircleIcon
                    className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer hover:text-gray-600"
                    onClick={() => handleFilterChange(filter.id, "value", "")}
                  />
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div className="relative">
              <input
                type="date"
                className="p-2 w-full pr-8"
                value={filter.value}
                onChange={(e) =>
                  handleFilterChange(filter.id, "value", e.target.value)
                }
                disabled={isDependent}
              />
              {filter.value && !isDependent && (
                <XCircleIcon
                  className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer hover:text-gray-600"
                  onClick={() => handleFilterChange(filter.id, "value", "")}
                />
              )}
            </div>
          );
        }
      case "number":
        return (
          <div className="relative">
            <input
              type="number"
              className="p-2 w-full pr-8"
              value={filter.value}
              onChange={(e) =>
                handleFilterChange(filter.id, "value", e.target.value)
              }
              disabled={isDependent}
              placeholder={isDependent ? `Set ${parentLabel} first` : ""}
            />
            {filter.value && !isDependent && (
              <XCircleIcon
                className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer hover:text-gray-600"
                onClick={() => handleFilterChange(filter.id, "value", "")}
              />
            )}
          </div>
        );
      default:
        return (
          <div className="relative">
            <input
              type="text"
              className="p-2 w-full pr-8"
              value={filter.value}
              onChange={(e) =>
                handleFilterChange(filter.id, "value", e.target.value)
              }
              placeholder={isDependent ? `Set ${parentLabel} first` : "Value"}
              disabled={isDependent}
            />
            {filter.value && !isDependent && (
              <XCircleIcon
                className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer hover:text-gray-600"
                onClick={() => handleFilterChange(filter.id, "value", "")}
              />
            )}
          </div>
        );
    }
  };

  // This function handles the change for the start or end date in a date range filter.
  // It updates the filter value with the new date and validates the filter.
  const handleDateBetweenChange = (id, dateType, value) => {
    const currentFilter = filters.find((filter) => filter.id === id);
    const [startDate, endDate] = currentFilter.value.split("+");
    let newValue;

    if (dateType === "start") {
      newValue = `${value}+${endDate || ""}`;
    } else {
      newValue = `${startDate || ""}+${value}`;
    }

    handleFilterChange(id, "value", newValue);
    validateFilter({ ...currentFilter, value: newValue });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // This useEffect hook is used to load initial options for each filter.
  // It iterates through the filters and checks if the attribute has options defined.
  // If so, it loads the options and updates the filter value with the corresponding label.
  // It also handles the "between" operator for date attributes.
  useEffect(() => {
    const loadInitialOptions = async () => {
      for (const filter of filters) {
        if (filter.column && attributes[filter.column]?.options) {
          setLoadingColumns((prev) => ({ ...prev, [filter.column]: true }));
        }

        // Parse 'between' date values
        if (
          attributes[filter.column]?.type === "date" &&
          filter.operator === "between"
        ) {
          const [startDate, endDate] = filter.value.split("+");
          if (startDate && endDate) {
            const formattedStartDate = format(
              parse(startDate, "yyyy-MM-dd", new Date()),
              "yyyy-MM-dd"
            );
            const formattedEndDate = format(
              parse(endDate, "yyyy-MM-dd", new Date()),
              "yyyy-MM-dd"
            );
            handleFilterChange(
              filter.id,
              "value",
              `${formattedStartDate}+${formattedEndDate}`
            );
          }
        }
      }
    };
    loadInitialOptions();
  }, []);

  // This useEffect is used to load options for each filter.
  // It checks if the column is loading and if so, it loads the options.
  // It also sets the default value if it exists.
  useEffect(() => {
    const loadOptions = async () => {
      for (const filter of filters) {
        if (filter.column && loadingColumns[filter.column]) {
          try {
            const attribute = attributes[filter.column];

            let options = [];
            if (attribute.dependsOn) {
              const dependencyFilter = filters.find(
                (f) => f.column === attribute.dependsOn
              );
              if (dependencyFilter && dependencyFilter.value) {
                options = await getOptions(
                  filter.column,
                  dependencyFilter.value
                );
              }
            } else {
              options = await getOptions(filter.column);
            }

            setLoadedOptions((prev) => ({ ...prev, [filter.column]: options }));

            // Set default value if it exists and no value is set
            if (!filter.value) {
              const defaultOption = options.find((option) => option.default);
              if (defaultOption) {
                handleFilterChange(filter.id, "value", defaultOption.value);
              }
            }
          } catch (error) {
            // console.error(`Error loading options for ${filter.column}:`, error);
          } finally {
            setLoadingColumns((prev) => ({ ...prev, [filter.column]: false }));
          }
        }
      }
    };

    loadOptions();
  }, [filters, loadingColumns]);

  // This useEffect is used to update the filters with dependents.
  // It updates the filters with dependents and sets the filters with dependents state.
  useEffect(() => {
    updateFiltersWithDependents(filters);
  }, []);

  // This useEffect hook is used to handle clicks outside the dropdown and the Escape key.
  // It sets the isOpen state to false when clicking outside the dropdown or pressing the Escape key.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef} onKeyDown={handleKeyDown}>
      <button
        className="flex items-center text-[#0694C0] text-sm bg-blue-100 hover:bg-[#0694C0] hover:text-white rounded-md p-2 transition-all duration-300"
        onClick={toggleDropdown}
      >
        <FunnelIcon className="h-5 w-5 mr-2" />
        Filters
        {getActiveFilterCount() > 0 && (
          <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-white bg-[#0694C0] rounded-full">
            {getActiveFilterCount()}
          </span>
        )}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 bg-white rounded-md shadow-lg z-10 p-4 w-[700px]">
          {filters.map((filter) => (
            <div key={filter.id} className="mb-6 border-b border-gray-500 pb-1">
              <div className="flex space-x-2 text-sm">
                <button
                  onClick={() => removeFilter(filter.id)}
                  className="flex-shrink-0"
                  disabled={hasDependents(filter.column)}
                  title={
                    hasDependents(filter.column)
                      ? "Cannot remove filter as it has dependents"
                      : ""
                  }
                >
                  <XMarkIcon
                    className={`h-5 w-5 ${hasDependents(filter.column) ? "text-gray-300 cursor-not-allowed" : "text-gray-500 hover:text-gray-700"}`}
                  />
                </button>
                <div className="w-1/4">
                  <div className="select-wrapper">
                    <select
                      className="w-full border-none"
                      value={filter.column}
                      onChange={(e) =>
                        handleFilterChange(filter.id, "column", e.target.value)
                      }
                    >
                      <option value="">- Attribute -</option>
                      {Object.entries(attributes).map(([key, attr]) => (
                        <option key={key} value={key}>
                          {attr.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors[`${filter.id}-column`] && (
                    <p className="text-red-500 text-xs mt-1 ml-3">
                      {errors[`${filter.id}-column`]}
                    </p>
                  )}
                </div>
                <div className="w-1/4">
                  <div className="select-wrapper">
                    <select
                      className="w-full border-none"
                      value={filter.operator}
                      onChange={(e) =>
                        handleFilterChange(
                          filter.id,
                          "operator",
                          e.target.value
                        )
                      }
                    >
                      <option value="">- Operator -</option>
                      {filter.column &&
                        getOperators(filter.column).map((op) => (
                          <option key={op.value} value={op.value}>
                            {op.label}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors[`${filter.id}-operator`] && (
                    <p className="text-red-500 text-xs mt-1 ml-3">
                      {errors[`${filter.id}-operator`]}
                    </p>
                  )}
                </div>
                <div className="w-2/4">
                  {filter.operator &&
                    !operatorsWithBooleanValue.includes(filter.operator) && (
                      <>
                        {renderValueInput(filter, filter.id)}
                        {errors[`${filter.id}-value`] && (
                          <p className="text-red-500 text-xs mt-1 ml-2">
                            {errors[`${filter.id}-value`]}
                          </p>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          ))}
          <div className="flex justify-between mt-4">
            <button
              onClick={addFilter}
              className="flex items-center text-[#0694C0] uppercase text-sm"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add Filter
            </button>
            <button
              onClick={removeAllFilters}
              className="flex items-center text-[#0694C0] uppercase text-sm"
            >
              <TrashIcon className="h-5 w-5 mr-2" />
              Remove All
            </button>
          </div>
          <button
            onClick={handleApplyFilters}
            className="w-full p-2 mt-4 cursor-pointer rounded border border-[#0694C0] text-[#0694C0] hover:text-white hover:border-[#0694C0] hover:bg-[#0694C0] uppercase text-sm"
          >
            Apply Filters
          </button>
        </div>
      )}
    </div>
  );
};

export default Filter;
