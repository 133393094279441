import React from "react";
import { ArrowDownOnSquareIcon, PrinterIcon } from "@heroicons/react/20/solid";

export default function PageActions() {
  return (
    <div className="mb-4 flex justify-center">
      <div className="mr-5 flex items-center cursor-pointer">
        <ArrowDownOnSquareIcon className="mr-2 w-5" />
        <p>Download as CSV</p>
      </div>
      <div className="flex items-center cursor-pointer">
        <PrinterIcon className="mr-2 w-5" />
        <p>Print</p>
      </div>
    </div>
  );
}
