import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/students`;

const getAllStudents = async (params) => {
  const data = await axiosClient.get(endpoint, params);

  return data;
};

const getStudentRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.studentId}`);

  return data;
};

const createStudentRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const createCsvStudentsRequest = async (params) => {
  const data = await axiosClient.post(`${endpoint}/csv_students`, params);

  return data;
};

const downloadCsvStudentsRequest = async (params) => {
  try {
    const response = await axiosClient.get(`${endpoint}.csv`, {
      ...params,
      responseType: "blob",
    });

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a link element, set the download attribute with a filename
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `Students-${new Date().toISOString().split("T")[0]}.csv`;

    // Append to the document body and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

const updateStudentRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/${params.studentId}`, params);

  return data;
};

const updateStudentAveryLabels = async (params) => {
  const data = await axiosClient.put(
    `${endpoint}/${params.studentId}/update_avery_labels`,
    params
  );

  return data;
};

const deleteStudentRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.studentId}`);

  return data;
};

const suggestedSchoolsRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/suggested_schools`, {
    params: params,
  });

  return data;
};

const averyLabelsRequest = async () => {
  return await axiosClient.get(`${endpoint}/avery_labels`);
};

const getAllStudentDecisionsRequest = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.studentId}/decisions`
  );

  return data;
};

const getAllStudentNotesRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.studentId}/notes`);

  return data;
};

const getAllStudentProductsRequest = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/${params.studentId}/products`
  );

  return data;
};

const bulkReassignStudentsRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/bulk_reassign`, params);

  return data;
};

const bulkShareStudentsRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/bulk_share`, params);

  return data;
};

const bulkUnshareStudentsRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/bulk_unshare`, params);

  return data;
};

export {
  getAllStudents,
  getStudentRequest,
  createStudentRequest,
  createCsvStudentsRequest,
  downloadCsvStudentsRequest,
  updateStudentRequest,
  deleteStudentRequest,
  suggestedSchoolsRequest,
  averyLabelsRequest,
  getAllStudentDecisionsRequest,
  getAllStudentNotesRequest,
  getAllStudentProductsRequest,
  bulkReassignStudentsRequest,
  bulkShareStudentsRequest,
  bulkUnshareStudentsRequest,
  updateStudentAveryLabels,
};
