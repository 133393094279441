import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/users`;

const getAllUsers = async (params) => {
  const data = await axiosClient.get(endpoint, params);

  return data;
};

const getAllUsersBySchoolRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/by_schools`, {
    params: params,
  });

  return data;
};

const getAllUsersForShareRequest = async () => {
  const data = await axiosClient.get(`${endpoint}/share`);

  return data;
};

const getUserRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.userId}`);

  return data;
};

const updateUserRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/${params.userId}`, params);

  return data;
};

const updateCurrentUserRequest = async (params) => {
  const data = await axiosClient.patch(`${endpoint}/update_current`, params);

  return data;
};

const updateCurrentUserImagesRequest = async (formData) => {
  const data = await axiosClient.patch(`${endpoint}/update_current`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

const deleteUserRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.userId}`);

  return data;
};

const getAllUserStudentsRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.userId}/students`);

  return data;
};

const getAllUserCoursesRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.userId}/courses`);

  return data;
};

const downloadCsvUsersRequest = async (params) => {
  try {
    const response = await axiosClient.get(`${endpoint}.csv`, {
      ...params,
      responseType: "blob",
    });

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a link element, set the download attribute with a filename
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${params.params?.type || "User"}s-${new Date().toISOString().split("T")[0]}.csv`;

    // Append to the document body and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export {
  getAllUsers,
  updateUserRequest,
  updateCurrentUserRequest,
  updateCurrentUserImagesRequest,
  getUserRequest,
  deleteUserRequest,
  getAllUserStudentsRequest,
  getAllUserCoursesRequest,
  getAllUsersBySchoolRequest,
  getAllUsersForShareRequest,
  downloadCsvUsersRequest,
};
