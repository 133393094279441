import React from "react";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import { useNavigate } from "react-router-dom";
import DecisionsForm from "./DecisionsForm";
import { twMerge } from "tailwind-merge";
import { displayError } from "../../../../../../../utils/displayError";

export default function Decisions({
  addingDecision,
  decisionOptions,
  setAddingDecision,
  visit,
  enrollment,
  addingNotes,
}) {
  const navigate = useNavigate();

  const selectedDecisionOptions = decisionOptions.filter((option) =>
    visit?.decisions.some(
      (decision) => decision.decision_option_id === option.id
    )
  );

  const onSubmit = async (data) => {
    try {
      const decisions = decisionOptions.map((decision_option) => ({
        id: visit?.decisions.find(
          (decision) => decision.decision_option_id === decision_option.id
        )?.id,
        decision_option_id: decision_option.id,
        cached_enrollment_id: enrollment.id,
        _destroy: data.decisions.find(
          (decision) => decision.id === decision_option.id
        )
          ? false
          : true,
      }));

      const res = await updateEnrollmentRequest({
        visits_attributes: [
          {
            id: visit?.id,
            decisions_attributes: decisions,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Decisions successfully.");
        setAddingDecision(false);
        navigate(".", { replace: true });
      }
    } catch (e) {
      displayError(e);
    }
  };

  return addingDecision ? (
    <DecisionsForm
      onSubmit={onSubmit}
      decisionOptions={decisionOptions}
      setAddingDecision={setAddingDecision}
      selectedDecisionOptions={selectedDecisionOptions}
    />
  ) : (
    <div className={twMerge(addingNotes ? "mt-4" : "mb-4")}>
      {selectedDecisionOptions.length > 0 &&
        selectedDecisionOptions.map((item) => (
          <div
            key={item.id}
            onClick={() => setAddingDecision(true)}
            className="cursor-pointer bg-[#0694C0] inline-flex items-center border rounded-full pl-2 pr-1 py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white"
          >
            {item.name}
          </div>
        ))}
    </div>
  );
}
