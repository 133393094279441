import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

export default function SearchInput({
  className,
  searchInputVisible,
  setSearchInputVisible,
}) {
  const { handleSubmit } = useForm();

  const onSubmit = async (data) => {
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <div className={twMerge("mx-auto w-full flex", className)}>
      <form
        className="w-full flex justify-end"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          name="searchTerm"
          className={`w-full border-b border-b-black bg-transparent placeholder-black ${
            !searchInputVisible ? "hidden" : "inherit"
          }`}
          placeholder="Search"
        />
        <MagnifyingGlassIcon
          className={twMerge("inline h-9  w-5 cursor-pointer text-black")}
          aria-hidden="true"
          onClick={() => setSearchInputVisible(!searchInputVisible)}
        />
      </form>
    </div>
  );
}
