import React from "react";
import CourseForm from "./CourseOverview/CourseForm";
import Card from "../../UI/Card";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../UI/Button";
import { createCourseRequest } from "../../../requests/courses";
import { ToastNotification } from "../../UI/ToastNotification";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function CourseNew() {
  const [searchParams] = useSearchParams();
  const methods = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await createCourseRequest({
        ...data,
        type: "Course",
        school_id: searchParams.get("school_id"),
      });

      if (res.data) {
        ToastNotification("success", "Course created successfully.");
        navigate(`/courses/${res.data.id}/overview`);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center mb-7">
        <h1 className="text-2xl">New Course</h1>
      </div>
      <Card classNames="mb-4">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CourseForm />
            <Button variant="primary" type="submit">
              Create Course
            </Button>
          </form>
        </FormProvider>
      </Card>
    </div>
  );
}
