import React, { useEffect, useState } from "react";
import DesktopDashboard from "./DesktopDashboard";
import MobileDashboard from "./MobileDashboard";
import { getAllCourses } from "../../requests/courses";
import { getAllUsers } from "../../requests/users";
import { getAllSeminars } from "../../requests/seminars";
import { getNeedingFollowupRequest } from "../../requests/enrollments";
import { checkPermission } from "../../utils/checkPermission";

export default function Dashboard() {
  const [courses, setCourses] = useState([]);
  const [seminars, setSeminars] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [needingFollowup, setNeedingFollowup] = useState([]);

  const isAdmin = checkPermission(["school_admin", "conference_admin"]);

  const getCourses = async () => {
    const res = await getAllCourses({ pagination: false });

    if (res.data) {
      setCourses(res.data);
    }
  };

  const getSeminars = async () => {
    const res = await getAllSeminars({ pagination: false });

    if (res.data) {
      setSeminars(res.data);
    }
  };

  const getInstructors = async () => {
    const res = await getAllUsers({ params: { pagination: false } });

    if (res.data) {
      setInstructors(res.data);
    }
  };

  const getNeedingFollowup = async () => {
    const res = await getNeedingFollowupRequest();

    if (res.data) {
      setNeedingFollowup(res.data);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      getInstructors();
    }

    getCourses();
    getSeminars();
    getNeedingFollowup();
  }, []);

  return (
    <>
      <div className="hidden md:block">
        <DesktopDashboard
          needingFollowup={needingFollowup}
          courses={courses}
          seminars={seminars}
          instructors={instructors}
          isAdmin={isAdmin}
        />
      </div>

      <div className="md:hidden">
        <MobileDashboard
          students={[]}
          courses={courses}
          seminars={seminars}
          instructors={instructors}
          isAdmin={isAdmin}
        />
      </div>
    </>
  );
}
