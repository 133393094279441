import React, { useState } from "react";
import ActionMenuContainer from "../../ActionMenu/ActionMenuContainer";
import { useForm, FormProvider } from "react-hook-form";
import { bulkUnshareStudentsRequest } from "../../../requests/students";
import Button from "../../UI/Button";
import Modal from "../../UI/Modal";
import { ToastNotification } from "../../UI/ToastNotification";
import { displayError } from "../../../utils/displayError";

export default function UnshareStudents({
  open,
  setOpen,
  students,
  onSuccess,
}) {
  const methods = useForm();
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    try {
      await bulkUnshareStudentsRequest({
        student_ids: students,
      });

      onSuccess();
      ToastNotification("success", "Students unshared successfully.");
    } catch (e) {
      displayError(e);
      setLoading(false);
    } finally {
      handleClose();
      setTimeout(() => setLoading(false), 1);
    }
  };

  return (
    <Modal open={open} setOpen={handleClose}>
      <div className="bg-neutral-600 relative">
        <ActionMenuContainer
          label="Unshare Students"
          clickToClose={handleClose}
        >
          {!loading ? (
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="max-w-[600px] m-auto my-10 text-center"
              >
                <p>Are you sure you want to unshare these students?</p>
                <div className="flex flex-row justify-center gap-4 mt-10">
                  <Button
                    type="button"
                    onClick={handleClose}
                    classNames="h-[38px]"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" classNames="h-[38px]">
                    Unshare
                  </Button>
                </div>
              </form>
            </FormProvider>
          ) : (
            <div className="flex justify-center items-center h-[300px]">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-white"></div>
            </div>
          )}
        </ActionMenuContainer>
      </div>
    </Modal>
  );
}
