import React from "react";
import Card from "../../../UI/Card";
import OnlineCourses from "./OnlineCourses";
import PrintedCourses from "./PrintedCourses";
import Seminars from "./Seminars";
import StudentMobileOverview from "./Mobile/StudentMobileOverview";
import { useRouteLoaderData } from "react-router-dom";

export default function StudentOverview() {
  const student = useRouteLoaderData("student").data;

  return (
    <div>
      <div className="md:hidden">
        <StudentMobileOverview />
      </div>
      <div className="md:block hidden">
        <Card>
          <Seminars student={student} />
        </Card>

        <div className="mt-4 flex">
          <Card classNames="mr-4 w-3/6">
            <PrintedCourses student={student} />
          </Card>
          <Card classNames="w-3/6">
            <OnlineCourses student={student} />
          </Card>
        </div>
      </div>
    </div>
  );
}
