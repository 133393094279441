import React from "react";
import { twMerge } from "tailwind-merge";

export default function InputGroup({ children, col, classNames }) {
  return (
    <div
      className={twMerge(
        "grid mb-6 items-end",
        col
          ? "grid-flow-row gap-6"
          : "lg:grid-flow-col lg:gap-4 grid-flow-row gap-6",
        classNames
      )}
    >
      {children}
    </div>
  );
}
