import { PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import Button from "./Button";

export default function AddButton({
  onClick,
  label,
  variant,
  classNames,
  type,
}) {
  return (
    <Button
      type={type}
      variant={variant}
      classNames={classNames}
      onClick={onClick}
      icon
    >
      <PlusIcon className="w-5" />
      {label}
    </Button>
  );
}
