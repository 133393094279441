import React, { useState } from "react";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import { useOutletContext, useRouteLoaderData } from "react-router-dom";
import ReminderRow from "./ReminderRow";
import AddButton from "../../../../../UI/AddButton";
import ReminderNew from "./ReminderNew";
import EmptyTable from "../../../../../UI/EmptyTable";

export default function Reminders() {
  const { seriesEnrollment } = useOutletContext();
  const enrollment = useRouteLoaderData(seriesEnrollment).data;
  const [addingReminder, setAddingReminder] = useState(false);

  const reminders = enrollment?.reminders;

  return (
    <div>
      {reminders?.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>REMINDER DATE</TableHeader>
              <TableHeader>NOTES</TableHeader>
            </tr>
          </thead>
          <tbody>
            {reminders.map((reminder) => (
              <ReminderRow
                key={reminder.id}
                enrollment={enrollment}
                reminder={reminder}
                handleCancelAdd={() => setAddingReminder(false)}
                addingReminder={addingReminder}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No reminders recorded." />
      )}

      {addingReminder ? (
        <div className="mt-4">
          <ReminderNew
            enrollment={enrollment}
            handleCancelAdd={() => setAddingReminder(false)}
          />
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <AddButton
            onClick={() => setAddingReminder(true)}
            label="Add Reminder"
            variant="primary-outline"
          />
        </div>
      )}
    </div>
  );
}
