import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import SeminarButton from "../SeminarButton";
import { hoverLink } from "../../../../utils/hoverLink";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../../UI/Modal";
import NewSchool from "./NewSchool";
import {
  getAllSeminarAssignStudentsRequest,
  getRecentlyAssignedStudentsRequest,
} from "../../../../requests/seminars";
import { displayError } from "../../../../utils/displayError";
import AssignStudents from "./AssignStudents";
import Button from "../../../UI/Button";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import { deleteSchoolRequest } from "../../../../requests/seminars";
import { ToastNotification } from "../../../UI/ToastNotification";

export default function ParticipatingSchools({ seminar }) {
  const [newSchoolModal, setNewSchoolModal] = useState({ open: false });
  const [assignStudentsModal, setAssignStudentsModal] = useState({
    open: false,
  });
  const [assignStudents, setAssignStudents] = useState([]);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSeriesOffered, setSelectedSeriesOffered] = useState(null);
  const [recentlyAssigned, setRecentlyAssigned] = useState([]);

  const getRecentlyAssigned = async () => {
    try {
      const res = await getRecentlyAssignedStudentsRequest({
        seminarId: seminar.id,
      });

      if (res.data) {
        setRecentlyAssigned(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getAllSeminarAssignStudents = async () => {
    try {
      const res = await getAllSeminarAssignStudentsRequest({
        seminarId: seminar.id,
      });

      if (res.data) {
        setAssignStudents(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleDelete = (series_offered) => {
    setSelectedSeriesOffered(series_offered);
    setDeleteModal(true);
  };

  const deleteSchool = async () => {
    try {
      const res = await deleteSchoolRequest({
        series_offered_id: selectedSeriesOffered.id,
        series_id: selectedSeriesOffered.series_id,
        school_id: selectedSeriesOffered.school_id,
        host_school_id: seminar.host_school_id,
      });

      if (res) {
        ToastNotification("success", "School deleted successfully.");
        setDeleteModal(false);
        navigate(`/seminars/${seminar.id}/details`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    getAllSeminarAssignStudents();
    getRecentlyAssigned();
  }, [assignStudentsModal, deleteModal]);

  return (
    <div>
      <Card classNames="overflow-y-scroll no-scrollbar">
        <div className="flex items-center justify-between mb-6">
          <h2 className="font-bold text-[#0694C0]">PARTICIPATING SCHOOLS</h2>
          <div className="flex">
            <SeminarButton
              classNames="mr-4"
              onClick={() => {
                if (assignStudents.length > 0) {
                  setAssignStudentsModal({ open: true });
                }
              }}
            >
              Assign Students ({assignStudents.length})
            </SeminarButton>
            <SeminarButton onClick={() => setNewSchoolModal({ open: true })}>
              Add School
            </SeminarButton>
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <TableHeader>SCHOOL</TableHeader>
              <TableHeader>TEAM NUMBER</TableHeader>
              <TableHeader>STUDENTS</TableHeader>
              <TableHeader>INSTRUCTORS</TableHeader>
              <TableHeader />
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableCell>
                <Link
                  className={hoverLink}
                  to={`/schools/${seminar.host_school.id}/overview`}
                >
                  {seminar.host_school.school_name}
                </Link>
              </TableCell>
              <TableCell>(host school)</TableCell>
              <TableCell>{seminar.host_school.enrollments_count}</TableCell>
              <TableCell>{seminar.host_school.instructors_count}</TableCell>
              <TableCell />
            </tr>
            {seminar.schools.map((school) => (
              <tr key={school.id}>
                <TableCell>
                  <Link
                    className={hoverLink}
                    to={`/schools/${school.id}/overview`}
                  >
                    {school.school_name}
                  </Link>
                </TableCell>
                <TableCell>{school.series_offered.team_number}</TableCell>
                <TableCell>{school.enrollments_count}</TableCell>
                <TableCell>{school.instructors_count}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleDelete(school.series_offered)}
                    variant="primary-outline"
                  >
                    Delete
                  </Button>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
      <Modal
        open={newSchoolModal.open}
        setOpen={() => setNewSchoolModal({ open: false })}
      >
        <NewSchool
          closeModal={() => setNewSchoolModal({ open: false })}
          seminar={seminar}
        />
      </Modal>
      <Modal
        open={assignStudentsModal.open}
        setOpen={() => setAssignStudentsModal({ open: false })}
        fullWidth
      >
        <AssignStudents
          closeModal={() => {
            setAssignStudentsModal({ open: false });
            navigate(`/seminars/${seminar.id}/details`);
          }}
          seminar={seminar}
          assignStudents={assignStudents}
          recentlyAssigned={recentlyAssigned}
          setRecentlyAssigned={setRecentlyAssigned}
        />
      </Modal>
      <Modal dark open={deleteModal} setOpen={setDeleteModal}>
        <ConfirmationDialog
          closeModal={() => setDeleteModal(false)}
          confirmAction={() => deleteSchool()}
        />
      </Modal>
    </div>
  );
}
