import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import ActionMenuContainer from "../../ActionMenuContainer";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import { useRouteLoaderData } from "react-router-dom";
import InputGroup from "../../../UI/Inputs/InputGroup";
import DatePickerInput from "../../../UI/Inputs/DatePickerInput";
import { updateEnrollmentRequest } from "../../../../requests/enrollments";
import { displayError } from "../../../../utils/displayError";
import { ToastNotification } from "../../../UI/ToastNotification";

export default function NewStudentReminder({ setRender }) {
  const student = useRouteLoaderData("student").data;

  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      const enrollmentId = student.course_enrollments.find(
        (enrollment) => enrollment.series_id === data.course_id
      ).id;

      const res = await updateEnrollmentRequest({
        reminders_attributes: [
          {
            date: data.date,
            notes_attributes:
              data.note_text && data.note_type
                ? [
                    {
                      text: data.note_text,
                      type: data.note_type,
                    },
                  ]
                : [],
          },
        ],
        enrollmentId: enrollmentId,
      });

      if (res.data) {
        ToastNotification("success", "Reminder created successfully.");
        setRender(null);
      }
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <ActionMenuContainer
      label="Add a reminder"
      handleGoingBack={() => setRender(null)}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputGroup>
            <Select
              id="course_id"
              classNames="w-full bg-transparent"
              label="Course"
              isRequired
            >
              <SelectItem value="">Select</SelectItem>
              {student.course_enrollments?.map((enrollment) => (
                <SelectItem key={enrollment.id} value={enrollment.series_id}>
                  {enrollment.series_name}
                </SelectItem>
              ))}
            </Select>
            <DatePickerInput
              id="date"
              dark
              label="Sent"
              selected={new Date()}
              isRequired
            />
          </InputGroup>
          <InputGroup>
            <Select
              id="note_type"
              classNames="w-full bg-transparent"
              label="Note Type"
            >
              <SelectItem value="">Select</SelectItem>
              <SelectItem value="Note">Note</SelectItem>
              <SelectItem value="PrayerRequest">Prayer Request</SelectItem>
            </Select>
            <Input id="note_text" label="Note" dark />
          </InputGroup>
          <div className="text-center">
            <Button type="submit" variant="primary">
              Save Reminder
            </Button>
          </div>
        </form>
      </FormProvider>
    </ActionMenuContainer>
  );
}
