import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { Link } from "react-router-dom";
import { hoverLink } from "../../utils/hoverLink";
import { renderAddress } from "../../utils/fullAddress";
import React from "react";
import Pill from "../UI/Pill";

export default function SchoolsTable({ schools, loading }) {
  const renderActive = (active) => {
    return active ? <Pill color="success">Active</Pill> : <Pill>Inactive</Pill>;
  };

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader isSortable sortBy="school_code">
            CODE
          </TableHeader>
          <TableHeader isSortable sortBy="school_name">
            NAME
          </TableHeader>
          <TableHeader
            isSortable
            isMultiSort
            multiSortOptions={[
              { name: "City", value: "school_city" },
              { name: "State", value: "school_state" },
              { name: "Zip", value: "school_zip_code" },
              { name: "Country", value: "school_country" },
            ]}
          >
            ADDRESS
          </TableHeader>
          <TableHeader isSortable sortBy="conference_name">
            CONFERENCE
          </TableHeader>
          <TableHeader>COURSES</TableHeader>
          <TableHeader>STATUS</TableHeader>
        </tr>
      </thead>
      <tbody>
        {schools.map((school) => (
          <tr key={school.id} className={loading ? "opacity-25" : undefined}>
            <TableCell>
              <Link className={hoverLink} to={`/schools/${school.id}/overview`}>
                #{school.school_code}
              </Link>
            </TableCell>
            <TableCell>
              <Link className={hoverLink} to={`/schools/${school.id}/overview`}>
                {school.school_name}
              </Link>
            </TableCell>
            <TableCell>
              {renderAddress([
                school.school_address,
                school.school_address_2,
                school.school_city,
                school.school_state,
                school.school_zip_code,
              ])}
            </TableCell>
            <TableCell>{school.conference?.name}</TableCell>
            <TableCell>{school.courses_count}</TableCell>
            <TableCell>{renderActive(school.active)}</TableCell>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
