import React from "react";
import Instructors from "../Instructors/Instructors";
import Instructor from "../Instructors/Instructor/Instructor";
import InstructorOverview from "../Instructors/Instructor/InstructorOverview/InstructorOverview";
import InstructorProfile from "../Instructors/Instructor/InstructorProfile/InstructorProfile";
import InstructorStudents from "../Instructors/Instructor/InstructorStudents/InstructorStudents";
import InstructorCourses from "../Instructors/Instructor/InstructorCourses/InstructorCourses";
import InstructorNew from "../Instructors/Instructor/InstructorNew";
import { getUserRequest } from "../../requests/users";

export default [
  {
    path: "/instructors",
    element: <Instructors />,
  },
  {
    path: "/instructors/new",
    element: <InstructorNew />,
  },
  {
    path: "/instructors/:userId",
    element: <Instructor />,
    id: "user",
    loader: ({ params }) => {
      return getUserRequest({ userId: params.userId });
    },
    children: [
      {
        path: "/instructors/:userId/overview",
        element: <InstructorOverview />,
      },
      {
        path: "/instructors/:userId/profile",
        element: <InstructorProfile />,
      },
      {
        path: "/instructors/:userId/students",
        element: <InstructorStudents />,
      },
      {
        path: "/instructors/:userId/courses",
        element: <InstructorCourses />,
      },
    ],
  },
];
