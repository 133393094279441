import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import Checkbox from "../../../UI/Inputs/Checkbox";
import { fullName } from "../../../../utils/fullName";
import Input from "../../../UI/Inputs/Input";
import InputGroup from "../../../UI/Inputs/InputGroup";
import Button from "../../../UI/Button";
import { getOrdinal } from "../../../../utils/getOrdinal";

export default function StudentView({
  onSubmit,
  seminar,
  participatingSchoolsInstructors,
  attendances,
  selectedStudent,
  methods,
  handleNext,
  handlePrevious,
  totalStudents,
  currentStudentNumber,
}) {
  const [selectedInstructorId, setSelectedInstructorId] = useState();

  const checkboxCell = (enrollment) =>
    attendances?.presentations?.map((presentation, index) => (
      <div className="mr-4 text-center" key={index}>
        <p>{`${index + 1}${getOrdinal(index + 1)}`}</p>
        <input
          type="checkbox"
          readOnly
          onClick={(e) => e.preventDefault()} // make it readonly
          checked={enrollment.episode_deliveries.find(
            (episode_delivery) =>
              episode_delivery.episode_id === presentation.id
          )}
        />
      </div>
    ));

  // I had to do this so that when we change viewModes the values from the form persists.
  useEffect(() => {
    if (selectedStudent) {
      Object.keys(selectedStudent).forEach((key) => {
        if (key !== "instructor_id") {
          methods.setValue(key, selectedStudent[key]);
        }
      });
    }
  }, [selectedStudent, methods]);

  useEffect(() => {
    if (selectedInstructorId) {
      methods.setValue("instructor_id", selectedInstructorId);
    }
  }, [selectedInstructorId, selectedStudent]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="md:flex-row flex items-top mt-2">
          <h3 className="text-3xl w-2/5 font-semibold">
            {selectedStudent?.first_name} {selectedStudent?.last_name}
          </h3>

          <div className="w-3/5 flex justify-end">
            <p className="content-top md:mr-4 pt-2 whitespace-nowrap">
              Assign to:
            </p>

            <div className="md:w-3/6 md:mr-4">
              <div className="select-wrapper">
                <select
                  className="w-full rounded-md"
                  onChange={(e) => setSelectedInstructorId(e.target.value)}
                  defaultValue={selectedInstructorId || ""}
                >
                  <option value="" disabled>
                    Teacher name
                  </option>

                  <optgroup
                    label={seminar.host_school.school_name}
                    key={seminar.host_school.id}
                  >
                    {seminar.host_school.users
                      .filter(
                        (u) => u.id !== seminar.host_school.head_school_admin_id
                      )
                      .map((instructor) => (
                        <option
                          key={instructor.id}
                          value={`${instructor.id}#${seminar.host_school.id}`}
                        >
                          {fullName(
                            instructor.first_name,
                            "",
                            instructor.last_name
                          )}
                        </option>
                      ))}
                  </optgroup>
                  {participatingSchoolsInstructors.map((school) => (
                    <>
                      {school.users
                        .filter((u) => u.id === school.head_school_admin_id)
                        .map((instructor) => (
                          <>
                            <option
                              key={`${instructor.id}#${school.id}`}
                              value={`${instructor.id}#${school.id}`}
                            >
                              {school.school_name}
                            </option>
                            <option
                              className="pl-10"
                              disabled
                              key={school.head_school_admin_id}
                              value={school.head_school_admin_id}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {fullName(
                                instructor.first_name,
                                "",
                                instructor.last_name
                              )}
                            </option>
                          </>
                        ))}
                    </>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <div className="text-center flex items-center">
                <Button
                  type="submit"
                  variant="primary"
                  classNames="md:mr-4 whitespace-nowrap"
                  disabled={!selectedInstructorId}
                >
                  Save & Next
                </Button>
                <button
                  type="button"
                  disabled={currentStudentNumber === 1}
                  className="flex items-center justify-center px-4 py-2 border border-[#0694C0] text-[#0694C0] rounded-md hover:bg-blue-50 transition-colors duration-300 disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
                  onClick={() => handlePrevious()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={currentStudentNumber === totalStudents}
                  className="flex items-center justify-center px-4 py-2 border border-[#0694C0] text-[#0694C0] rounded-md hover:bg-blue-50 transition-colors duration-300 disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed ml-3"
                  onClick={() => handleNext()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 ml-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <p className="text-right mt-3">
                Showing {currentStudentNumber}
                {getOrdinal(currentStudentNumber)} of {totalStudents} students
              </p>
            </div>
          </div>
        </div>
      </form>

      <p className="mb-2 text-[20px]" style={{ marginTop: "-30px" }}>
        Attendance
      </p>

      {attendances?.enrollments?.length > 0 &&
        attendances.enrollments.map((enrollment) => (
          <div className="inline-flex" key={enrollment.id}>
            {attendances?.presentations?.length > 0 && checkboxCell(enrollment)}
          </div>
        ))}

      <div className="flex md:flex-row flex-col">
        <div className="md:w-3/5 md:border-r md:border-black md:pr-6">
          <p className="mt-4 mb-4"> Student </p>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="first_name"
              defaultValue={selectedStudent?.first_name}
              placeholder="First name"
              readOnly
            />
            <Input
              inputClassNames="relative"
              id="middle_name"
              defaultValue={selectedStudent?.middle_name}
              placeholder="Middle name"
              readOnly
            />
            <Input
              inputClassNames="relative"
              id="last_name"
              defaultValue={selectedStudent?.last_name}
              placeholder="Last name"
              readOnly
            />
          </InputGroup>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="address"
              defaultValue={selectedStudent?.address}
              placeholder="Address 1"
              readOnly
            />
          </InputGroup>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="city"
              defaultValue={selectedStudent?.city}
              placeholder="City"
              readOnly
            />

            <Input
              inputClassNames="relative"
              id="state"
              defaultValue={selectedStudent?.state}
              placeholder="State"
              readOnly
            />

            <Input
              inputClassNames="relative"
              id="zip_code"
              defaultValue={selectedStudent?.zip_code}
              placeholder="Zip/Postal"
              readOnly
            />
          </InputGroup>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="email"
              defaultValue={selectedStudent?.email}
              placeholder="Email"
              readOnly
              label="Email Address"
            />
          </InputGroup>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="social_media"
              defaultValue={selectedStudent?.social_media}
              placeholder="Social Media"
              readOnly
              label="Social Media"
            />
          </InputGroup>
        </div>

        <div className="md:w-2/5 md:pl-6">
          <p className="mt-4 mb-4"> Demographics </p>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="gender"
              defaultValue={selectedStudent?.gender}
              placeholder="Gender"
              readOnly
              label="Gender"
            />
          </InputGroup>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="marital_status"
              defaultValue={selectedStudent?.married ? "Married" : "Single"}
              placeholder="Marital Status"
              readOnly
              label="Marital Status"
            />
          </InputGroup>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="birthdate"
              defaultValue={selectedStudent?.birthdate}
              readOnly
              label="Birth Date"
            />
          </InputGroup>

          <InputGroup col>
            <Input
              inputClassNames="relative"
              id="denomination"
              defaultValue={selectedStudent?.denomination}
              placeholder="Denomination"
              readOnly
              label="Denomination"
            />
            <Checkbox
              inputClassNames="relative"
              id="baptized"
              defaultChecked={selectedStudent?.baptized}
              readOnly
              label="Is baptized"
              labelColor="#ebebeb"
              labelFont="font-small"
            />
          </InputGroup>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="interests"
              defaultValue={selectedStudent?.interest}
              readOnly
              label="Interests"
            />
          </InputGroup>

          <InputGroup>
            <Input
              inputClassNames="relative"
              id="source"
              defaultValue={selectedStudent?.source}
              readOnly
              label="Source"
            />
          </InputGroup>
        </div>
      </div>
    </FormProvider>
  );
}
