import React, { useEffect, useState } from "react";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import {
  getAllCourseDecisionsRequest,
  getAllCourseLessonsRequest,
} from "../../../../../../requests/courses";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { useOutletContext, useRouteLoaderData } from "react-router-dom";
import LessonsRow from "./Lesson/LessonsRow";
import EmptyTable from "../../../../../UI/EmptyTable";

export default function Lessons() {
  const { user, seriesEnrollment } = useOutletContext();

  const enrollment = useRouteLoaderData(seriesEnrollment).data;

  const [lessons, setLessons] = useState([]);
  const [decisionOptions, setDecisionOptions] = useState([]);

  const getLessons = async () => {
    try {
      const res = await getAllCourseLessonsRequest({
        courseId: enrollment.series_id,
      });

      if (res.data) {
        setLessons(res.data);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const getAllCourseDecisions = async () => {
    const res = await getAllCourseDecisionsRequest({
      params: {
        student_id: enrollment.student_id,
      },
      courseId: enrollment.series_id,
    });

    if (res.data) {
      setDecisionOptions(res.data);
    }
  };

  useEffect(() => {
    getLessons();
    getAllCourseDecisions();
  }, []);

  return (
    <div>
      {lessons.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>LESSON</TableHeader>
              <TableHeader>SENT</TableHeader>
              <TableHeader>RECEIVED</TableHeader>
              <TableHeader>DELIVERY TYPE</TableHeader>
              <TableHeader>NOTES & DECISIONS</TableHeader>
            </tr>
          </thead>
          <tbody>
            {lessons.map((lesson) => (
              <LessonsRow
                lesson={lesson}
                key={lesson.id}
                decisionOptions={decisionOptions}
                enrollment={enrollment}
                user={user}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No lessons available for this course." />
      )}
    </div>
  );
}
