import React from "react";
import BackLink from "../../../../Mobile/BackLink";
import { Link, useRouteLoaderData } from "react-router-dom";
import Card from "../../../../UI/Card";
import { faker } from "@faker-js/faker";
import { twMerge } from "tailwind-merge";

export default function StudentMobileCourses({ courses }) {
  const student = useRouteLoaderData("student").data;

  return (
    <div>
      <BackLink path={`/students/${student?.id}/overview`} label="Courses" />

      <div className="pt-4">
        {courses.map((course) => (
          <Link
            key={course.id}
            to={`/students/${student.id}/overview`}
            className="mb-4 last:mb-0 block"
          >
            <Card
              classNames={twMerge(
                "flex justify-between items-center",
                course.status === "Complete" && "bg-black/70 text-white"
              )}
            >
              <p className="text-[17px]">{course.name}</p>
              {course.status === "Complete" ? (
                <div className="text-center text-xs">
                  <p>Complete</p>
                  <p>10/12/2021</p>
                </div>
              ) : (
                <div className="flex text-center">
                  <div className="mr-4">
                    <p className="text-xs">Lesson</p>
                    <span className="text-[40px]">{faker.number.int(100)}</span>
                  </div>
                  <div>
                    <p className="text-xs">Days Past</p>
                    <span className="text-[40px]">{faker.number.int(100)}</span>
                  </div>
                </div>
              )}
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
