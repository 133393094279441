import React from "react";
import { Link } from "react-router-dom";
import Card from "../../UI/Card";
import { fullName } from "../../../utils/fullName";
import BackLink from "../../Mobile/BackLink";

export default function StudentsMobileList({ students }) {
  return (
    <div>
      <BackLink path="/" label="Students" />

      <div className="pt-4">
        {students.map((student) => (
          <Link
            key={student.id}
            to={`/students/${student.id}/overview`}
            className="mb-4 last:mb-0 block"
          >
            <Card>
              <p>{fullName(student.first_name, " ", student.last_name)}</p>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
