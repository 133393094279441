import React, { useState } from "react";
import DatePickerInput from "../../../../../../UI/Inputs/DatePickerInput";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../../../../UI/Button";
import InputGroup from "../../../../../../UI/Inputs/InputGroup";
import dayjs from "dayjs";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import { hoverLink } from "../../../../../../../utils/hoverLink";
import { useNavigate } from "react-router-dom";

export default function DateReceived({
  lesson,
  enrollment,
  episodeDelivery,
  user,
}) {
  const [formOpen, setFormOpen] = useState(false);
  const methods = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            date_completed: data.date_completed,
            episode_id: lesson.id,
            grading_instructor_id: user.id,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        setFormOpen(false);
        navigate(".", { replace: true });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  if (!formOpen) {
    return (
      <p onClick={() => setFormOpen(true)} className={hoverLink}>
        {episodeDelivery?.date_completed
          ? dayjs(episodeDelivery.date_completed).format("MM/DD/YYYY")
          : "-"}
      </p>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputGroup classNames="mb-0">
          <DatePickerInput
            id="date_completed"
            selected={episodeDelivery?.date_completed}
            autoFocus
          />

          <div>
            <Button
              onClick={() => setFormOpen(false)}
              classNames="mr-4"
              variant="primary-outline"
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary-outline">
              Save
            </Button>
          </div>
        </InputGroup>
      </form>
    </FormProvider>
  );
}
