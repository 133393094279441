import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ActionMenuContainer from "../../ActionMenuContainer";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import { useRouteLoaderData } from "react-router-dom";
import InputGroup from "../../../UI/Inputs/InputGroup";
import {
  getAllCourseDecisionsRequest,
  getAllCourseLessonsRequest,
} from "../../../../requests/courses";
import { displayError } from "../../../../utils/displayError";
import DatePickerInput from "../../../UI/Inputs/DatePickerInput";
import MultiSelect from "../../../UI/Inputs/MultiSelect";
import {
  getEnrollmentRequest,
  updateEnrollmentRequest,
} from "../../../../requests/enrollments";
import { ToastNotification } from "../../../UI/ToastNotification";

export default function NewStudentLesson({ setRender, user }) {
  const student = useRouteLoaderData("student").data;
  const methods = useForm();

  const [lessons, setLessons] = useState([]);
  const [decisionOptions, setDecisionOptions] = useState([]);
  const [enrollment, setEnrollment] = useState(null);

  const selectedCourse = methods.watch("course_id");
  const selectedEnrollmentId = student.course_enrollments.find(
    (enrollment) => enrollment.series_id === selectedCourse
  )?.id;

  const onSubmit = async (data) => {
    try {
      const notes = [
        {
          text: data?.note_text,
          type: data?.note_type,
        },
      ];

      const decisions = data.decisions.map((decision) => ({
        decision_option_id: decision.id,
        cached_enrollment_id: selectedEnrollmentId,
      }));

      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            date_sent: data.date_sent,
            date_completed: data.date_completed,
            delivery_type: data.delivery_type,
            decisions_attributes: decisions,
            notes_attributes: data.note_text && data.note_type ? notes : [],
            episode_id: data.lesson_id,
            grading_instructor_id: user.id,
          },
        ],
        enrollmentId: selectedEnrollmentId,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        setRender(null);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const deliveryTypes = [
    {
      label: "PO",
      value: "Post Office",
    },
    {
      label: "PD",
      value: "Personal Delivery",
    },
    {
      label: "IH",
      value: "In-home",
    },
  ];

  const getLessons = async () => {
    try {
      const res = await getAllCourseLessonsRequest({
        courseId: selectedCourse,
      });

      if (res.data) {
        setLessons(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getAllCourseDecisions = async () => {
    const res = await getAllCourseDecisionsRequest({
      courseId: selectedCourse,
    });

    if (res.data) {
      setDecisionOptions(res.data);
    }
  };

  const getEnrollment = async () => {
    try {
      const res = await getEnrollmentRequest({
        enrollmentId: selectedEnrollmentId,
      });

      if (res.data) {
        setEnrollment(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      getLessons();
      getAllCourseDecisions();
      getEnrollment();
    }
  }, [selectedCourse]);

  return (
    <ActionMenuContainer
      label="Send a lesson"
      handleGoingBack={() => setRender(null)}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputGroup>
            <Select
              id="course_id"
              classNames="w-full bg-transparent"
              label="Course"
              isRequired
            >
              <SelectItem value="">Select</SelectItem>
              {student.course_enrollments?.map((enrollment) => (
                <SelectItem key={enrollment.id} value={enrollment.series_id}>
                  {enrollment.series_name}
                </SelectItem>
              ))}
            </Select>
            <Select
              id="lesson_id"
              classNames="w-full bg-transparent"
              label="Lesson"
              isRequired
            >
              <SelectItem value="" disabled>
                {selectedCourse ? "Select" : "Select a course first"}
              </SelectItem>
              {lessons
                .filter(
                  (lesson) =>
                    !enrollment?.episode_deliveries.some(
                      (delivery) => delivery.episode_id === lesson.id
                    )
                )
                ?.map((lesson) => (
                  <SelectItem key={lesson.id} value={lesson.id}>
                    {lesson.name}
                  </SelectItem>
                ))}
            </Select>
          </InputGroup>
          <InputGroup>
            <DatePickerInput id="date_sent" dark label="Sent" isRequired />
            <DatePickerInput id="date_completed" dark label="Received" />
          </InputGroup>
          <InputGroup>
            <Select
              id="delivery_type"
              classNames="w-full bg-transparent"
              label="Delivery Type"
            >
              <SelectItem value="">Select</SelectItem>
              {deliveryTypes?.map((deliveryType) => (
                <SelectItem key={deliveryType.value} value={deliveryType.value}>
                  {deliveryType.value}
                </SelectItem>
              ))}
            </Select>
          </InputGroup>
          {decisionOptions.length > 0 && (
            <InputGroup>
              <MultiSelect
                id="decisions"
                label="Decisions"
                placeholder="Add Decision"
                options={decisionOptions}
                defaultSelected={[]}
                dark
              />
            </InputGroup>
          )}
          <InputGroup>
            <Select
              id="note_type"
              classNames="w-full bg-transparent"
              label="Note Type"
              isRequired
            >
              <SelectItem value="Note">Note</SelectItem>
              <SelectItem value="PrayerRequest">Prayer Request</SelectItem>
            </Select>
            <Input id="note_text" label="Note" dark />
          </InputGroup>
          <div className="text-center">
            <Button type="submit" variant="primary">
              Save Lesson
            </Button>
          </div>
        </form>
      </FormProvider>
    </ActionMenuContainer>
  );
}
