import React from "react";
import { fullName } from "../../../../../utils/fullName";

export default function MobileResults({ students }) {
  return (
    <ul>
      {students.map((student) => (
        <li key={student.id} className="border-b border-white py-4">
          {fullName(student.first_name, " ", student.last_name)}
        </li>
      ))}
    </ul>
  );
}
