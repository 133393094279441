import React, { useEffect, useState } from "react";
import { getAttendanceReport } from "../../requests/reports";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { usePagination } from "../UI/Pagination";
import { useSearchParams } from "react-router-dom";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { fullName } from "../../utils/fullName";
import { Pagination } from "../UI/Pagination";
import { renderAddress } from "../../utils/fullAddress";
import { renderPresentations } from "../../utils/reports/reportAttendanceChecks";
import StudentsFilter from "../Students/StudentsFilter";
import { useFilterValues } from "../UI/Filter/Filter";
import QuickFind from "../UI/QuickFind";

export default function AttendanceDecisionReport({ seminar }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pagination = usePagination();
  const query = searchParams.get("query") || "";
  const filterValues = useFilterValues();

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    setLoading(true);

    try {
      const res = await getAttendanceReport({
        params: {
          seminar_id: searchParams.get("seminar_id"),
          page: page,
          per_page: pagination.recordsPerPage,
          q: {
            full_name_i_cont_all: query.split(" "),
            ...filterValues,
          },
        },
      });

      if (res.data) {
        setReports(res.data);
        pagination.setData(res.data.pagination);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReport();
  }, [page, seminar, pagination.recordsPerPage, searchParams]);

  return (
    <div>
      <div className="flex justify-between my-10">
        <p>Attendance Decision Report for {seminar?.name}</p>
        <div className="flex">
          <QuickFind
            onSubmit={(data) =>
              setSearchParams((searchParams) => {
                searchParams.set("query", data.search);
                return searchParams;
              })
            }
            value={query}
          />
          <div className="relative ml-7">
            <StudentsFilter />
          </div>
        </div>
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && reports?.length < 1}
        empty={reports?.length < 1}
      >
        {reports.enrollments?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>REG. NO.</TableHeader>
                <TableHeader>STUDENT</TableHeader>
                <TableHeader>ADDRESS</TableHeader>
                <TableHeader>ATTENDANCE</TableHeader>
                <TableHeader>DECISIONS</TableHeader>
                <TableHeader>INSTRUCTOR</TableHeader>
                <TableHeader>SCHOOL</TableHeader>
              </tr>
            </thead>
            <tbody>
              {reports?.enrollments.map((enrollment) => (
                <tr key={enrollment.id}>
                  <TableCell>{enrollment.registration_number || "-"}</TableCell>
                  <TableCell>
                    {fullName(
                      enrollment.student.first_name,
                      "",
                      enrollment.student.last_name
                    )}
                  </TableCell>
                  <TableCell classNames="max-w-[100px]">
                    {renderAddress([
                      enrollment.student.address,
                      "",
                      enrollment.student.city,
                      enrollment.student.state,
                      enrollment.student.zip_code,
                      "",
                    ])}
                  </TableCell>
                  <TableCell>
                    <div className="flex">
                      {reports?.presentations.length > 0 &&
                        renderPresentations(reports, enrollment)}
                    </div>
                  </TableCell>
                  <TableCell>
                    {enrollment.decision_options?.map((decision) => (
                      <p key={decision.id}>{decision.name}</p>
                    ))}
                  </TableCell>
                  <TableCell>{enrollment.student.instructor_name}</TableCell>
                  <TableCell>
                    {`(${enrollment.student.school.school_code})
                ${enrollment.student.school.church_name}`}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No attendances recorded." />
        )}
      </Pagination>
    </div>
  );
}
