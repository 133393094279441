import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { twMerge } from "tailwind-merge";

export default function ActionMenuHeader({
  label,
  goBack,
  clickToClose,
  light,
}) {
  return (
    <div className="relative border-b border-white text-center">
      {goBack && (
        <ArrowLeftIcon
          onClick={goBack}
          className="sm:-translate-y-0 md:w-7 w-5 ml-6 cursor-pointer absolute top-1/2 -translate-y-1/2"
        />
      )}
      {clickToClose && (
        <XMarkIcon
          onClick={clickToClose}
          className={twMerge(
            light ? "text-black" : "text-white",
            "w-10 ml-auto cursor-pointer absolute right-0 pt-6 mr-6 z-50"
          )}
        />
      )}
      {label && <h1 className="md:text-[32px] text-[24px] sm:pt-6">{label}</h1>}
    </div>
  );
}
