import React from "react";
import AsyncSelect from "react-select/async";
import { getAllUsers } from "../../requests/users";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export default function SearchUser({ userType, field }) {
  const loadOptions = async (inputValue) => {
    try {
      const response = await getAllUsers({
        params: {
          type: userType,
          q: {
            first_name_or_last_name_or_email_cont: inputValue,
            s: "first_name asc",
          },
        },
      });

      // Group users by search_group_name
      const groupedUsers = response.data.users.reduce((acc, user) => {
        const group = user.search_group_name || "No School";
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push({
          label: `${user.first_name} ${user.last_name} (${user.email})`,
          value: user.id,
        });
        return acc;
      }, {});

      // Transform grouped users into the required format
      const options = Object.entries(groupedUsers).map(([group, users]) => ({
        label: group,
        options: users,
      }));

      return options;
    } catch (error) {
      return [];
    }
  };

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0",
    color: "#9ca3af",
  };

  const groupBadgeStyles = {
    backgroundColor: "#444",
    borderRadius: "2em",
    color: "#fff",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.17em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: state.isFocused ? "#0694C0" : "#6b7280",
      boxShadow: state.isFocused ? "0 0 0 1px #0694C0" : "none",
      "&:hover": {
        borderColor: "#0694C0",
      },
      minHeight: "38px",
      height: "38px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "38px",
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9ca3af",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#444" : "transparent",
      color: state.isFocused ? "white" : "#d1d5db",
      paddingLeft: "24px",
      "&:hover": {
        backgroundColor: "#444",
        color: "white",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
  };

  return (
    <>
      <AsyncSelect
        {...field}
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        placeholder={`Search ${userType}`}
        styles={customStyles}
        className="w-full"
        classNamePrefix="react-select"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        formatGroupLabel={formatGroupLabel}
      />
      <MagnifyingGlassIcon
        className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none"
        aria-hidden="true"
      />
    </>
  );
}
