import React from "react";
import Print from "../UI/Print";
import { fullName } from "../../utils/fullName";
import { returnEnrollmentStatus } from "../../utils/enrollmentStatus";
import dayjs from "dayjs";

const StudentsPrint = ({ students, handleAfterPrint }) => {
  return (
    <Print
      header="STUDENTS"
      empty={students.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>STUDENT/PHONE</th>
            <th>
              ENROLLMENTS
              <br />
              (Last lesson sent / Registration #)
            </th>
            <th>ADDRESS</th>
            <th>CREATED</th>
            <th>TEACHER</th>
            <th>SCHOOL</th>
            <th>CONFERENCE</th>
          </tr>
        </thead>
        <tbody>
          {students.length > 0 &&
            students.map((student) => (
              <tr key={student.id}>
                <td>
                  {fullName(
                    student.first_name,
                    student.middle_name,
                    student.last_name
                  )}
                  <br />
                  {student.phone}
                </td>
                <td>
                  {student.enrollments.map((enrollment) => (
                    <div key={enrollment.id} className="whitespace-nowrap">
                      {enrollment.series_type === "Seminar" ? (
                        <>
                          {enrollment.series_name}
                          {" - "}
                          {returnEnrollmentStatus(
                            enrollment.series_id,
                            enrollment
                          )}
                          {" - "}
                          {enrollment.registration_number}
                        </>
                      ) : (
                        <>
                          {enrollment.series_name}
                          {" - "}
                          {returnEnrollmentStatus(
                            enrollment.series_id,
                            enrollment
                          )}
                          {" - "}
                          {enrollment.last_completed_delivery
                            ? dayjs(
                                enrollment.last_completed_delivery
                                  ?.date_completed
                              ).format("MMM D, YYYY")
                            : "No lessons"}
                        </>
                      )}
                    </div>
                  ))}
                </td>
                <td className="whitespace-nowrap">
                  {student.address && (
                    <>
                      {student.address}
                      {student.address_2 && (
                        <>
                          <br />
                          {student.address_2}
                        </>
                      )}
                      <br />
                      {student.city}
                      <br />
                      {student.state} {student.zip_code}
                      <br />
                      {student.country}
                    </>
                  )}
                </td>
                <td className="whitespace-nowrap">
                  {dayjs(student.created_at).format("MMM D, YYYY")}
                </td>
                <td>{student.instructor_name}</td>
                <td>
                  (#{student.school?.school_code}){" "}
                  {student.school?.school_name || "-"}
                </td>
                <td>{student.school?.conference?.name || "-"}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Print>
  );
};

export default StudentsPrint;
