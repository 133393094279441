import React from "react";
import { Link } from "react-router-dom";
import Card from "../../UI/Card";
import BackLink from "../../Mobile/BackLink";

export default function CoursesMobileList({ courses }) {
  return (
    <div>
      <BackLink path="/" label="Courses" />

      <div className="pt-4">
        {courses.map((course) => (
          <Link
            key={course.id}
            to={`/courses/${course.id}/overview`}
            className="mb-4 last:mb-0 block"
          >
            <Card>
              <p>{course.name}</p>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
