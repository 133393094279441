import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/questions`;

const getAllQuestionsRequest = async (params) => {
  const data = await axiosClient.get(endpoint, params);

  return data;
};

const createQuestionRequest = async (params) => {
  const data = await axiosClient.post(endpoint, params);

  return data;
};

const updateQuestionRequest = async (params) => {
  const data = await axiosClient.put(
    `${endpoint}/${params.questionId}`,
    params
  );

  return data;
};

const deleteQuestionRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.questionId}`);

  return data;
};

export {
  createQuestionRequest,
  deleteQuestionRequest,
  updateQuestionRequest,
  getAllQuestionsRequest,
};
