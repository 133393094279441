import React from "react";
import { Tooltip } from "../../UI/Tooltip";
import { usePageActions } from "../../../contexts/PageActionsContext";
import { ShareIcon } from "../../UI/PageActions/Icons";

const Spinner = () => (
  <svg
    className="animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

// Avoid showing spinner on Safari (it doesn't work well with the spinner)
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const ActionButton = ({
  icon: Icon = ShareIcon,
  label,
  onClick,
  disabled,
  loading,
}) => (
  <Tooltip content={label}>
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={`p-2 rounded-full ${
        disabled || loading ? "cursor-not-allowed" : "hover:bg-gray-100"
      }`}
    >
      {loading && !isSafari ? (
        <div className="w-6 h-6 flex items-center justify-center mr-[1px] ml-[2px]">
          <Spinner />
        </div>
      ) : (
        <Icon color={disabled ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, .7)"} />
      )}
    </button>
  </Tooltip>
);

const PageActions = () => {
  const { pageActions } = usePageActions();

  if (!pageActions || pageActions.length === 0) return null;

  return (
    <div className="flex justify-center space-x-2">
      {pageActions.map((action, index) => (
        <ActionButton key={index} {...action} />
      ))}
    </div>
  );
};

export default PageActions;
