import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Select from "../../../UI/Inputs/Select/Select";
import Button from "../../../UI/Button";
import { getAllCourses } from "../../../../requests/courses";

export default function CreateNewStudent({
  setCreateNewStudent,
  setNewStudentForm,
  selectedCourseId,
  setSelectedCourseId,
  courses,
  setCourses,
}) {
  const methods = useForm();

  const getCourses = async () => {
    const res = await getAllCourses({ pagination: false });

    if (res.data) {
      setCourses(res.data);
    }
  };

  const onSubmit = (data) => {
    setSelectedCourseId(data.course);
    setCreateNewStudent(false);
    setNewStudentForm(true);
  };

  useEffect(() => {
    if (courses.length === 0) {
      getCourses();
    }
  }, []);

  return (
    <>
      <h2 className="text-2xl text-center">Assign new student automatically</h2>
      <p className="text-[15px] text-center">
        Select the course you would like to enroll the new student in.
      </p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-6">
          <Select
            id="course"
            classNames="w-full bg-transparent"
            defaultValue={selectedCourseId}
          >
            <SelectItem value="" disabled>
              Select a course
            </SelectItem>
            {courses.map((course) => (
              <SelectItem value={course.id} key={course.id}>
                {course.name}
              </SelectItem>
            ))}
          </Select>

          <div className="text-center mt-4">
            <Button type="submit" variant="primary">
              Next
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
