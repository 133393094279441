import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function DesktopNavigation({ baseNavigation }) {
  return (
    <header className="relative hidden h-24 border-b border-gray-300 backdrop-blur-3xl lg:flex">
      <div className="container mx-auto flex justify-center">
        {baseNavigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              twMerge(isActive && "text-sky-600", "px-6 pb-2 pt-8")
            }
          >
            {({ isActive }) => (
              <div className="inline-flex flex-col items-center justify-start gap-1 transition-all hover:scale-105">
                <div>{item.icon(isActive ? "#0284C7" : "black")}</div>
                <p>{item.name}</p>
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </header>
  );
}
