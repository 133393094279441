import React, { useEffect, useState } from "react";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import TableCell from "../../../../../UI/Table/TableCell";
import { useOutletContext, useRouteLoaderData } from "react-router-dom";
import { getDecisionSummaryRequest } from "../../../../../../requests/enrollments";
import dayjs from "dayjs";
import EmptyTable from "../../../../../UI/EmptyTable";

export default function Decisions() {
  const { seriesEnrollment } = useOutletContext();
  const [decisions, setDecisions] = useState([]);
  const enrollment = useRouteLoaderData(seriesEnrollment).data;

  const getDecisionSummary = async () => {
    const res = await getDecisionSummaryRequest({
      enrollmentId: enrollment.id,
    });

    if (res.data) {
      setDecisions(res.data);
    }
  };

  useEffect(() => {
    getDecisionSummary();
  }, []);

  return (
    <div>
      {decisions.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>DECISION</TableHeader>
              <TableHeader>LESSON</TableHeader>
              <TableHeader>DATE</TableHeader>
            </tr>
          </thead>
          <tbody>
            {decisions.map((decision) => (
              <tr key={decision.id}>
                <TableCell>{decision.decision_option.name}</TableCell>
                <TableCell>{decision.context}</TableCell>
                <TableCell>
                  {decision.owner.date_sent || decision.owner.date
                    ? dayjs(
                        decision.owner.date_sent || decision.owner.date
                      ).format("MM/DD/YYYY")
                    : "-"}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No decisions recorded." />
      )}
    </div>
  );
}
