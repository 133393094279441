import React from "react";
import NavGroup from "../../../../UI/NavGroup/NavGroup";
import NavGroupItem from "../../../../UI/NavGroup/NavGroupItem";

export default function StudentSeminarNav({ studentId, enrollmentId }) {
  const studentSeminarNavigation = [
    {
      label: "Management",
      url: "management",
    },
    {
      label: "Presentations",
      url: "presentations",
    },
    {
      label: "Visits",
      url: "visits",
    },
    {
      label: "Reminders",
      url: "reminders",
    },
    {
      label: "Products",
      url: "products",
    },
  ];

  return (
    <NavGroup>
      {studentSeminarNavigation.map((item) => (
        <NavGroupItem
          key={item.label}
          label={item.label}
          url={`/students/${studentId}/seminars/enrollments/${enrollmentId}/${item.url}`}
        />
      ))}
    </NavGroup>
  );
}
