import React from "react";
import { useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";

export default function TextArea({
  id,
  placeholder,
  classNames,
  label,
  rows,
  dark,
  isRequired,
  defaultValue,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      {label && <p>{label}</p>}
      <textarea
        id={id}
        rows={rows}
        defaultValue={defaultValue}
        className={twMerge(
          "w-full rounded border px-2 py-1.5",
          dark && "bg-transparent",
          errors[id] && "border-red-500",
          classNames
        )}
        {...register(id, {
          required: isRequired ? "This field is required" : false,
        })}
        placeholder={placeholder}
      />
      {errors[id] && (
        <p className="absolute text-red-500">{errors[id]?.message}</p>
      )}
    </div>
  );
}
