import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import { Link } from "react-router-dom";

export default function ProductsSummary({ products, course }) {
  return (
    <Card classNames="overflow-y-scroll no-scrollbar mb-4">
      <div className="flex items-center justify-between mb-6">
        <h2 className="font-bold text-[#0694C0]">PRODUCTS SUMMARY</h2>
        <Link to={`/courses/${course.id}/products`}>
          <ArrowTopRightOnSquareIcon className="h-[32px] w-[32px] text-[#C4C4C4]" />
        </Link>
      </div>
      {products.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>PRODUCTS</TableHeader>
              <TableHeader>SOLD/GIVEN TO</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {products.map((item) => (
              <tr key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>#</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="Work in progress." />
      )}
    </Card>
  );
}
