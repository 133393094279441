import React from "react";
import { ToastNotification } from "../../../UI/ToastNotification";
import { createDecisionRequest } from "../../../../requests/decisions";
import CourseDecisionsForm from "./CourseDecisionsForm";

export default function CourseDecisionsNew({ setFormOpen, courseId }) {
  const onSubmit = async (data) => {
    try {
      const res = await createDecisionRequest({
        ...data,
        series_id: courseId,
      });

      if (res.data) {
        ToastNotification("success", "Decision created successfully.");
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return (
    <div className="mt-4">
      <CourseDecisionsForm setOpen={setFormOpen} onSubmit={onSubmit} />
    </div>
  );
}
