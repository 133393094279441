import React, { useEffect, useRef, useState } from "react";
import ActionMenuContainer from "../ActionMenu/ActionMenuContainer";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import Button from "../UI/Button";
import { ToastNotification } from "../UI/ToastNotification";
import { displayError } from "../../utils/displayError";
import TableHeader from "../UI/Table/TableHeader";
import Table from "../UI/Table/Table";
import { createCsvStudentsRequest } from "../../requests/students";
import { getAllSchools } from "../../requests/schools";
import CsvStudentsRow from "./CsvStudentsRow";
import Checkbox from "../UI/Inputs/Checkbox";
import { getLookupsByType } from "../../requests/lookups";
import { getAllCountries } from "../../requests/countries";
import { getAllStates } from "../../requests/states";

export default function CsvStudents({
  closeModal,
  csvStudents,
  setRefreshStudents,
}) {
  const methods = useForm();
  const { control } = useForm();
  const { fields, append } = useFieldArray({
    control,
    name: "csv_students",
  });
  const appendCalled = useRef(false);
  const [schools, setSchool] = useState([]);
  const [removedRows, setRemovedRows] = useState([]);
  const [removedColumns, setRemovedColumns] = useState([]);
  const [countries, setCountries] = useState([]);
  const [genders, setGenders] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (!appendCalled.current) {
      csvStudents.map((student) =>
        append({
          first_name: student.first_name.trim(),
          middle_name: student.middle_name?.trim(),
          last_name: student.last_name?.trim(),
          school_name: student.school?.trim(),
          school_id: "",
          instructor_name: student.instructor?.trim(),
          instructor_id: "",
          title: student.title?.trim(),
          address: student.street_address?.trim(),
          address_2: student.address_2?.trim(),
          city: student.city?.trim(),
          state: student.state_province?.trim(),
          zip_code: student.zip_postal_code?.trim(),
          country: student.country?.trim(),
          gender: student.gender?.trim(),
          married: student.married?.trim(),
          birthdate: student.birthdate?.trim(),
          denomination: student.denomination?.trim(),
          baptized: student.baptized?.trim(),
          phone: student.phone?.trim(),
          phone_is_sms: student.phone_is_sms?.trim(),
          phone_2: student.phone_2?.trim(),
          phone_2_is_sms: student.phone_2_is_sms?.trim(),
          email: student.e_mail_address?.trim(),
          social_media: student.social_media?.trim(),
          referrals: student.referrals?.trim(),
          enrollments: student.enrollments?.trim(),
          series_id: "",
          index: "",
        })
      );
      appendCalled.current = true;
    }
  }, []);

  const handleRemovedRows = async (event, value) => {
    if (!event.target.checked) {
      setRemovedRows([...removedRows, value]);
    } else {
      setRemovedRows(removedRows.filter((item) => item !== value));
    }
  };

  const handleRemovedColumns = async (event, value) => {
    if (!event.target.checked) {
      setRemovedColumns([...removedColumns, value]);
    } else {
      setRemovedColumns(removedColumns.filter((item) => item !== value));
    }
  };

  const onSubmit = async (data) => {
    // filter removed rows
    data = data.csv_students.filter((s) => !removedRows.includes(s.index));

    // filter removed columns
    removedColumns.forEach((column) => {
      data.forEach((object) => {
        delete object[column];
      });
    });

    if (data.length === 0) {
      ToastNotification("error", "No students selected.");
      return;
    }

    try {
      const res = await createCsvStudentsRequest({ data });

      if (res.data) {
        ToastNotification("success", "Students imported successfully.");
        closeModal();
        setRefreshStudents(true);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getSchools = async () => {
    const res = await getAllSchools({ params: { pagination: false } });
    if (res.data) {
      setSchool(res.data);
    }
  };

  const getCountries = async () => {
    try {
      const res = await getAllCountries();

      if (res.data) {
        setCountries(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getStates = async () => {
    try {
      const res = await getAllStates();

      if (res.data) {
        setStates(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getGenders = async () => {
    try {
      const res = await getLookupsByType({ lookupType: 1 });

      if (res.data) {
        setGenders(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    getSchools();
    getCountries();
    getStates();
    getGenders();
  }, []);

  useEffect(() => {
    if (document.getElementById("csv-preview-form")) {
      document.getElementById("csv-reader-input").value = null;
    }
  });

  return (
    <div className="bg-neutral-600 relative">
      <ActionMenuContainer
        label="CSV students preview"
        clickToClose={closeModal}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} id="csv-preview-form">
            <div className="overflow-auto max-h-[700px] max-w-[1500px]">
              <Table>
                <thead className="sticky top-0 z-[999] text-black bg-white">
                  <tr>
                    <TableHeader />
                    <TableHeader classNames="font-bold text-md min-w-[90px]">
                      <Checkbox
                        id="title"
                        label="Title"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "title")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[150px]">
                      <Checkbox
                        id="first_name"
                        label="First Name"
                        defaultChecked={true}
                        disabled
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[150px]">
                      <Checkbox
                        id="middle_name"
                        label="Middle Name"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "middle_name")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[150px]">
                      <Checkbox
                        id="last_name"
                        label="Last Name"
                        defaultChecked={true}
                        disabled
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[180px]">
                      <Checkbox
                        id="school"
                        label="School"
                        defaultChecked={true}
                        disabled
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[215px]">
                      <Checkbox
                        id="instructor"
                        label="Instructor"
                        defaultChecked={true}
                        disabled
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[200px]">
                      <Checkbox
                        id="enrollments"
                        label="Enrollments"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "series_id")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[160px]">
                      <Checkbox
                        id="referrals"
                        label="Referrals"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "referrals")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[150px]">
                      <Checkbox
                        id="address"
                        label="Address"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "address")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[100px]">
                      <Checkbox
                        id="address_2"
                        label="Address 2"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "address_2")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[100px]">
                      <Checkbox
                        id="city"
                        label="City"
                        defaultChecked={true}
                        onClick={(event) => handleRemovedColumns(event, "city")}
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[190px]">
                      <Checkbox
                        id="state"
                        label="State/Province"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "state")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[110px]">
                      <Checkbox
                        id="zip_code"
                        label="Zip/Postal"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "zip_code")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[190px]">
                      <Checkbox
                        id="country"
                        label="Country"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "country")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[180px]">
                      <Checkbox
                        id="gender"
                        label="Gender"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "gender")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[100px]">
                      <Checkbox
                        id="married"
                        label="Married"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "married")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[130px]">
                      <Checkbox
                        id="birthdate"
                        label="Birthdate"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "birthdate")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[130px]">
                      <Checkbox
                        id="denomination"
                        label="Denomination"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "denomination")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[100px]">
                      <Checkbox
                        id="baptized"
                        label="Baptized"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "baptized")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[140px]">
                      <Checkbox
                        id="phone"
                        label="Phone"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "phone")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[100px]">
                      <Checkbox
                        id="phone_is_sms"
                        label="SMS"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "phone_is_sms")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[140px]">
                      <Checkbox
                        id="phone_2"
                        label="Phone 2"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "phone_2")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[120px]">
                      <Checkbox
                        id="phone_2_is_sms"
                        label="Phone 2 SMS"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "phone_2_is_sms")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[190px]">
                      <Checkbox
                        id="email"
                        label="Email"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "email")
                        }
                      />
                    </TableHeader>
                    <TableHeader classNames="font-bold text-md min-w-[160px]">
                      <Checkbox
                        id="social_media"
                        label="Social Media"
                        defaultChecked={true}
                        onClick={(event) =>
                          handleRemovedColumns(event, "social_media")
                        }
                      />
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((student, index) => (
                    <CsvStudentsRow
                      key={index}
                      index={index}
                      student={student}
                      schools={schools}
                      methods={methods}
                      handleRemovedRows={handleRemovedRows}
                      countries={countries}
                      states={states}
                      genders={genders}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="md:flex-row flex justify-center pt-4">
              <div className="text-center md:mr-4">
                <Button type="submit" variant="primary">
                  Add Students
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </ActionMenuContainer>
    </div>
  );
}
