import React from "react";
import Card from "../../../../UI/Card";
import { Link, useRouteLoaderData } from "react-router-dom";
import { faker } from "@faker-js/faker";

export default function StudentMobileOverview() {
  const student = useRouteLoaderData("student").data;
  const baseRoute = `/students/${student?.id}`;

  return (
    <div className="max-w-[320px] m-auto">
      <Card classNames="text-center w-[150px] m-auto p-3 mb-4">
        <Link className="block" to={`${baseRoute}/profile`}>
          View Profile
        </Link>
      </Card>

      <div className="grid gap-4 grid-cols-2">
        <Card classNames="text-center">
          <Link to={`${baseRoute}/courses`} className="block">
            <p className="text-[40px]">{faker.number.int(100)}</p>
            <p>Courses</p>
          </Link>
        </Card>
        <Card classNames="text-center">
          <Link to={`${baseRoute}/seminars`} className="block">
            <p className="text-[40px]">{faker.number.int(100)}</p>
            <p>Seminars</p>
          </Link>
        </Card>
        <Card classNames="text-center">
          <Link to={`${baseRoute}/decisions`} className="block">
            <p className="text-[40px]">{faker.number.int(100)}</p>
            <p>Decisions</p>
          </Link>
        </Card>
        <Card classNames="text-center">
          <Link to={`${baseRoute}/products`} className="block">
            <p className="text-[40px]">{faker.number.int(100)}</p>
            <p>Products</p>
          </Link>
        </Card>
        <Card classNames="text-center">
          <Link to={`${baseRoute}/notes`} className="block">
            <p className="text-[40px]">{faker.number.int(100)}</p>
            <p>Notes</p>
          </Link>
        </Card>
      </div>
    </div>
  );
}
