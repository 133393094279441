import React, { useEffect, useRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Pill from "../Pill";
import { twMerge } from "tailwind-merge";

export default function MultiSelect({
  id,
  placeholder,
  options,
  dark,
  label,
  defaultSelected = [],
}) {
  const { setValue, control } = useFormContext();

  const [selected, setSelected] = useState(defaultSelected);
  const [defaultOptions, setDefaultOptions] = useState(options);

  const [open, setOpen] = useState(false);

  const wrapperRef = useRef();

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleSelect = (option) => {
    if (selected.some((item) => item.id === option.id)) {
      setSelected(selected.filter((item) => item.id !== option.id));
      return;
    }

    setSelected([...selected, { id: option.id, name: option.name }]);
  };

  const handleFilter = (value) => {
    setOpen(true);
    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(value.toLowerCase())
    );

    setDefaultOptions(filteredOptions);
  };

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  // closes on mouse leave
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setValue(id, selected);
  }, [selected]);

  return (
    <div
      className="flex h-full w-full flex-col rounded-md text-popover-foreground overflow-visible"
      ref={wrapperRef}
    >
      {label && <p>{label}</p>}
      <Controller
        control={control}
        name={id}
        defaultValue={selected}
        render={() => (
          <div
            className={twMerge(
              dark ? "bg-transparent" : "bg-white",
              "group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2"
            )}
          >
            <div className="flex flex-wrap gap-1">
              {selected.length > 0 &&
                selected.map((item) => (
                  <Pill key={item.id}>
                    {item.name}
                    <div className="cursor-pointer bg-[#057b9f] rounded-full w-4 ml-1">
                      <XMarkIcon
                        onClick={() =>
                          setSelected(
                            selected.filter(
                              (selectedItem) => selectedItem.id !== item.id
                            )
                          )
                        }
                      />
                    </div>
                  </Pill>
                ))}
              <input
                id={id}
                placeholder={placeholder}
                className={twMerge(
                  dark && "placeholder-white",
                  "ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground pl"
                )}
                role="combobox"
                type="text"
                onChange={(e) => handleFilter(e.target.value)}
                onClick={() => setOpen(!open)}
              />
            </div>
          </div>
        )}
      />
      <div className="relative mt-2 h-full">
        {open && (
          <ul className="absolute bg-white w-full max-h-[500px] rounded shadow-lg top-0 z-50 overflow-y-scroll no-scrollbar">
            {defaultOptions.length > 0 ? (
              defaultOptions.map((option) => (
                <li
                  key={option.id}
                  className="hover:bg-neutral-600 hover:text-white p-2 cursor-pointer text-black"
                  onClick={() => handleSelect(option)}
                >
                  <input
                    type="checkbox"
                    checked={selected.some((item) => item.id === option.id)}
                    className="h-4 w-4 mr-2 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                  />
                  {option.name}
                </li>
              ))
            ) : (
              <li className="p-2 text-black">No options available</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
