import React, { useEffect, useState } from "react";
import { getNadBibleSchools } from "../../requests/reports";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { usePagination } from "../UI/Pagination";
import { useSearchParams } from "react-router-dom";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { Pagination } from "../UI/Pagination";
import Pill from "../UI/Pill";

export default function NadBibleSchoolsReport() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pagination = usePagination();

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    setLoading(true);

    try {
      const res = await getNadBibleSchools({
        params: {
          active: searchParams.get("status"),
          conference_code: searchParams.get("conference_code"),
          discovery_center: searchParams.get("discovery_center"),
          page: page,
          per_page: pagination.recordsPerPage,
        },
      });

      if (res.data) {
        setReports(res.data);
        pagination.setData(res.data.pagination);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReport();
  }, [
    page,
    pagination.recordsPerPage,
    searchParams.get("status"),
    searchParams.get("conference_code"),
    searchParams.get("discovery_center"),
  ]);

  return (
    <div>
      <div className="flex justify-between my-10">
        <p>NAD Bible School List</p>
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && reports.length < 1}
        empty={reports.length < 1}
      >
        {reports.conferences?.length > 0 ? (
          reports.conferences.map((conference) => (
            <>
              <p className="text-2xl my-4">{conference.name.toUpperCase()}</p>
              <Table>
                <thead>
                  <tr>
                    <TableHeader>STATE</TableHeader>
                    <TableHeader>CITY</TableHeader>
                    <TableHeader>NAME/CHURCH</TableHeader>
                    <TableHeader>ADDRESS(ES)</TableHeader>
                    <TableHeader>CITY/STATE/ZIP</TableHeader>
                    <TableHeader>CHURCH CONTACT</TableHeader>
                    <TableHeader>SCHOOL ADMIN</TableHeader>
                    <TableHeader>ACTIVE</TableHeader>
                    <TableHeader>#</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {conference.conference_schools.map((school) => {
                    const isActive = school.active;

                    return (
                      <tr key={school.id}>
                        <TableCell>{conference.state}</TableCell>
                        <TableCell>{conference.city}</TableCell>
                        <TableCell>
                          <p>{school.school_name}</p>
                          <p>{school.church_name}</p>
                        </TableCell>
                        <TableCell>
                          <p>{school.school_address}</p>
                          <p>{school.church_address}</p>
                        </TableCell>
                        <TableCell>
                          <p>{`${school.school_city}, ${school.school_state} ${school.school_zip_code}`}</p>
                          <p>{`${school.church_city}, ${school.church_state} ${school.church_zip_code}`}</p>
                        </TableCell>
                        <TableCell>
                          <p>{school.church_contact}</p>
                          <p>{school.church_phone}</p>
                          <p>{school.church_email}</p>
                        </TableCell>
                        <TableCell>
                          <p>{`${school.head_school_admin?.first_name} ${school.head_school_admin?.last_name}`}</p>
                          <p>{school.head_school_admin?.phone}</p>
                          <p>{school.head_school_admin?.email}</p>
                        </TableCell>
                        <TableCell>
                          {isActive ? (
                            <Pill color="success">Active</Pill>
                          ) : (
                            <Pill>Inactive</Pill>
                          )}
                        </TableCell>
                        <TableCell>{school.school_code}</TableCell>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ))
        ) : (
          <EmptyTable message="No schools recorded." />
        )}
      </Pagination>
    </div>
  );
}
