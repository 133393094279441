import React, { useEffect, useState } from "react";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Checkbox from "../../../UI/Inputs/Checkbox";
import { getAllCountries } from "../../../../requests/countries";
import { getStatesByCountry } from "../../../../requests/states";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { ToastNotification } from "../../../UI/ToastNotification";
import { useFormContext } from "react-hook-form";

export default function ContactInformationForm({ student, isAdmin }) {
  const { watch } = useFormContext();
  const [denominations, setDenominations] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const selectedCountry = watch("country", student?.country);

  const getCountries = async () => {
    try {
      const res = await getAllCountries();

      if (res.data) {
        setCountries(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching countries.");
    }
  };

  const getStates = async () => {
    try {
      const countryId = countries.find(
        (country) => country.name === selectedCountry
      )?.id;
      const res = await getStatesByCountry({
        countryId: countryId || countries[0]?.id,
      });

      if (res.data) {
        setStates(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching states.");
    }
  };

  useEffect(() => {
    setDenominations(["Mr", "Mrs", "Ms", "Miss", "Dr", "Prof"]);
    getCountries();
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      getStates();
    }
  }, [selectedCountry, countries]);

  return (
    <div>
      <h2 className="mb-6 text-2xl">Contact Information</h2>

      <InputGroup>
        <Select disabled={!isAdmin} id="title">
          <SelectItem value="" disabled selected>
            Title
          </SelectItem>
          {denominations.map((option) => (
            <SelectItem
              key={option}
              value={option}
              selected={student?.title === option}
            >
              {option}
            </SelectItem>
          ))}
        </Select>
        <Input
          id="first_name"
          defaultValue={student?.first_name}
          placeholder="First name"
          disabled={!isAdmin}
          isRequired
        />
        <Input
          id="middle_name"
          defaultValue={student?.middle_name}
          placeholder="Middle name"
          disabled={!isAdmin}
        />
        <Input
          id="last_name"
          defaultValue={student?.last_name}
          placeholder="Last name"
          disabled={!isAdmin}
          isRequired
        />
      </InputGroup>

      <InputGroup col>
        <Input
          id="address"
          defaultValue={student?.address}
          placeholder="Address 1"
          label="Address"
          disabled={!isAdmin}
        />
        <Input
          id="address_2"
          defaultValue={student?.address_2}
          placeholder="Address 2"
          disabled={!isAdmin}
        />
      </InputGroup>

      <InputGroup>
        <Input
          id="city"
          defaultValue={student?.city}
          placeholder="City"
          disabled={!isAdmin}
        />

        {states.length > 0 && (
          <Select
            disabled={!isAdmin}
            id="state"
            label="State"
            defaultValue={student?.state}
          >
            <SelectItem value="" disabled selected>
              {watch("country") ? "State a state" : "Select a country"}
            </SelectItem>
            {watch("country") &&
              states.map((state) => (
                <SelectItem key={state.id} value={state.name}>
                  {state.name}
                </SelectItem>
              ))}
          </Select>
        )}

        <Input
          id="zip_code"
          defaultValue={student?.zip_code}
          placeholder="Zip/Postal"
          disabled={!isAdmin}
        />
      </InputGroup>

      <InputGroup>
        <div className=" md:w-3/5">
          {countries.length > 0 && (
            <Select
              disabled={!isAdmin}
              id="country"
              defaultValue={student?.country || ""}
            >
              <SelectItem value="" disabled selected>
                Select a country
              </SelectItem>
              {countries.map((country) => (
                <SelectItem key={country.id} value={country.name}>
                  {country.name}
                </SelectItem>
              ))}
            </Select>
          )}
        </div>
      </InputGroup>

      <InputGroup>
        <Input
          disabled={!isAdmin}
          id="phone"
          defaultValue={student?.phone}
          placeholder="Phone"
        />
        <Checkbox
          disabled={!isAdmin}
          id="phone_is_sms"
          defaultChecked={student?.phone_is_sms}
          label="SMS"
        />
        <Input
          disabled={!isAdmin}
          id="phone_2"
          defaultValue={student?.phone_2}
          placeholder="Phone 2"
        />
        <Checkbox
          disabled={!isAdmin}
          id="phone_2_is_sms"
          defaultChecked={student?.phone_2_is_sms}
          label="SMS"
        />
      </InputGroup>

      <InputGroup>
        <Input
          disabled={!isAdmin}
          id="email"
          defaultValue={student?.email}
          placeholder="Email"
          label="Email"
        />
        <Select
          id="include_in_mailing_list"
          defaultValue={student?.include_in_mailing_list || "if_address_valid"}
          label="Include in mailing list"
        >
          <SelectItem value="if_address_valid">If Address Valid</SelectItem>
          <SelectItem value="always">Always</SelectItem>
          <SelectItem value="never">Never</SelectItem>
        </Select>
      </InputGroup>

      <InputGroup>
        <Input
          disabled={!isAdmin}
          id="social_media"
          defaultValue={student?.social_media}
          placeholder="Social Media"
          label="Social Media"
        />
      </InputGroup>
    </div>
  );
}
