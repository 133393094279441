import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/lookups`;

const getAllLookups = async () => {
  const data = await axiosClient.get(endpoint);

  return data;
};

const getLookupsByType = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}?lookup_type=${params.lookupType}`
  );

  return data;
};

export { getAllLookups, getLookupsByType };
