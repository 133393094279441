import React from "react";
import Print from "../UI/Print";
import { fullName } from "../../utils/fullName";

const InstructorsPrint = ({ instructors, handleAfterPrint }) => {
  return (
    <Print
      header="INSTRUCTORS"
      empty={instructors.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>INSTRUCTOR</th>
            <th>STUDENTS</th>
            <th>CONTACT INFO</th>
            <th>ADDRESS</th>
            <th>ROLES</th>
          </tr>
        </thead>

        <tbody>
          {instructors.map((instructor) => (
            <tr key={instructor.id}>
              <td>
                {fullName(instructor.first_name, " ", instructor.last_name)}
              </td>
              <td className="whitespace-nowrap">
                {instructor.students_count} students
              </td>
              <td>
                <p>{instructor.email}</p>
                <p>{instructor.phone}</p>
              </td>
              <td className="whitespace-nowrap">
                {instructor.address && (
                  <>
                    {instructor.address}
                    {instructor.address_2 && (
                      <>
                        <br />
                        {instructor.address_2}
                      </>
                    )}
                    <br />
                    {instructor.city}
                    <br />
                    {instructor.state} {instructor.zip_code}
                    <br />
                    {instructor.country}
                  </>
                )}
              </td>
              <td>
                {instructor.roles.map((role) => (
                  <div key={role.id}>
                    <b>{role.titleized_role}</b> at {role.organization_name}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Print>
  );
};

export default InstructorsPrint;
