import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ActionMenuContainer from "../../ActionMenuContainer";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Button from "../../../UI/Button";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { useRouteLoaderData } from "react-router-dom";
import {
  getAllCourseDecisionsRequest,
  getAllCourseLessonsRequest,
} from "../../../../requests/courses";
import { displayError } from "../../../../utils/displayError";
import MultiSelect from "../../../UI/Inputs/MultiSelect";
import {
  getEnrollmentRequest,
  updateEnrollmentRequest,
} from "../../../../requests/enrollments";
import { ToastNotification } from "../../../UI/ToastNotification";

export default function NewStudentDecision({ setRender, user }) {
  const student = useRouteLoaderData("student").data;
  const methods = useForm();

  const [lessons, setLessons] = useState([]);
  const [decisionOptions, setDecisionOptions] = useState([]);
  const [enrollment, setEnrollment] = useState(null);

  const selectedCourse = methods.watch("course_id");
  const selectedEnrollmentId = student.course_enrollments.find(
    (enrollment) => enrollment.series_id === selectedCourse
  )?.id;

  const episodeDelivery = enrollment?.episode_deliveries.find(
    (delivery) => delivery.episode_id === methods.watch("lesson_id")
  );

  const selectedDecisionOptions = decisionOptions.filter((option) =>
    episodeDelivery?.decisions.some(
      (decision) => decision.decision_option_id === option.id
    )
  );

  const getLessons = async () => {
    try {
      const res = await getAllCourseLessonsRequest({
        courseId: selectedCourse,
      });

      if (res.data) {
        setLessons(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getAllCourseDecisions = async () => {
    const res = await getAllCourseDecisionsRequest({
      courseId: selectedCourse,
    });

    if (res.data) {
      setDecisionOptions(res.data);
    }
  };

  const getEnrollment = async () => {
    try {
      const res = await getEnrollmentRequest({
        enrollmentId: selectedEnrollmentId,
      });

      if (res.data) {
        setEnrollment(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const onSubmit = async (data) => {
    try {
      const decisions = decisionOptions.map((decision_option) => ({
        id: episodeDelivery?.decisions.find(
          (decision) => decision.decision_option_id === decision_option.id
        )?.id,
        decision_option_id: decision_option.id,
        cached_enrollment_id: selectedEnrollmentId,
        _destroy: !data.decisions.find(
          (decision) => decision.id === decision_option.id
        ),
      }));

      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            grading_instructor_id: user.id,
            decisions_attributes: decisions,
            episode_id: methods.watch("lesson_id"),
          },
        ],
        enrollmentId: selectedEnrollmentId,
      });

      if (res.data) {
        ToastNotification("success", "Decisions successfully.");
        setRender(null);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      getLessons();
      getAllCourseDecisions();
      getEnrollment();
    }
  }, [selectedCourse]);

  return (
    <ActionMenuContainer
      label="Record a decision"
      handleGoingBack={() => setRender(null)}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputGroup>
            <Select
              id="course_id"
              classNames="w-full bg-transparent"
              label="Course"
              isRequired
            >
              <SelectItem value="">Select</SelectItem>
              {student.course_enrollments?.map((enrollment) => (
                <SelectItem key={enrollment.id} value={enrollment.series_id}>
                  {enrollment.series_name}
                </SelectItem>
              ))}
            </Select>
            <Select
              id="lesson_id"
              classNames="w-full bg-transparent"
              label="Lesson"
              isRequired
            >
              <SelectItem value="" disabled>
                {selectedCourse ? "Select" : "Select a course first"}
              </SelectItem>
              {lessons?.map((lesson) => (
                <SelectItem key={lesson.id} value={lesson.id}>
                  {lesson.name}
                </SelectItem>
              ))}
            </Select>
          </InputGroup>
          {decisionOptions.length > 0 && (
            <InputGroup>
              <MultiSelect
                id="decisions"
                label="Decisions"
                placeholder="Add Decision"
                options={decisionOptions}
                defaultSelected={selectedDecisionOptions}
                dark
              />
            </InputGroup>
          )}

          <div className="text-center">
            <Button
              type="submit"
              variant="primary"
              disabled={decisionOptions.length === 0}
            >
              Save Decision
            </Button>
          </div>
        </form>
      </FormProvider>
    </ActionMenuContainer>
  );
}
