import React, { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { Outlet, useOutletContext, useRouteLoaderData } from "react-router-dom";
import InstructorNav from "./InstructorNav";
import PageActions from "../../UI/PageActions";
import { fullName } from "../../../utils/fullName";

export default function Instructor() {
  const { user, setActionMenuModal } = useOutletContext();
  const instructor = useRouteLoaderData("user").data;

  const [navOpen, setNavOpen] = useState(true);

  const renderAddress = () => {
    const hasFullAddress = instructor.city && instructor.state;
    const partialAddress = instructor.city || instructor.state;

    if (hasFullAddress) {
      return `| ${instructor.city}, ${instructor.state}`;
    }

    if (partialAddress) {
      return `| ${partialAddress}`;
    }

    return "";
  };

  return (
    <div>
      <div
        className={twMerge(
          !navOpen && "mb-7",
          "flex items-center justify-center"
        )}
      >
        <h1 className="text-2xl">
          {fullName(instructor.first_name, " ", instructor.last_name)}{" "}
          {renderAddress()}
        </h1>
        <EllipsisVerticalIcon
          className="ml-5 w-6 cursor-pointer"
          onClick={() => setNavOpen(!navOpen)}
        />
      </div>

      <div className="relative">
        <Transition.Root show={navOpen} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div>
              <InstructorNav instructor={instructor} />
              <div className="absolute top-2 right-0">
                <PageActions />
              </div>
            </div>
          </Transition.Child>
        </Transition.Root>
      </div>

      <Outlet context={{ user, setActionMenuModal }} />
    </div>
  );
}
