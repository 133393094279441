import Button from "../UI/Button";
import React, { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getSchoolAveryLabels } from "../../requests/reports";
import { useSearchParams } from "react-router-dom";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function SchoolAveryLabelsReport() {
  const [searchParams] = useSearchParams();
  const [schools, setSchools] = useState([]);
  let data = [];

  const docDefinition = () => {
    data = [];
    let count = 0;
    let row = [];

    schools.forEach((school, index) => {
      const line = {
        text: `Bible School Coordinator \n ${school.church_name} \n ${school.school_address ? school.school_address : "N/A"} \n ${school.school_city}, ${school.school_state} ${school.school_zip_code}`,
        fontSize: 10,
        margin: [0, 0, 0, 21],
      };

      row.push(line);
      count++;

      if (count % 3 === 0 || index === schools.length - 1) {
        data.push(row);
        row = [];
      }

      // Add a page break after every 30 schools (10 rows)
      if ((index + 1) % 30 === 0 && index !== schools.length - 1) {
        data.push({ text: "", pageBreak: "after" });
      }
    });

    let content = data.map((item) => {
      // If the item is a page break, return it as is
      if (item.pageBreak) {
        return item;
      }

      // Otherwise, return the item as a row in a table
      return {
        table: {
          widths: ["*", "*", "*"], // Set the width of each column
          body: [item],
        },
        layout: "noBorders", // Remove the borders from the table
      };
    });

    return {
      info: {
        title: "schools.pdf",
      },
      pageSize: {
        width: 612, //points
        height: 792, //points
      },
      pageMargins: [25, 45, 0, 45],
      content: content,
    };
  };

  const getAllSchoolAveryLabels = async () => {
    const res = await getSchoolAveryLabels({
      params: {
        active: searchParams.get("status"),
        conference_code: searchParams.get("conference_code"),
        discovery_center: searchParams.get("discovery_center"),
      },
    });
    if (res.data) {
      setSchools(res.data.schools);
    }
  };

  useEffect(() => {
    if (schools.length > 0 && data.length === 0) {
      const pdfDocGenerator = pdfMake.createPdf(docDefinition());
      pdfDocGenerator.getDataUrl((dataUrl) => {
        document.querySelector("#pdfViewer").src = dataUrl;
      });
    }
  }, [schools]);

  useEffect(() => {
    getAllSchoolAveryLabels();
  }, [
    searchParams.get("status"),
    searchParams.get("conference_code"),
    searchParams.get("discovery_center"),
  ]);

  return (
    <>
      <div className="flex justify-between my-10">
        <p>School Avery 5160 Labels Report</p>
        <Button
          variant="primary"
          onClick={() =>
            pdfMake.createPdf(docDefinition()).download("schools.pdf")
          }
        >
          Download PDF
        </Button>
      </div>
      <div>
        <iframe id="pdfViewer" className="w-full h-[700px]"></iframe>
      </div>
    </>
  );
}
