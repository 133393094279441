import React, { useEffect, useState } from "react";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import { useOutletContext, useRouteLoaderData } from "react-router-dom";
import { getAllQuestionsRequest } from "../../../../../../requests/questions";
import QuestionRow from "./QuestionRow";
import { getAllQuestionResponseRequest } from "../../../../../../requests/questionResponses";
import EmptyTable from "../../../../../UI/EmptyTable";

export default function Questions() {
  const { seriesEnrollment } = useOutletContext();
  const enrollment = useRouteLoaderData(seriesEnrollment).data;

  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState([]);

  const getAllQuestions = async () => {
    const res = await getAllQuestionsRequest({
      params: {
        series_id: enrollment.series_id,
      },
    });

    if (res.data) {
      setQuestions(res.data);
    }
  };

  const getAllQuestionResponses = async () => {
    const res = await getAllQuestionResponseRequest({
      params: {
        enrollment_id: enrollment.id,
      },
    });

    if (res.data) {
      setResponses(res.data);
    }
  };

  useEffect(() => {
    getAllQuestions();
    getAllQuestionResponses();
  }, []);

  return (
    <div>
      {questions.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>LESSON</TableHeader>
              <TableHeader>QUESTION</TableHeader>
              <TableHeader>RESPONSE</TableHeader>
            </tr>
          </thead>
          <tbody>
            {questions.map((question) => (
              <QuestionRow
                key={question.id}
                question={question}
                enrollment={enrollment}
                response={responses.find(
                  (response) => response.question_id === question.id
                )}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No questions available for this course." />
      )}
    </div>
  );
}
