import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import { Link } from "react-router-dom";
import EmptyTable from "../../../UI/EmptyTable";

export default function LessonsSummary({ course, lessons }) {
  return (
    <Card classNames="overflow-y-scroll no-scrollbar">
      <div className="flex items-center justify-between mb-6">
        <h2 className="font-bold text-[#0694C0]">LESSONS SUMMARY</h2>
        <Link to={`/courses/${course.id}/lessons`}>
          <ArrowTopRightOnSquareIcon className="h-[32px] w-[32px] text-[#C4C4C4]" />
        </Link>
      </div>
      {lessons.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>LESSON</TableHeader>
              <TableHeader>LESSON DELIVERED</TableHeader>
            </tr>
          </thead>
          <tbody>
            {lessons.map((lesson) => (
              <tr key={lesson.id}>
                <TableCell>{lesson.name}</TableCell>
                <TableCell>{`${lesson.lessons_delivered} delivered of ${lesson.enrollments_count} enrolled`}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No lessons available." />
      )}
    </Card>
  );
}
