import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import InputGroup from "../../../../../UI/Inputs/InputGroup";
import Button from "../../../../../UI/Button";
import Select from "../../../../../UI/Inputs/Select/Select";
import SelectItem from "../../../../../UI/Inputs/Select/SelectItem";
import DatePickerInput from "../../../../../UI/Inputs/DatePickerInput";
import Input from "../../../../../UI/Inputs/Input";

export default function ProductsForm({
  products,
  handleCancelAdd,
  onSubmit,
  column,
  delivery,
}) {
  const methods = useForm();

  const deliveryTypes = [
    {
      label: "PO",
      value: "Post Office",
    },
    {
      label: "PD",
      value: "Personal Delivery",
    },
    {
      label: "IH",
      value: "In-home",
    },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputGroup>
          {(column === "product_id" || column === "all") && (
            <Select
              id="product_id"
              label="Product Offer"
              defaultValue={delivery?.product.id}
              isRequired
            >
              <SelectItem value="" selected disabled>
                Select a product
              </SelectItem>
              {products.map((product) => (
                <SelectItem key={product.id} value={product.id}>
                  {product.name}
                </SelectItem>
              ))}
            </Select>
          )}
          {(column === "delivered_at" || column === "all") && (
            <DatePickerInput
              id="delivered_at"
              label="Delivered At"
              selected={delivery?.delivered_at || new Date()}
            />
          )}
          {(column === "quantity" || column === "all") && (
            <Input
              id="quantity"
              label="Quantity"
              type="number"
              defaultValue={delivery?.quantity}
            />
          )}
          {(column === "price" || column === "all") && (
            <Input
              id="price"
              label="Price"
              type="number"
              size="12"
              min="0.0"
              step="0.01"
              defaultValue={delivery?.price}
            />
          )}
          {(column === "delivery_type" || column === "all") && (
            <Select
              id="delivery_type"
              label="Delivery Type"
              defaultValue={delivery?.delivery_type}
            >
              <SelectItem value="" disabled selected>
                Select a delivery type
              </SelectItem>
              {deliveryTypes.map((deliveryType) => (
                <SelectItem key={deliveryType.label} value={deliveryType.value}>
                  {deliveryType.value}
                </SelectItem>
              ))}
            </Select>
          )}
          {(column === "visitor_name" || column === "all") && (
            <Input
              id="visitor_name"
              label="Visitor"
              defaultValue={delivery?.visitor_name}
            />
          )}
          <div>
            <Button
              classNames="mr-2"
              onClick={() => handleCancelAdd()}
              variant="primary-outline"
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary-outline">
              Save
            </Button>
          </div>
        </InputGroup>
      </form>
    </FormProvider>
  );
}
